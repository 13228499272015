import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';

export interface ConfirmationDialogProps {
  open: boolean;
  close: () => void;
  handleOk: () => void;
  dialogText: string;
  confirmDisabled?: boolean;
  cancelDisabled?: boolean;
}

export const ConfirmationDialog: React.FC<ConfirmationDialogProps> = ({
  open,
  close,
  handleOk,
  dialogText,
  children,
  confirmDisabled,
  cancelDisabled,
}) => (
  <div>
    <Dialog
      open={open}
      onClose={close}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {dialogText}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button onClick={close} color="error" disabled={cancelDisabled}>
          Cancel
        </Button>
        <Button
          onClick={handleOk}
          color="primary"
          autoFocus
          disabled={confirmDisabled}
        >
          OK
        </Button>
      </DialogActions>
    </Dialog>
  </div>
);
