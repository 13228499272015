import { getAccessToken } from './../auth';
import { ServerConfig, AuthMode } from './determine-server';
import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
  NormalizedCacheObject,
} from '@apollo/client';

import { setContext } from '@apollo/client/link/context';
import { SentryLink } from 'apollo-link-sentry';
import { createTransformerLink } from 'apollo-client-transform';

import introspectionResult from '../../generated/introspection-result';
import { env } from '../../runtime-environment';
import transformers from './transformers';

import { getCachedPin } from '../../state/user';
import { authEnabled } from '../auth';

const transformerLink = createTransformerLink(transformers);
const makeHttpLink = (serverUri: string) => createHttpLink({ uri: serverUri });

const cache = new InMemoryCache({
  possibleTypes: introspectionResult.possibleTypes,
  typePolicies: {
    Fulfilment: {
      fields: {
        lines: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
    FulfilmentLine: {
      fields: {
        packs: {
          merge(_existing, incoming) {
            return incoming;
          },
        },
      },
    },
  },
});

const makeAuthLink = (authMode: AuthMode) => {
  const getJwtHeaders = async () => {
    if (!authEnabled) return {};
    const token = await getAccessToken();
    return {
      Authorization: `Bearer ${token.accessToken}`,
    };
  };

  return setContext(async (_, { headers }) => {
    const pin = getCachedPin();

    const authHeaders =
      authMode === 'pin'
        ? {
            'x-client-app': 'pickle',
            Authorization: `Basic ${pin}`,
          }
        : await getJwtHeaders();

    return {
      headers: {
        ...headers,
        ...authHeaders,
        // Requests for this specific app can be found in Apollo Studio
        'apollographql-client-name': 'pickle',
        'apollographql-client-version': env.version || 'local',
      },
    };
  });
};

function makeApolloClient(serverConfig: ServerConfig) {
  const sentryLink = new SentryLink({
    uri: env.serverUri,
    setTransaction: true,
    setFingerprint: true,
    attachBreadcrumbs: {},
  });

  return new ApolloClient({
    link: ApolloLink.from([
      makeAuthLink(serverConfig.authMode),
      transformerLink as any,
      sentryLink,
      makeHttpLink(env.serverUri),
    ]),
    cache,
  });
}

let client: ApolloClient<NormalizedCacheObject> | null = null;

export default function makeCachedApolloClient(
  serverConfig: ServerConfig | null,
) {
  if (client) return client;
  if (serverConfig == null) {
    throw new Error(
      'Must provide `serverConfig` on first request for apollo client',
    );
  }

  return (client = makeApolloClient(serverConfig));
}
