import React, { useCallback, useState } from 'react';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import { CenterPage } from './CenterPage';
import { v4 as uuid } from 'uuid';
import {
  PackScanNotification,
  PackScanNotifications,
} from './PackScanNotifications';
import { ScanSuccess } from './ScanSuccess';
import { ScanError } from './ScanError';
import { ConfirmationDialog } from './ConfirmationDialog';
import { SalesOrderLine, FulfilmentLine } from '../generated/graphql';
import { useRemovePackMutation } from '../graphql/mutations/useRemovePackMutation';
import { PickType } from './PickPage';
import { RemainingToPick, getRemainingToPick } from './Pick';

function parseUniversalPackLabel(scannedValue: string) {
  try {
    // Replace curly quotes
    scannedValue = scannedValue
      .replace(/[\u2018\u2019]/g, "'")
      .replace(/[\u201C\u201D]/g, '"');

    const pack = JSON.parse(scannedValue);
    const data = {
      packNumber: pack.PackNumber as string,
      stockCode: pack.ProductCode as string,
      quantity: pack.Quantity as number,
      metersCubed: pack.M3 as number,
      kilograms: pack.KG as number,
    };

    if (data.packNumber === undefined) return null;
    if (data.stockCode === undefined) return null;
    if (data.quantity === undefined) return null;
    if (data.metersCubed === undefined) return null;
    if (data.kilograms === undefined) return null;

    return data;
  } catch {
    console.warn('Failed to parse pack label', scannedValue);
    return null;
  }
}

export type PackLabelData = NonNullable<
  ReturnType<typeof parseUniversalPackLabel>
>;

export interface ScanProps {
  pickType: PickType;
  orderLine: SalesOrderLine;
  fulfilmentLine: FulfilmentLine | null;
  complete: (
    data?: PackLabelData,
  ) => Promise<{ error: string | null; packId: string | null }>;
  back: () => void;
}

enum ScanStatus {
  Pending,
  Success,
  Error,
}

export const Scan: React.FC<ScanProps> = ({
  pickType,
  orderLine,
  fulfilmentLine,
  complete,
  back,
}) => {
  const [scannedValue, setScannedValue] = useState('');
  const [dialogState, setDialogState] = useState(false);

  const hasBarcode = !!orderLine.stockItem.barcode;
  const hasBinCode = !!orderLine.stockItem.locationInfo.binCode;

  const [status, setStatus] = useState(ScanStatus.Pending);
  const [scannedPacks, setScannedPacks] = useState<PackScanNotification[]>(
    fulfilmentLine?.packs.map((p) => ({
      id: uuid(),
      packId: p.id,
      state: 'complete',
      data: { ...p, stockCode: orderLine.stockItem.stockCode },
    })) ?? [],
  );
  const setScanSuccess = (data?: PackLabelData) => {
    if (data) {
      const id = uuid();
      // Add pack to notification list
      setScannedPacks((packs) =>
        packs.concat([{ id, packId: null, data, state: 'pending' }]),
      );
      // Reset input value so the process can repeat
      setScannedValue('');
      // Server request
      complete(data).then(({ error, packId }) => {
        // Update notification to show that it's been added
        setScannedPacks((packs) =>
          packs.map((x) =>
            x.id === id
              ? { ...x, packId, state: error == null ? 'complete' : { error } }
              : x,
          ),
        );
      });
      return;
    }

    setStatus(ScanStatus.Success);
    setTimeout(() => {
      complete(data);
    }, 1000);
  };

  const setScanError = () => {
    setStatus(ScanStatus.Error);
    setTimeout(() => {
      setScannedValue('');
      setStatus(ScanStatus.Pending);
    }, 2000);
  };

  const submitScannedValue = (scannedValue: string) => {
    if (
      (hasBarcode && scannedValue === orderLine.stockItem.barcode) ||
      (hasBinCode && scannedValue === orderLine.stockItem.locationInfo.binCode)
    ) {
      setScanSuccess();
      return;
    }

    if (pickType === 'quantity') {
      // Above needed to succeed if we are restricted to picking quantities
      setScanError();
      return;
    }

    const packData = parseUniversalPackLabel(scannedValue);
    if (packData && packData.stockCode === orderLine.stockItem.stockCode) {
      setScanSuccess(packData);
      return;
    }

    setScanError();
  };

  const submissionOptionsText = (() => {
    if (pickType === 'pack') {
      return 'Please scan the pack label';
    }

    const any = pickType === 'any';
    if (hasBarcode) {
      if (hasBinCode) {
        return `Please scan the product or bin barcode${
          any ? ', or a pack label' : ''
        }`;
      }
      return `Please scan the product${any ? ' or a pack label' : ''}`;
    }

    if (hasBinCode) {
      return `Please scan the bin code${any ? ' or a pack label' : ''}`;
    }

    if (any) {
      return "Scan a pack label, or if there isn't one, there is nothing else we can accept for this line, and so you may skip this step.";
    }

    return 'There is nothing we can accept for scanning. You may skip this step';
  })();

  const [removePack] = useRemovePackMutation();
  const lineId = fulfilmentLine?.id;
  const handleDeletePack = useCallback(
    (id: string) => {
      if (lineId == null) return;
      removePack({
        packId: id,
        fulfilmentLineId: lineId,
        onSuccess: () =>
          setScannedPacks((packs) => packs.filter((x) => x.packId !== id)),
      });
    },
    [removePack, lineId],
  );

  if (status === ScanStatus.Success) {
    return <ScanSuccess orderLine={orderLine} scanned={scannedValue} />;
  }

  if (status === ScanStatus.Error) {
    return <ScanError orderLine={orderLine} scanned={scannedValue} />;
  }

  return (
    <CenterPage>
      {scannedPacks && (
        <PackScanNotifications
          notifications={scannedPacks}
          deletePack={handleDeletePack}
        />
      )}

      <Container maxWidth="sm">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            // Prevent what seems to be a race condition by getting current text
            const barcode = document.getElementById(
              'scanned-barcode',
            ) as HTMLInputElement;
            submitScannedValue(barcode.value);
          }}
        >
          <TextField
            id="scanned-barcode"
            label="Press here before scanning."
            value={scannedValue}
            onChange={(e) => setScannedValue(e.target.value)}
            autoComplete="off"
            style={{ display: 'flex' }}
            autoFocus
            variant="standard"
          />
        </form>
        <Typography
          variant="h1"
          style={{
            textAlign: 'center',
          }}
        >
          {orderLine.stockItem.locationInfo.binCode}
        </Typography>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          {orderLine.stockItem.stockCode}
          <br />
          {orderLine.description}
        </Typography>
        <RemainingToPick
          remaining={getRemainingToPick(orderLine, fulfilmentLine)}
          quantityMultiplier={orderLine.stockItem.quantityMultiplier}
          unit={orderLine.stockItem.unitOfMeasure.name}
        />
        <Typography
          variant="h3"
          style={{
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          {submissionOptionsText}
        </Typography>
        <Grid
          container
          justifyContent="space-around"
          style={{ marginTop: '3rem' }}
        >
          <Grid item>
            <Button size="large" color="primary" onClick={back}>
              {pickType === 'pack' ? 'Done Adding Packs' : 'Back'}
            </Button>
            {pickType !== 'pack' && (
              <Button
                size="large"
                color="error"
                onClick={() => {
                  if (!hasBarcode && !hasBinCode) {
                    // Skipping in these circumstances will be much more common,
                    // so lets make it less of a pain.
                    complete();
                  } else {
                    setDialogState(true);
                  }
                }}
              >
                Skip Scanning
              </Button>
            )}
            <ConfirmationDialog
              open={dialogState === true}
              close={() => {
                setDialogState(false);
              }}
              handleOk={() => complete()}
              dialogText="Are you sure that you want to skip scanning?"
            />
          </Grid>
        </Grid>
      </Container>
    </CenterPage>
  );
};
