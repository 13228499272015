import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import BackIcon from '@mui/icons-material/ArrowBack';
import React, { useCallback, useState } from 'react';
import { useHotkeys } from 'react-hotkeys-hook';
import { FulfilmentPreviewList } from './FulfilmentPreviewList';
import { UserDisplay } from './UserDisplay';
import { AdapterLink } from './AdapterLink';

import { FULFILMENT_MOST_RECENT_COUNT } from '../graphql/queries/useFulfilmentsQuery';
import TextField from '@mui/material/TextField';
import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';
import { useHistory, useParams } from 'react-router-dom';
import { FulfilmentModal } from './FulfilmentModal';

export const FulfilmentsPage: React.FC = () => {
  const [searchQuery, setSearchQuery] = useState<string | null>(null);

  const history = useHistory();
  const match = useParams<{ id: string }>();

  const openFulfilmentId = match?.id;

  const handleOpen = useCallback(
    (fulfilmentId: string) => {
      history.push(`/fulfilments/${fulfilmentId}`);
    },
    [history],
  );

  const handleClose = useCallback(() => {
    history.push(`/fulfilments`);
  }, [history]);

  return (
    <Container maxWidth="md" style={{ paddingTop: '2rem' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ padding: '0 4em 3em 4em' }}
      >
        <Grid item xs={3}>
          <IconButton component={AdapterLink} to="/" size="large">
            <BackIcon />
          </IconButton>
        </Grid>
        <Grid
          item
          xs={6}
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >
          <Typography variant="h2" style={{ textAlign: 'center' }}>
            Fulfilments
          </Typography>
          <Typography
            variant="body2"
            color="textSecondary"
            style={{
              textAlign: 'center',
              visibility: searchQuery == null ? 'visible' : 'hidden',
            }}
          >
            Most Recent {FULFILMENT_MOST_RECENT_COUNT}
          </Typography>
          <FulfilmentSearchControls
            searchQuery={searchQuery}
            setSearchQuery={setSearchQuery}
          />
        </Grid>
        <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <UserDisplay />
        </Grid>
      </Grid>
      <FulfilmentPreviewList searchQuery={searchQuery} onOpen={handleOpen} />
      {openFulfilmentId != null && (
        <FulfilmentModal
          fulfilmentId={openFulfilmentId}
          onClose={handleClose}
        />
      )}
    </Container>
  );
};

const FulfilmentSearchControls: React.FC<{
  searchQuery: string | null;
  setSearchQuery: React.Dispatch<React.SetStateAction<string | null>>;
}> = ({ searchQuery, setSearchQuery }) => {
  const match = useParams<{ id: string }>();

  useHotkeys(
    'ctrl+f',
    (event) => {
      // Don't show browser-native search
      event.preventDefault();
      // Open search box (which will autofocus)
      setSearchQuery('');
    },
    {},
    [setSearchQuery],
  );

  useHotkeys(
    'escape',
    () => {
      setSearchQuery(null);
    },
    {
      // Keep this keep this working even when the search box is focused
      enableOnTags: ['INPUT'],
      // If a modal is open, pressing escape should close the modal, not affect
      // the search query (so we disable it)
      enabled: !match?.id,
    },
    [setSearchQuery],
  );

  return (
    <div style={{ display: 'flex' }}>
      {searchQuery != null && (
        <TextField
          onChange={(e) => setSearchQuery(e.target.value)}
          style={{ marginRight: '0.5em' }}
          placeholder="Search..."
          autoFocus
          variant="standard"
        />
      )}
      <div>
        <IconButton
          style={{ padding: '12px 6px' }}
          onClick={() => {
            setSearchQuery((s) => (s == null ? '' : null));
          }}
          size="large"
        >
          {searchQuery != null ? <ClearIcon /> : <SearchIcon />}
        </IconButton>
      </div>
    </div>
  );
};
