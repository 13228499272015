import React from "react";
import { ButtonStyle } from "./FulfilmentModal";
import * as Sentry from "@sentry/react";

interface PBTTrackingButtonProps {
  fulfilmentId: string;
  fulfilmentStockLocation: string;
}

export const PBTTrackingButton: React.FC<PBTTrackingButtonProps> = ({ fulfilmentId, fulfilmentStockLocation }) => {
  function getTrackingNumber(
    fulfilmentId: any,
    fulfilmentStockLocation: string
  ): string {
    const paddedId = fulfilmentId.padStart(6, '0')

    const prefix: "TZP1" | "PDB1" | "PDC1" | void =
        fulfilmentStockLocation === '1.Motueka Stock' ? 'TZP1'
      : fulfilmentStockLocation === '6.Moutere Stock' ? 'PDB1'
      : fulfilmentStockLocation === '2.Auckland Stock' ? 'PDC1'
      : (function () {
          Sentry.captureException(new Error("Unexpected stock location: " + fulfilmentStockLocation));
        })();

    return `${prefix}${paddedId}001`;
  }

  return (
    <ButtonStyle
      isExternal
      href={`https://www.pbt.co.nz/landing.aspx?track=${getTrackingNumber(fulfilmentId, fulfilmentStockLocation)}`}
      variant="contained"
      style={{ marginLeft: '1em', marginRight: '1em' }}
    >
      Track
    </ButtonStyle>
  );
};
