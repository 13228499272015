import { gql } from '@apollo/client';
import {
  useSalesOrdersQuery as useQuery,
  SalesOrderStatus,
  ListFilter,
  SalesOrdersQueryVariables,
  SalesOrderLinePickCategory,
} from '../../generated/graphql';
import { OrderListType } from '../../models/OrderListType';

gql`
  query SalesOrders(
    $statuses: [SalesOrderStatus!]
    $take: Int!
    $branchIds: [ID!]!
    $stockLocationId: ID!
    $withLinesInCategory: SalesOrderLinePickCategory
    $descending: Boolean
    $onHoldFilter: ListFilter
    $customerOnStopCredit: ListFilter
    $customerInactive: ListFilter
    $pickedFilter: ListFilter
  ) {
    salesOrders(
      statuses: $statuses
      take: $take
      branchIds: $branchIds
      stockLocationId: $stockLocationId
      withLinesInCategory: $withLinesInCategory
      descending: $descending
      onHoldFilter: $onHoldFilter
      customerOnStopCredit: $customerOnStopCredit
      customerInactive: $customerInactive
      pickedFilter: $pickedFilter
    ) {
      id
      customerName
      customer {
        name
      }
      internalReference
      dateOrdered
      status
      hasBackOrders
      notes
      onHold
      activeFulfilment {
        id
        createdBy {
          id
          givenName
        }
      }
    }
  }
`;

export const useSalesOrdersQuery = (
  vars: {
    listType: OrderListType;
    take: number;
    branchIds: string[];
    stockLocationId: string;
  } | null,
) => {
  const getVars = () => {
    if (vars === null) return;

    const value: SalesOrdersQueryVariables = {
      statuses: null,
      pickedFilter: ListFilter.Exclude,
      onHoldFilter: ListFilter.Exclude,
      customerOnStopCredit: ListFilter.Exclude,
      customerInactive: ListFilter.Exclude,
      take: vars.take,
      branchIds: vars.branchIds,
      stockLocationId: vars.stockLocationId,
      withLinesInCategory: null,
      descending: ![OrderListType.Ready, OrderListType.BackOrdered].includes(
        vars.listType,
      ),
    };

    switch (vars.listType) {
      case OrderListType.Ready:
        value.withLinesInCategory = SalesOrderLinePickCategory.Ready;
        value.statuses = [
          SalesOrderStatus.NotProcessed,
          SalesOrderStatus.PartiallyProcessed,
        ];
        break;
      case OrderListType.OnHold:
        value.onHoldFilter = ListFilter.Only;
        value.statuses = [
          SalesOrderStatus.NotProcessed,
          SalesOrderStatus.PartiallyProcessed,
        ];
        break;
      case OrderListType.BackOrdered:
        value.withLinesInCategory = SalesOrderLinePickCategory.BackOrdered;
        break;
      case OrderListType.Unavailable:
        value.pickedFilter = ListFilter.Only;
        value.statuses = [
          SalesOrderStatus.NotProcessed,
          SalesOrderStatus.PartiallyProcessed,
        ];
        break;
      case OrderListType.FullyProcessed:
        value.pickedFilter = ListFilter.Include;
        value.onHoldFilter = ListFilter.Include;
        value.customerOnStopCredit = ListFilter.Include;
        value.customerInactive = ListFilter.Include;
        value.statuses = [SalesOrderStatus.FullyProcessed];
        break;
    }

    return value;
  };

  return useQuery({
    pollInterval: 15000,
    skip: vars === null,
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-first',
    variables: vars !== null ? getVars() : undefined,
  });
};
