import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Any: any;
  Date: any;
  DateTime: any;
  JSON: any;
  Upload: any;
};

/** Describes access permissions to an application. */
export type AppAccess = {
  __typename?: 'AppAccess';
  /** Textual ID for identifying the app. */
  id: Scalars['String'];
};

export type ConsignmentBatchGenerateFromFulfilmentsInput = {
  /** The `Fulfilment`s to pull the needed data from. */
  fulfilmentIds: Array<Scalars['ID']>;
};

export type ConsignmentBatchGenerateFromFulfilmentsPayload = {
  __typename?: 'ConsignmentBatchGenerateFromFulfilmentsPayload';
  /** Resulting freight documentation files, if any. */
  documents: Array<File>;
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ConsignmentGenerateFromFulfilmentInput = {
  /** The `Fulfilment` to pull the needed data from. */
  fulfilmentId: Scalars['ID'];
};

export type ConsignmentGenerateFromFulfilmentPayload = {
  __typename?: 'ConsignmentGenerateFromFulfilmentPayload';
  /**
   * The consignment number used, if a consignment was actually generated, unique
   * per `ShippingProvider`.
   */
  consignmentNumber?: Maybe<Scalars['String']>;
  /**
   * - If `true`, the shipping provider to use was given a consignment.
   * - If `false`, the shipping provider used has no automated integration, and so
   *   nothing was done.
   */
  providerNotified: Scalars['Boolean'];
  /** The tracking link that would be used if a consignment was generated. */
  trackingLink?: Maybe<Scalars['String']>;
};

/**
 * Represents a notification to a freight provider of a `Fulfilment` or a number
 * of `Fulfilment`s.
 */
export type ConsignmentGroup = {
  __typename?: 'ConsignmentGroup';
  /** When the provider was notified, or shortly after. */
  dateSent: Scalars['Date'];
  /** A set of PDF documents relating to this consignment group. */
  documents: Array<File>;
  /** Unique identifier. */
  id: Scalars['ID'];
};

export type ConsignmentPrintLabelsInput = {
  /** The `Fulfilment` to pull the needed data from. */
  fulfilmentId: Scalars['ID'];
};

export type ConsignmentPrintLabelsPayload = {
  __typename?: 'ConsignmentPrintLabelsPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type ContactEmail = {
  __typename?: 'ContactEmail';
  email: Scalars['String'];
  type: ContactEmailType;
};

export enum ContactEmailType {
  Bcc = 'BCC',
  Cc = 'CC',
  To = 'TO'
}

export type Coordinate = {
  __typename?: 'Coordinate';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

/**
 * Stores a number which changes when used, intended for generating sequences or
 * unique identifiers.
 */
export type Counter = {
  __typename?: 'Counter';
  /** Unique identifier for the counter. Can be any string. */
  id: Scalars['ID'];
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
  /** The counter's current value. */
  value: Scalars['Int'];
};

export type CounterCreateInput = {
  /** Unique identifier for fetching the `Counter` once created. Can be any string. */
  id: Scalars['ID'];
  /**
   * The initial value of the counter. Is the value that will be returned when
   * first running the `useCounter` mutation for this `Counter` ID.
   */
  initialValue: Scalars['Int'];
  /**
   * If `true`, will replace any existing counter. If `false` or unspecified,
   * counter creation would fail if the ID was already registered.
   */
  reinitialize?: InputMaybe<Scalars['Boolean']>;
  /**
   * The amount the `value` of the counter will change when used once. May be
   * negative.
   */
  updateChange: Scalars['Int'];
};

/** An account in the CRM system. */
export type CrmAccount = {
  __typename?: 'CrmAccount';
  /** The store's account number in the financial system (EXO) */
  accountNumber?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  /** The account name. */
  name: Scalars['String'];
};

/** Name of a contact in the CRM system, often linked to accounts. */
export type CrmContact = {
  __typename?: 'CrmContact';
  /** Email addresses associated with the contact. */
  emailAddresses: Array<Scalars['String']>;
  id: Scalars['ID'];
  /**
   * Whether on not this contact should receive an email when a customer requests
   * a quote and references this store (e.g. Trelli).
   */
  shouldContactForQuote: Scalars['Boolean'];
};

/** Sales order created in the CRM system. */
export type CrmSalesOrder = {
  __typename?: 'CrmSalesOrder';
  customerReference: Scalars['String'];
  dateCreated: Scalars['Date'];
  deliverTo: Array<Scalars['String']>;
  emailAddress?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lines: Array<CrmSalesOrderLine>;
};

/** Line on a `CrmSalesOrder`. */
export type CrmSalesOrderLine = {
  __typename?: 'CrmSalesOrderLine';
  description: Scalars['String'];
  quantity: Scalars['Float'];
  stockCode?: Maybe<Scalars['ID']>;
  unitPrice: Scalars['Float'];
};

/** An entity that can become indebted to the company through purchase of goods. */
export type Debtor = {
  __typename?: 'Debtor';
  /** Alert associated with the `Debtor`. */
  alert?: Maybe<Scalars['String']>;
  /** The default shipping provider to use for this `Debtor`, if any. */
  defaultShippingProvider?: Maybe<ShippingProvider>;
  /** Address where goods ordered by this customer should typically be sent. */
  deliveryAddress: Array<Scalars['String']>;
  /** Primary email address associated with the `Debtor`. */
  email?: Maybe<Scalars['String']>;
  /** GLN code for the `Debtor`. */
  gln?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Whether or not the `Debtor` is active - inactive debtors can't make orders. */
  isActive: Scalars['Boolean'];
  /** Name for the `Debtor`. Defaults to uppercase. */
  name: Scalars['String'];
  /** Whether the debtor is on stop credit (cannot make further transactions). */
  onStopCredit: Scalars['Boolean'];
  /** Parent/Head Office `Debtor`. */
  parent?: Maybe<Debtor>;
  /** Default phone number for the `Debtor`, if available. */
  phoneNumber?: Maybe<Scalars['String']>;
};


/** An entity that can become indebted to the company through purchase of goods. */
export type DebtorNameArgs = {
  case?: InputMaybe<TextCapitalization>;
};

export type DebtorInvoice = {
  __typename?: 'DebtorInvoice';
  id: Scalars['ID'];
  /** Unique number to identify the `DebtorInvoice`. */
  invoiceNumber: Scalars['String'];
  /** Lines associated with the `DebtorInvoice`. */
  lines: Array<DebtorInvoiceLine>;
  /** The `Debtor` responsible for paying. */
  payableBy: Debtor;
  /** The `SalesOrder`, if any, relating to the `DebtorInvoice`. */
  salesOrder?: Maybe<SalesOrder>;
  /** Determines monetary totals to use. Is almost always set to false. */
  taxInclusive: Scalars['Boolean'];
  /** Total tax on the invoice. */
  totalTax: Scalars['Float'];
  /** Total invoice amount, including taxes. */
  totalWithTax: Scalars['Float'];
  /** Total invoice amount, excluding taxes. */
  totalWithoutTax: Scalars['Float'];
};

export type DebtorInvoiceGenerateFromFulfilmentInput = {
  /**
   * Whether to automatically invoice any special lines at the same time, such as
   * freight or discount lines.
   */
  autoinvoiceSpecialLines: Scalars['Boolean'];
  /** The consignment to reference, mandatory for Bunnings invoices. */
  consignmentId?: InputMaybe<Scalars['ID']>;
  /**
   * If `true`, any lines on the order that are invoiced to a total quantity less
   * than the order quantity will be force closed. Lines that are not invoiced will
   * not be touched.
   */
  forceClosePartialLines: Scalars['Boolean'];
  /**
   * If `true`, will mark the order as fully processed regardless of the actual
   * state of the `SalesOrder`.
   */
  forceFullyProcess?: InputMaybe<Scalars['Boolean']>;
  /** The `Fulfilment` to be invoiced. */
  fulfilmentId: Scalars['ID'];
};

export type DebtorInvoiceGenerateFromFulfilmentPayload = {
  __typename?: 'DebtorInvoiceGenerateFromFulfilmentPayload';
  invoiceId: Scalars['ID'];
  invoiceNumber: Scalars['String'];
};

export type DebtorInvoiceLine = {
  __typename?: 'DebtorInvoiceLine';
  /**
   * Description of the line. Often the same as the description of the line's
   * `StockItem`, but may be different.
   */
  description: Scalars['String'];
  id: Scalars['ID'];
  /** The quantity of product that is being invoiced. */
  quantity: Scalars['Float'];
  /** The `SalesOrderLine`, if any, relating to the line. */
  salesOrderLine?: Maybe<SalesOrderLine>;
  /** Total tax on the goods represented by the line. */
  totalTax: Scalars['Float'];
  /** Total price of the goods represented by the line, including tax. */
  totalWithTax: Scalars['Float'];
  /** Total price of the goods represented by the line, excluding tax. */
  totalWithoutTax: Scalars['Float'];
  /** Price per unit of the goods represented by the line, including tax. */
  unitPriceWithTax: Scalars['Float'];
  /** Price per unit of the goods represented by the line, excluding tax. */
  unitPriceWithoutTax: Scalars['Float'];
};

/** Information about a file that has been deleted. */
export type DeletedFile = {
  __typename?: 'DeletedFile';
  deletionReason: FileDeletionReason;
};

/** A durable file containing human-readable information. Can be printed. */
export type Document = {
  __typename?: 'Document';
  /** Unique ID. */
  id: Scalars['ID'];
  /** URL for displaying the document, valid for approximately 30 minutes */
  url: Scalars['String'];
};

export type DocumentGenerateCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data?: InputMaybe<Scalars['JSON']>;
  /** The `DocumentTemplate` that should be used, for example 'examples.hello'. */
  templateId: Scalars['ID'];
  /**
   * Location to upload the generated document to. Should be an Azure Blob Storage
   * URL (with SAS token or otherwise authenticated), typically obtained via use of
   * `uploadHandlesGet`.
   */
  uploadUrl: Scalars['String'];
};

export type DocumentGenerateInput = {
  /** List of commands for generating documents. */
  commands: Array<DocumentGenerateCommandInput>;
};

export type DocumentGeneratePayload = {
  __typename?: 'DocumentGeneratePayload';
  /**
   * Always an empty array
   * @deprecated Always an empty array, so no longer serves a purpose.
   */
  documents: Array<Document>;
  message: Scalars['String'];
};

export type DocumentPrintCommandInput = {
  /** Number of copies to print. Cannot be negative. Defaults to 1. */
  copies?: InputMaybe<Scalars['Int']>;
  /** ID of the printer to use. */
  printer: Scalars['String'];
  /** Location to fetch the document from, before printing. Must be a PDF file. */
  url: Scalars['String'];
};

export type DocumentPrintInput = {
  /** List of commands for printing. */
  commands: Array<DocumentPrintCommandInput>;
};

export type DocumentPrintPayload = {
  __typename?: 'DocumentPrintPayload';
  message: Scalars['String'];
};

export type EventPublishEventInput = {
  /**
   * The event data. Must be in the format dictated by the topic's `eventType` and
   * `dataVersion`.
   */
  data: Scalars['JSON'];
  /**
   * Version (e.g. 1.0) to use to describe the data's schema. Increment the minor
   * version if the change is non-breaking (e.g. adding a field, 1.0 -> 1.1),
   * or increment the major version if it is breaking (e.g. removing a field,
   * 1.0 -> 2.0).
   */
  dataVersion: Scalars['String'];
  /**
   * Event type to use, typically the action performed on the primary business
   * entity. For example, on the `sales-orders` topic, one event type is
   * `accepted`.
   */
  eventType: Scalars['String'];
  /**
   * Subject to use for searchability. This is often the ID of the primary entity
   * involved.
   */
  subject: Scalars['String'];
  /**
   * Name of the topic to publish to. This is typically the name of the primary
   * business entity involved, e.g. `sales-orders`.
   */
  topic: Scalars['String'];
};

export type EventPublishInput = {
  events: Array<EventPublishEventInput>;
};

export type EventPublishPayload = {
  __typename?: 'EventPublishPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** A simple way of describing changes to a field in an object */
export type FieldModification = {
  __typename?: 'FieldModification';
  /** Date the modification was made. */
  date: Scalars['Date'];
  /** The name of the field that was changed. */
  field: Scalars['String'];
  /** The value of the field before the modification (which may be null). */
  formerValue?: Maybe<Scalars['Any']>;
};

/** A handle to a file in Azure Blob Storage. */
export type File = {
  __typename?: 'File';
  /** Mime-type of the file, e.g. "application/pdf". */
  contentType: Scalars['String'];
  /** When, if at all, the file will expire or did expire. */
  expireAt?: Maybe<Scalars['DateTime']>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Data relating to the file, different depending on the file's status */
  info: FileInfo;
  /** Name of the file, if specified when creating an upload handle. */
  name?: Maybe<Scalars['String']>;
};

export enum FileDeletionReason {
  /** File was deleted automatically because it expired. */
  Expired = 'EXPIRED'
}

export type FileInfo = DeletedFile | PresentFile;

export enum FilePermissions {
  /** The default. Files can only be read. */
  Read = 'READ',
  /**
   * Files can be updated after creation - an upload url can always be
   * requested. However unlike the `accessUrl` field, doing so requires
   * the admin scope.
   */
  ReadUpdate = 'READ_UPDATE'
}

/** Represents the physical set of goods that are to be sent to a customer/`Debtor`. */
export type Fulfilment = {
  __typename?: 'Fulfilment';
  /**
   *   The consignment/group of consignments through which a freight provider was
   * told about this `Fulfilment`.
   */
  consignmentGroup?: Maybe<ConsignmentGroup>;
  /**
   * The `User` responsible for creating the `Fulfilment`. This `User` is
   * responsible for all further changes to the `Fulfilment`, as no other user is
   * permitted to perform any action on another `User`'s `Fulfilment`.
   */
  createdBy: User;
  /** The date of abandoning/deleting, if `ABANDONED`. */
  dateAbandoned?: Maybe<Scalars['Date']>;
  /** The date of completion, if it reached that stage (`UNFINALIZED` or `COMPLETED`). */
  dateCompleted?: Maybe<Scalars['Date']>;
  /** The date of creation. */
  dateCreated: Scalars['Date'];
  /** The date of finalization, if it reached that stage (`COMPLETED`). */
  dateFinalized?: Maybe<Scalars['Date']>;
  /** Unique ID. */
  id: Scalars['ID'];
  /** Invoice number of the associated Invoice. */
  invoiceNumber?: Maybe<Scalars['String']>;
  /** Lines associated with the `Fulfilment`. */
  lines: Array<FulfilmentLine>;
  /**
   * Packing slips for the order. This includes variations of the packing slip,
   * such as the Bunnings delivery docket, and the fulfilment confirmation.
   *
   * Fulfilments created before this field was added will return an empty array,
   * because at that time, we didn't attach the generated documents to the order
   */
  packingSlips: Array<File>;
  /** Photos of the order. May be empty. */
  photos: Array<File>;
  /** The `SalesOrder` that this `Fulfilment` fulfils, either in whole or in part. */
  salesOrder: SalesOrder;
  /**
   * Number of 'items' shipping. Is non-null if the `Fulfilment` is
   * `COMPLETED`.
   */
  shipmentItemCount?: Maybe<Scalars['Int']>;
  /**
   * The `ShippingProvider` used for shipping the order. Is null until the
   * `Fulfilment` is completed, at which point it is set. However, in older
   * completed `Fulfilments`, the value may still be null.
   */
  shippingProvider?: Maybe<ShippingProvider>;
  /** The current status. */
  status: FulfilmentStatus;
  /** The `StockLocation` that the `Fulfilment` pulls stock from. */
  stockLocation: StockLocation;
};

export type FulfilmentAbandonInput = {
  /** The ID of the `Fulfilment` to abandon. */
  fulfilmentId: Scalars['ID'];
};

export type FulfilmentAttachConsignmentDataInput = {
  /** Files to attach. */
  fileIds: Array<Scalars['ID']>;
  /** The `Fulfilment` to attach the document(s) to. */
  fulfilmentId: Scalars['ID'];
};

export type FulfilmentAttachConsignmentDataPayload = {
  __typename?: 'FulfilmentAttachConsignmentDataPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

export type FulfilmentClearLineInput = {
  /** ID of the `FulfilmentLine` to remove. */
  lineId: Scalars['ID'];
};

export type FulfilmentCompleteInput = {
  /**
   * Explicitly state that you do not wish to backorder certain `SalesOrderLine`s
   * (by ID). See the documentation for the `fulfilmentComplete` mutation.
   */
  doNotBackOrderSalesOrderLines: Array<Scalars['ID']>;
  /** The `Fulfilment` to complete. Must be a `NEW` `Fulfilment`. */
  fulfilmentId: Scalars['ID'];
  /** Number of 'items' shipping. */
  shipmentItemCount: Scalars['Int'];
  /** The `ShippingProvider` to use for fulfilling. */
  shippingProviderId: Scalars['ID'];
};

export type FulfilmentCreateInput = {
  /** The ID of the `SalesOrder` the `Fulfilment` will attempt to fulfil, in whole or in part. */
  salesOrderId: Scalars['ID'];
  /** The `StockLocation` from which the `Fulfilment` will take place. */
  stockLocationId: Scalars['ID'];
};

export type FulfilmentFinalizeInput = {
  /** The `Fulfilment` to finalize. Must be `UNFINALIZED`. */
  fulfilmentId: Scalars['ID'];
  /** Photos of the order in base 64 url format. May be empty. */
  photos: Array<Scalars['String']>;
};

/** Represents a specific part of a set of fulfilled goods. */
export type FulfilmentLine = {
  __typename?: 'FulfilmentLine';
  /** Unique ID. */
  id: Scalars['ID'];
  /**
   * Packs for fulfilling the line. Some orders don't use packs, and only use a
   * total `quantityFulfilled`, in which case this will be an empty array.
   */
  packs: Array<FulfilmentLinePack>;
  /**
   * The quantity of good that is fulfilled, in the unit suitable for the line's
   * associated `SalesOrderLine`.
   */
  quantityFulfilled: Scalars['Float'];
  /**
   * The `SalesOrderLine` that this `FulfilmentLine` is fulfilling, in whole or in
   * part.
   */
  salesOrderLine: SalesOrderLine;
};

/** A pack of goods that was likely not already recognized as a pack. */
export type FulfilmentLinePack = {
  __typename?: 'FulfilmentLinePack';
  /** Unique ID. */
  id: Scalars['ID'];
  /** Weight of the pack, in kilograms. */
  kilograms: Scalars['Float'];
  /** Size of the pack, in meters cubed. */
  metersCubed: Scalars['Float'];
  /** The recorded pack log, if present */
  packLog?: Maybe<PackLog>;
  /** Unique serialized identifier, possibly with a prefix, for the pack. */
  packNumber: Scalars['String'];
  /**
   * The quantity of good in the pack, in the unit suitable for the
   * `FulfilmentLine`.
   */
  quantity: Scalars['Float'];
  /** Product the pack is composed of. */
  stockItem: StockItem;
};

export type FulfilmentLineRemovePackInput = {
  /** ID of the `FulfilmentLinePack` to remove. */
  packId: Scalars['ID'];
};

export type FulfilmentPickLineInput = {
  /** The ID of the `Fulfilment` the `FulfilmentLine` should be added to. */
  fulfilmentId: Scalars['ID'];
  /** Quantity of good to pick, in the unit suitable for the `SalesOrderLine`. */
  quantity: Scalars['Float'];
  /** The ID of the corresponding `SalesOrderLine`. */
  salesOrderLineId: Scalars['ID'];
};

export type FulfilmentPickLinePackInput = {
  /** The ID of the `Fulfilment` the pack is for. */
  fulfilmentId: Scalars['ID'];
  /** Weight of the pack, in kilograms. */
  kilograms: Scalars['Float'];
  /** Size of the pack, in meters cubed. */
  metersCubed: Scalars['Float'];
  /**
   * Unique serialized identifier, possibly with a prefix, for the pack.
   *
   * Once used, this pack cannot be used for any other `FulfilmentLine`.
   */
  packNumber: Scalars['String'];
  /**
   * The quantity of good in the pack, in the unit suitable for the
   * `FulfilmentLine`.
   */
  quantity: Scalars['Float'];
  /** The ID of the corresponding `SalesOrderLine`. */
  salesOrderLineId: Scalars['ID'];
  /** Product the pack is composed of. */
  stockCode: Scalars['ID'];
};

export type FulfilmentPrintLabelsInput = {
  /** Whether to print consignment labels. */
  consignmentLabels: Scalars['Boolean'];
  /** The `Fulfilment` to print labels for. */
  fulfilmentId: Scalars['ID'];
  /** Whether to print item (info) labels. */
  itemLabels: Scalars['Boolean'];
};

export type FulfilmentPrintPackingSlipInput = {
  /** The `Fulfilment` to print a packing slip/delivery docket for. */
  fulfilmentId: Scalars['ID'];
};

export type FulfilmentSendPendingConsignmentsInGroupInput = {
  /** The `ShippingProvider` to find fulfilments for. */
  shippingProviderId: Scalars['ID'];
  /** The `StockLocation` to find fulfilments within. */
  stockLocationId: Scalars['ID'];
};

export type FulfilmentSendPendingConsignmentsInGroupPayload = {
  __typename?: 'FulfilmentSendPendingConsignmentsInGroupPayload';
  message: Scalars['String'];
  success: Scalars['Boolean'];
};

/** The status of a `Fulfilment`. */
export enum FulfilmentStatus {
  /** Abandoned (i.e. will not actually fulfil), for any reason. */
  Abandoned = 'ABANDONED',
  /**
   * The `Fulfilment` completely describes the intended set of goods to be
   * fulfilled, and no longer requires any manual processing.
   */
  Completed = 'COMPLETED',
  /** Not yet ready for shipment. */
  New = 'NEW',
  /**
   * The `Fulfilment` completely describes the intended set of goods to be
   * fulfilled.
   */
  Unfinalized = 'UNFINALIZED'
}

/** Describes a physical transfer of a collection of physical products 'StockItem's from one location to another. */
export type InventoryTransfer = {
  __typename?: 'InventoryTransfer';
  /** Creating user. */
  createdBy: User;
  /** The date of creation. */
  dateCreated: Scalars['Date'];
  /** The date of receival. */
  dateReceived?: Maybe<Scalars['Date']>;
  /** The destination of the stock. */
  destination: StockLocation;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Where stock is drawn from. */
  origin: StockLocation;
  /** Amount of packs being transferred. */
  packCount: Scalars['Int'];
  /** List of packs to be moved. */
  packs: Array<InventoryTransferPack>;
  /** Reference. */
  reference: Scalars['String'];
  /** Only for `InventoryTransferType` `TRUCK`, specifies trucking company. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** The shipment status. */
  status: InventoryTransferStatus;
  /** The total weight in kilograms. */
  totalKilograms: Scalars['Float'];
  /** The M3. */
  totalMetersCubed: Scalars['Float'];
  /** Optional Treatment location for packs are being sent be treated  */
  treatmentPlant?: Maybe<TreatmentPlant>;
  /** The method of transportation. */
  type: InventoryTransferType;
};

export type InventoryTransferAddPackInput = {
  /** The `InventoryTransfer` this pack is being added to. */
  inventoryTransferId: Scalars['ID'];
  /** The description of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualDescription?: InputMaybe<Scalars['String']>;
  /** The shipping Destination of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualShippingDestination?: InputMaybe<Scalars['String']>;
  /** The treatment type of the stock for manual treatment transfers. Only applicable if stock code = 'MANUAL'. */
  manualTreatmentType?: InputMaybe<Scalars['String']>;
  /** M3 of pack. */
  metresCubed: Scalars['Float'];
  /** The unique ID of the pack. */
  packNumber: Scalars['String'];
  /** The quantity of the given `StockItem` contained in the pack being added. */
  quantity: Scalars['Float'];
  /** Stockcode ID of pack contents. */
  stockCode: Scalars['String'];
  /** In some cases the server may throw an error with a type of 'TREATMENT_WARNING' which the user must acknowledge prior to the pack being added. */
  treatmentWarningAcknowledged?: Scalars['Boolean'];
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: InputMaybe<Scalars['String']>;
  /** Weight of pack. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferAddPackPayload = {
  __typename?: 'InventoryTransferAddPackPayload';
  /** The `InventoryTransfer` the pack was added to. */
  inventoryTransfer: InventoryTransfer;
  /** The `InventoryTransferPack` added. */
  pack: InventoryTransferPack;
  /** Any warning messages about the pack added. */
  warning?: Maybe<Scalars['String']>;
};

export type InventoryTransferCreateInput = {
  /** The location of `InventoryTransfer` destination. */
  destinationStockLocationId: Scalars['ID'];
  /** The method of transfer. */
  inventoryTransferType: InventoryTransferType;
  /** The location of `InventoryTransfer` origin. */
  originStockLocationId: Scalars['ID'];
  /** Only for `InventoryTransferType` = `TRUCK`. Specifies trucking company */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Only for`InventoryTransferType` = `INSTANT`. Specifies treatment plant */
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferCreatePayload = {
  __typename?: 'InventoryTransferCreatePayload';
  /** The `InventoryTransfer` that has been created. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferDeleteInput = {
  /** The `InventoryTransfer` being soft deleted. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferDeletePayload = {
  __typename?: 'InventoryTransferDeletePayload';
  /** The `InventoryTransfer` that has been deleted. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferGiveUpInput = {
  /** The `InventoryTransfer` to give up. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferGiveUpPayload = {
  __typename?: 'InventoryTransferGiveUpPayload';
  /** The `InventoryTransfer` that has just been given up. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferInput = {
  __typename?: 'InventoryTransferInput';
  destinationLocationId: Scalars['ID'];
  fromLocationId: Scalars['ID'];
  inventoryTransferId: Scalars['ID'];
  packs?: Maybe<Array<InventoryTransferPack>>;
  reference?: Maybe<Scalars['String']>;
};

/** A (typically) large number of items of a particular `StockItem` packed efficiently so as to facilitate transport. */
export type InventoryTransferPack = {
  __typename?: 'InventoryTransferPack';
  /** Unique identifier. */
  id: Scalars['ID'];
  /** The `InventoryTransfer` this pack belongs to. */
  inventoryTransfer: InventoryTransfer;
  /** The description given by the user for manual packs. */
  manualDescription?: Maybe<Scalars['String']>;
  /** The total M3 of the pack. */
  metresCubed: Scalars['Float'];
  /** The pack number. */
  packNumber: Scalars['String'];
  /** Total number of this `InventoryTransferPack`. */
  quantity: Scalars['Float'];
  /** The Shipping Destination as recorded for this pack. Separate to the stock item as it may change over time. */
  shippingDestination?: Maybe<Scalars['String']>;
  /** The `StockItem` contained within. Note optional as manual transfers may not have a regular stock item associated with them. */
  stockItem?: Maybe<StockItem>;
  /** The treatment type as recorded for this pack. Separate to the stock item as it may change over time. */
  treatmentType?: Maybe<Scalars['String']>;
  /** The Unit Number if it was present in the pack's barcode */
  unitNumber?: Maybe<Scalars['String']>;
  /** The details text derived from unit number, customer etc. */
  unitText?: Maybe<Scalars['String']>;
  /** The total weight of the pack in kilograms. */
  weightKilograms: Scalars['Float'];
};

export type InventoryTransferReceiveInput = {
  /** The `InventoryTransfer` being received. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferReceivePayload = {
  __typename?: 'InventoryTransferReceivePayload';
  /** The `InventoryTransfer` that was received. */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferRedirectInput = {
  /** The new destination location. If null, destination remains the same. */
  destinationStockLocationId?: InputMaybe<Scalars['ID']>;
  /** The `InventoryTransfer` to redirect. */
  inventoryTransferId: Scalars['ID'];
  /** The new origin location. If null, origin remains the same. */
  originStockLocationId?: InputMaybe<Scalars['ID']>;
};

export type InventoryTransferRedirectPayload = {
  __typename?: 'InventoryTransferRedirectPayload';
  /** Error message, if any. */
  error?: Maybe<Scalars['String']>;
  /** The `InventoryTransfer` that has just been redirected. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
};

export type InventoryTransferRemovePackInput = {
  /** The `InventoryTransferPack` being removed from the `InventoryTransfer`. */
  packId: Scalars['ID'];
};

export type InventoryTransferRemovePackPayload = {
  __typename?: 'InventoryTransferRemovePackPayload';
  /** The `InventoryTransfer` the `InventoryTransferPack` was removed from */
  inventoryTransfer: InventoryTransfer;
};

export type InventoryTransferStartTransitInput = {
  /** The `InventoryTransfer` who's status is being set to `IN_TRANSIT`. */
  inventoryTransferId: Scalars['ID'];
  /** Reference for quick recognition of `InventoryTransfer` by office staff. */
  reference: Scalars['String'];
};

export type InventoryTransferStartTransitPayload = {
  __typename?: 'InventoryTransferStartTransitPayload';
  /** The `InventoryTransfer` describing the packs that have just left the origin `StockLocation`. */
  inventoryTransfer: InventoryTransfer;
};

/** The possible statuses of an `InventoryTransfer` in relation to its desired location. */
export enum InventoryTransferStatus {
  /** Status once received at the desired location */
  Complete = 'COMPLETE',
  /** Status upon creation at the location of origin */
  Creating = 'CREATING',
  /** Status on soft deletion. */
  Deleted = 'DELETED',
  /** Status when a user gives up on a transfer */
  Error = 'ERROR',
  /**
   * Status when error occurs in EXO update when receiving a transfer. The user may
   * safely reattempt `inventoryTransferReceive` again on a shipment with this
   * status.
   */
  ErrorReceiveTransfer = 'ERROR_RECEIVE_TRANSFER',
  /**
   * Status when error occurs in EXO update when starting a transfer. The user may
   * try to perform the `inventoryTransferStartTransit` operation again if they
   * wish. If a transfer is in this state, stock levels are not in an inconsistent
   * state - nothing has happened in EXO due to the transfer.
   */
  ErrorStartTransit = 'ERROR_START_TRANSIT',
  /**
   * Status when error occurs in EXO update when undoing a transfer. The user may
   * safely reattempt `inventoryTransferUndo` again on a shipment with this status.
   */
  ErrorUndoTransit = 'ERROR_UNDO_TRANSIT',
  /** Status during transit, on the way to the desired location */
  InTransit = 'IN_TRANSIT'
}

/** The method of transport for an `InventoryTransfer`. */
export enum InventoryTransferType {
  /** `InventoryTransfer` being sent in a shipping `CONTAINER`. */
  Container = 'CONTAINER',
  /**
   * `InventoryTransfer` is to appear immediately in the destination location,
   * without need for receipting.
   */
  Instant = 'INSTANT',
  /** `InventoryTransfer` being sent by `TRUCK`. */
  Truck = 'TRUCK'
}

export type InventoryTransferUndoInput = {
  /** The `InventoryTransfer` being reverted from status `COMPLETE` back to `IN_TRANSIT`, in case of a user error. */
  inventoryTransferId: Scalars['ID'];
};

export type InventoryTransferUndoPayload = {
  __typename?: 'InventoryTransferUndoPayload';
  /** The `InventoryTransfer` that was undone. */
  inventoryTransfer: InventoryTransfer;
};

export type Kiln = {
  __typename?: 'Kiln';
  id: Scalars['ID'];
};

export type KilnCharge = {
  __typename?: 'KilnCharge';
  chargeNumber: Scalars['String'];
  id: Scalars['ID'];
};

export type KilnChargeItem = {
  __typename?: 'KilnChargeItem';
  id: Scalars['ID'];
};

export type LabelPrintCommandInput = {
  /**
   * The data to apply to the `DocumentTemplate` to generate a document.
   * If the `DocumentTemplate` provides default/example data, this is optional.
   */
  data: Array<LabelTemplateFieldData>;
  /** The number of copies to print. Must be nonnegative. */
  printQuantity: Scalars['Int'];
  /** The printer to print to. */
  printerId: Scalars['ID'];
  /** The `LabelTemplate` that should be used. For example 'Porta-FSC'. */
  templateId: Scalars['ID'];
};

export type LabelPrintInput = {
  /** List of commands for printing labels. */
  commands: Array<LabelPrintCommandInput>;
};

export type LabelPrintPayload = {
  __typename?: 'LabelPrintPayload';
  message: Scalars['String'];
};

/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplate = {
  __typename?: 'LabelTemplate';
  /** Free text, way of loosely categorizing templates (group by category). */
  category?: Maybe<Scalars['String']>;
  /**
   * The printer that should be suggested automatically if printing labels with
   * this template.
   */
  defaultPrinter?: Maybe<Scalars['String']>;
  /** Fields for customizing generated labels. */
  fields: Array<LabelTemplateField>;
  /** Unique identifier. */
  id: Scalars['ID'];
  /** Name of the template. */
  name: Scalars['String'];
  /**
   * Preset document, if any, in XLSX format, as a base64 string. Can use `age`
   * in the same was as in the `presets` field.
   */
  presetDocument?: Maybe<Scalars['String']>;
  /** How presets should be handled, or whether allowed at all. */
  presetKind: LabelTemplatePresetKind;
  /**
   * Preset data, empty array if none. Can use `age` to get previous versions.
   *   - `age = 0`: current version
   *   - `age = 1`: version before current
   *   - etc.
   */
  presets: Array<Array<Scalars['String']>>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetDocumentArgs = {
  age?: InputMaybe<Scalars['Int']>;
};


/** Describes a template that can be used to make labels of a particular kind. */
export type LabelTemplatePresetsArgs = {
  age?: InputMaybe<Scalars['Int']>;
};

/** A point of customization for labels generated with a `LabelTemplate`. */
export type LabelTemplateField = {
  __typename?: 'LabelTemplateField';
  /** For multiline fields, how many lines may typically be used. */
  defaultLineCount?: Maybe<Scalars['Int']>;
  /** Comment or example input to clarify the purpose of the field. */
  extraInfo: Scalars['String'];
  /** Null if the field is there for clarity in presets, and not for the label. */
  internalName?: Maybe<Scalars['String']>;
  /** Guide as to how the field should be entered. */
  kind: LabelTemplateFieldKind;
  /** User-friendly field name. */
  name: Scalars['String'];
  /** Whether this field should be provided by a preset, or entered manually. */
  preset: Scalars['Boolean'];
};

export type LabelTemplateFieldData = {
  field: Scalars['String'];
  value: Scalars['String'];
};

export enum LabelTemplateFieldKind {
  /** The field accepts multiline text. */
  Multiline = 'MULTILINE',
  /** The field should be given normal text. */
  Text = 'TEXT'
}

export enum LabelTemplatePresetKind {
  /** Can use presets, but with multi-select printing. */
  Multi = 'MULTI',
  /** Cannot use presets for this template */
  None = 'NONE',
  /** Can use presets, but with single-select printing. */
  Single = 'SINGLE'
}

export type LabelTemplateUploadPresetsError = LabelTemplateUploadPresetsInvalidDataError | LabelTemplateUploadPresetsNotFoundError;

export type LabelTemplateUploadPresetsInput = {
  /** The label template to upload presets to. */
  labelTemplateId: Scalars['ID'];
  /** The presets file, in XLSX format. */
  presets: Scalars['Upload'];
};

export type LabelTemplateUploadPresetsInvalidDataError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsInvalidDataError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsNotFoundError = UserError & {
  __typename?: 'LabelTemplateUploadPresetsNotFoundError';
  message: Scalars['String'];
};

export type LabelTemplateUploadPresetsPayload = {
  __typename?: 'LabelTemplateUploadPresetsPayload';
  userErrors: Array<LabelTemplateUploadPresetsError>;
};

/** Simple filtering object. */
export enum ListFilter {
  /** Exclude entities with this property. */
  Exclude = 'EXCLUDE',
  /** Include entities with this property. */
  Include = 'INCLUDE',
  /** Only include entities with this property. */
  Only = 'ONLY'
}

export type Location = {
  __typename?: 'Location';
  id: Scalars['ID'];
};

export type Mutation = {
  __typename?: 'Mutation';
  /**
   * Generates consignments for the fulfilments given and sends them in a group.
   *
   * If the shipping provider isn't supported for sending batch consignments, then
   * nothing will happen.
   *
   * This will not modify the `Fulfilment`s after the batch is sent, so it is
   * advisable to instead use `fulfilmentSendPendingConsignments`, which will
   * record data properly.
   */
  consignmentBatchGenerateFromFulfilments: ConsignmentBatchGenerateFromFulfilmentsPayload;
  /**
   * Generates a consignment from the provided information and automatically sends
   * it to the appropriate shipping provider.
   *
   * If the shipping provider isn't supported for sending single consignments, then
   * nothing will happen.
   */
  consignmentGenerateFromFulfilment: ConsignmentGenerateFromFulfilmentPayload;
  /**
   * Print the labels that would have been printed previously for the given
   * fulfilment.
   */
  consignmentPrintLabels: ConsignmentPrintLabelsPayload;
  /** Create a counter with the given initial data. */
  counterCreate: Scalars['Boolean'];
  /**
   * Fetches the current counter information and returns it, and then updates the
   * counter as appropriate. If the counter does not exist, will error. You
   * can choose to handle this and create the counter on failure if desired.
   *
   * If `multiplier` is specified, will behave as if the counter was used that many
   * times.
   */
  counterUse: Counter;
  /**
   * Generates a `DebtorInvoice` from a `Fulfilment`. If a specific EDI procedure
   * is implemented for the `Debtor`, will use that implementation. Otherwise will
   * register a `BusinessEvent` so that the `DebtorInvoice` can be done manually.
   */
  debtorInvoiceGenerateFromFulfilment: DebtorInvoiceGenerateFromFulfilmentPayload;
  /** Generate documents using a `DocumentTemplate` and data. */
  documentGenerate: DocumentGeneratePayload;
  /** Print documents to a printer using a URL. */
  documentPrint: DocumentPrintPayload;
  /** Publish event(s) to a topic on our Azure EventGrid domain. */
  eventPublish: EventPublishPayload;
  /**
   * Abandons a `Fulfilment`, for any reason.
   * Only possible for `NEW` `Fulfilment`s.
   */
  fulfilmentAbandon: Scalars['Boolean'];
  /** Attach consignment data to a `Fulfilment`. */
  fulfilmentAttachConsignmentData: FulfilmentAttachConsignmentDataPayload;
  /**
   * Removes a line from a `Fulfilment`, for whatever reason.
   * Only possible for `NEW` `Fulfilment`s.
   */
  fulfilmentClearLine: Scalars['Boolean'];
  /**
   * Completes a `Fulfilment`. Only possible for `NEW` `Fulfilment`s. Will make
   * the fulfilment `UNFINALIZED`.
   *
   * This triggers a number of additional processes, depending on the business
   * context. These may include automatic supplying and invoicing along with a
   * status change in the financial system, document/label printing, shipping
   * consignment EDI, and customer EDI.
   *
   * If the business context around this order means that the order should be
   * supplied in the financial system when completing, the following happens:
   * - `SalesOrderLine`s that have been fulfilled will be supplied by the
   *   appropriate quantity, and the remaining unsupplied quantity (if any) will be
   *   put on backorder unless explicitly stated otherwise with
   *   `doNotBackOrderSalesOrderLines`
   * - `SalesOrderLine`s that were not fulfilled for the `Fulfilment`'s specific
   *   stock location will be put fully onto backorder, unless explicitly stated
   *   otherwise.
   * - Any freight/discount/special lines are supplied automatically in full.
   *
   * If the order is invoiced, the order is invoiced based on however it was just
   * supplied.
   */
  fulfilmentComplete: Scalars['Boolean'];
  /** Creates a `Fulfilment`, returning it's ID. */
  fulfilmentCreate: Scalars['ID'];
  /**
   * Completes final actions after completion of the `Fulfilment`, such as taking a
   * photo of the order.
   *
   * This photo must be taken after the `Fulfilment` is completed, for it should
   * contain the info labels printed out upon completion of the `Fulfilment`.
   */
  fulfilmentFinalize: Scalars['Boolean'];
  /**
   * Removes a single `FulfilmentLinePack` from a `FulfilmentLine`.
   * Only possible for `NEW` `Fulfilment`s.
   */
  fulfilmentLineRemovePack: Scalars['Boolean'];
  /**
   * Adds/updates a line to a `Fulfilment`. Corresponds to physically picking an
   * item. Returns the line's ID.
   *
   * Only possible for `NEW` `Fulfilment`s which don't have any packs on the
   * specified line.
   */
  fulfilmentPickLine: Scalars['ID'];
  /**
   * Adds a `FulfilmentLinePack` to a `FulfilmentLine`, creating the line if it
   * doesn't exist. Returns the pack's ID.
   *
   * Only possible for `NEW` `Fulfilment`s.
   * If a `Fulfilment` has a non-zero quantity fulfilled, but no packs, then
   * this will fail, as packs should represent the line, not quantity.
   */
  fulfilmentPickLinePack: Scalars['ID'];
  /**
   * Prints labels for a given `Fulfilment`. Useful for manually triggering when
   * something went wrong with a `Fulfilment` or the document was lost. Uses the
   * printers configured.
   */
  fulfilmentPrintLabels: Scalars['Boolean'];
  /**
   * Prints the packing slip (or delivery docket) for a given
   * `Fulfilment`. Useful for manually triggering when something went wrong with a
   * `Fulfilment` or the document was lost. Uses the printers configured.
   */
  fulfilmentPrintPackingSlip: Scalars['Boolean'];
  /**
   * Finds `Fulfilment`s associated with a `ShippingProvider` that wants data in
   * batches, and generates and sends any consignments in a group to the provider.
   */
  fulfilmentSendPendingConsignmentsInGroup: FulfilmentSendPendingConsignmentsInGroupPayload;
  /** Adds a pack to an `InventoryTransfer`. */
  inventoryTransferAddPack: InventoryTransferAddPackPayload;
  /** Create a new `InventoryTransfer` of status `CREATING` with no packs. */
  inventoryTransferCreate: InventoryTransferCreatePayload;
  /** Soft deletes an `InventoryTransfer`. Keeping the entity data but marking it as deleted */
  inventoryTransferDelete: InventoryTransferDeletePayload;
  /** Set the `InventoryTransfer`s status to `ERROR` if a transfer is created with stock items that are not available at the origin or destination location */
  inventoryTransferGiveUp: InventoryTransferGiveUpPayload;
  /** The `InventoryTransfer` that has just been received at the destination `StockLocation`. Status will change accordingly to `COMPLETE`. */
  inventoryTransferReceive: InventoryTransferReceivePayload;
  /**
   * Change the origin and destination locations for an `InventoryTransfer`. This
   * can be done for both in transit and completed transfers.
   */
  inventoryTransferRedirect: InventoryTransferRedirectPayload;
  /** Remove a pack from an `InventoryTransfer`. */
  inventoryTransferRemovePack: InventoryTransferRemovePackPayload;
  /** The `InventoryTransfer` that has just left the origin `StockLocation`. Status will change accordingly to `IN_TRANSIT`. */
  inventoryTransferStartTransit: InventoryTransferStartTransitPayload;
  /**
   * Moves a `InventoryTransfer` from `COMPLETE` back to `IN_TRANSIT`, and creates
   * stock movements which reverse those that came from receiving. Would be used if
   * someone messed up.
   */
  inventoryTransferUndo: InventoryTransferUndoPayload;
  /** Print a label using a `LabelTemplate` and data. */
  labelPrint: LabelPrintPayload;
  /** Upload a set of presets to a `LabelTemplate`. */
  labelTemplateUploadPresets: LabelTemplateUploadPresetsPayload;
  /** Puts all lines of a Sales order on back order */
  salesOrderBackOrder: Scalars['Boolean'];
  /**
   * Creates a `SalesOrder`, available via the financial system. Returns the ID of
   * the new `SalesOrder`.
   */
  salesOrderCreate: Scalars['ID'];
  /**
   * Supply lines on a `SalesOrder`. Will not allow you to supply more than the
   * unsupplied quantity. Quantities must be positive.
   */
  salesOrderSupply: Scalars['Boolean'];
  /** Replace the internal reference of a `SalesOrder` with the given value. */
  salesOrderUpdateInternalReference: Scalars['Boolean'];
  /**
   * Replace the picked by `User` of a `SalesOrder` with the given value. If the
   * given `User` isn't able to be assigned to this field, will clear the field
   * instead.
   */
  salesOrderUpdatePickedBy: Scalars['Boolean'];
  /** Sets the shipping provider for a `SalesOrder`'s most recent fulfilment. */
  salesOrderUpdateShippingProvider: Scalars['Boolean'];
  /** Adjust out packs at a location, 'consuming' the packs. */
  stockConsume: StockConsumePayload;
  /** Create a (Universal Print) pack label and perform related operations. */
  stockCreate: StockCreatePayload;
  /** Create a product pack label for a single product, and adjust the product in. */
  stockCreateSingle: StockCreateSinglePayload;
  /** Attempt to undo the `stockCreateSingle` operation. */
  stockCreateSingleUndo: StockCreateSingleUndoPayload;
  /** Attempt to undo the `stockCreate` operation, or redo after an undo. */
  stockCreateUndo: StockCreateUndoPayload;
  /**
   * Get one or more handles, which can be used to update existing files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned. Requires the `Documents.Admin` scope, and the files must be
   * configured with update permissions.
   */
  updateHandlesGet: UpdateHandlesGetPayload;
  /**
   * Get one or more handles, which can be used to upload files for approximately
   * 60 minutes. For each element in the input array, one `UploadHandle` is
   * returned.
   */
  uploadHandlesGet: UploadHandlesGetPayload;
  /**
   * Provides URLs with an SAS to a file blob in Azure, to which original sources
   * can be uploaded for WIP items.
   */
  wipFileUploadGetHandles: WipFileUploadGetHandlesPayload;
  /** Adds a new line to the end of an order. */
  wipSalesOrderAddLine: WipSalesOrderAddLinePayload;
  /** Attaches a document to a `WipSalesOrder`, for archival purposes or otherwise. */
  wipSalesOrderAttachDocuments: WipSalesOrderAttachDocumentsPayload;
  /** Creates a new work-in-progress sales order. */
  wipSalesOrderCreate: WipSalesOrderCreatePayload;
  /**
   * Deletes a `WipSalesOrder`, meaning that the order will never be sent into the
   * financial system.
   */
  wipSalesOrderDelete: WipSalesOrderDeletePayload;
  /** Provides the `backOrder` for an order, replacing any previous value. */
  wipSalesOrderProvideBackOrder: WipSalesOrderProvideBackOrderPayload;
  /** Provides the `customerId` for an order, replacing any previous value. */
  wipSalesOrderProvideCustomerId: WipSalesOrderProvideCustomerIdPayload;
  /** Provides the `CustomerName` for an order, replacing any previous value. */
  wipSalesOrderProvideCustomerName: WipSalesOrderProvideCustomerNamePayload;
  /** Provides the `CustomerPhoneNumber` for an order, replacing any previous value. */
  wipSalesOrderProvideCustomerPhoneNumber: WipSalesOrderProvideCustomerPhoneNumberPayload;
  /** Provides the `customerReference` for an order, replacing any previous value. */
  wipSalesOrderProvideCustomerReference: WipSalesOrderProvideCustomerReferencePayload;
  /** Provides the `datePaymentDue` for an order, replacing any previous value. */
  wipSalesOrderProvideDatePaymentDue: WipSalesOrderProvideDatePaymentDuePayload;
  /**
   * Provides the `defaultStockLocation` for an order, replacing any previous
   * value.
   */
  wipSalesOrderProvideDefaultStockLocation: WipSalesOrderProvideDefaultStockLocationPayload;
  /** Provides the `deliveryAddress` for an order, replacing any previous value. */
  wipSalesOrderProvideDeliveryAddress: WipSalesOrderProvideDeliveryAddressPayload;
  /** Provides the `emailOverride` for an order, replacing any previous value. */
  wipSalesOrderProvideEmailOverride: WipSalesOrderProvideEmailOverridePayload;
  /** Provides the `Instructions` for an order, replacing any previous value. */
  wipSalesOrderProvideInstructions: WipSalesOrderProvideInstructionsPayload;
  /** Provides the `internalReference` for an order, replacing any previous value. */
  wipSalesOrderProvideInternalReference: WipSalesOrderProvideInternalReferencePayload;
  /**
   * Provides the `description` for a line on an order, replacing any previous
   * value.
   */
  wipSalesOrderProvideLineDescription: WipSalesOrderProvideLineDescriptionPayload;
  /**
   * Provides the `quantityOrdered` for a line on an order, replacing any previous
   * value.
   */
  wipSalesOrderProvideLineQuantity: WipSalesOrderProvideLineQuantityPayload;
  /** Provides the `stockCode` for a line on an order, replacing any previous value. */
  wipSalesOrderProvideLineStockCode: WipSalesOrderProvideLineStockCodePayload;
  /** Provides the `stock item` for an order line, replacing any previous values. */
  wipSalesOrderProvideLineStockItem: WipSalesOrderProvideLineStockItemPayload;
  /**
   * Provides the `stockLocation` for a line on an order, replacing any previous
   * value.
   */
  wipSalesOrderProvideLineStockLocation: WipSalesOrderProvideLineStockLocationPayload;
  /** Provides the `unitPrice` for a line on an order, replacing any previous value. */
  wipSalesOrderProvideLineUnitPrice: WipSalesOrderProvideLineUnitPricePayload;
  /** Provides the `narrative` for an order, replacing any previous value. */
  wipSalesOrderProvideNarrative: WipSalesOrderProvideNarrativePayload;
  /** Provides the `onHold` for an order, replacing any previous value. */
  wipSalesOrderProvideOnHold: WipSalesOrderProvideOnHoldPayload;
  /** Provides the `salesBranch` for an order, replacing any previous value. */
  wipSalesOrderProvideSalesBranch: WipSalesOrderProvideSalesBranchPayload;
  /** Provides the `shippingProvider` for an order, replacing any previous value. */
  wipSalesOrderProvideShippingProvider: WipSalesOrderProvideShippingProviderPayload;
  /** Provides the `taxType` for an order, replacing any previous value. */
  wipSalesOrderProvideTaxType: WipSalesOrderProvideTaxTypePayload;
  /**
   * Rejects a `WipSalesOrder`, meaning that the order will never be sent into the
   * financial system.
   */
  wipSalesOrderReject: WipSalesOrderRejectPayload;
  /**
   * Removes a line from an order. If the order was added after submission, the
   * line is fully removed. Otherwise, it is 'suppressed' and still visible, and
   * can later be restored.
   */
  wipSalesOrderRemoveLine: WipSalesOrderRemoveLinePayload;
  /** Undoes the effect of removing a line that was 'suppressed' from an order. */
  wipSalesOrderRestoreLine: WipSalesOrderRestoreLinePayload;
  /** Sends a `WipSalesOrder` to the financial system, if the order is `SENDABLE`. */
  wipSalesOrderSend: WipSalesOrderSendPayload;
  /**
   * Submits a new work-in-progress sales order, which is hoped to eventually be
   * sent to the financial system.
   */
  wipSalesOrderSubmit: WipSalesOrderSubmitPayload;
  /**
   * Run rules that modify the wip order under certain conditions. For example,
   * this may shift lines to Auckland when there is enough stock in that location.
   */
  wipSalesOrderTriggerRules: WipSalesOrderTriggerRulesPayload;
};


export type MutationConsignmentBatchGenerateFromFulfilmentsArgs = {
  input: ConsignmentBatchGenerateFromFulfilmentsInput;
};


export type MutationConsignmentGenerateFromFulfilmentArgs = {
  input: ConsignmentGenerateFromFulfilmentInput;
};


export type MutationConsignmentPrintLabelsArgs = {
  input: ConsignmentPrintLabelsInput;
};


export type MutationCounterCreateArgs = {
  input: CounterCreateInput;
};


export type MutationCounterUseArgs = {
  id: Scalars['ID'];
  multiplier?: InputMaybe<Scalars['Int']>;
};


export type MutationDebtorInvoiceGenerateFromFulfilmentArgs = {
  input: DebtorInvoiceGenerateFromFulfilmentInput;
};


export type MutationDocumentGenerateArgs = {
  input: DocumentGenerateInput;
};


export type MutationDocumentPrintArgs = {
  input: DocumentPrintInput;
};


export type MutationEventPublishArgs = {
  input: EventPublishInput;
};


export type MutationFulfilmentAbandonArgs = {
  input: FulfilmentAbandonInput;
};


export type MutationFulfilmentAttachConsignmentDataArgs = {
  input: FulfilmentAttachConsignmentDataInput;
};


export type MutationFulfilmentClearLineArgs = {
  input: FulfilmentClearLineInput;
};


export type MutationFulfilmentCompleteArgs = {
  input: FulfilmentCompleteInput;
};


export type MutationFulfilmentCreateArgs = {
  input: FulfilmentCreateInput;
};


export type MutationFulfilmentFinalizeArgs = {
  input: FulfilmentFinalizeInput;
};


export type MutationFulfilmentLineRemovePackArgs = {
  input: FulfilmentLineRemovePackInput;
};


export type MutationFulfilmentPickLineArgs = {
  input: FulfilmentPickLineInput;
};


export type MutationFulfilmentPickLinePackArgs = {
  input: FulfilmentPickLinePackInput;
};


export type MutationFulfilmentPrintLabelsArgs = {
  input: FulfilmentPrintLabelsInput;
};


export type MutationFulfilmentPrintPackingSlipArgs = {
  input: FulfilmentPrintPackingSlipInput;
};


export type MutationFulfilmentSendPendingConsignmentsInGroupArgs = {
  input: FulfilmentSendPendingConsignmentsInGroupInput;
};


export type MutationInventoryTransferAddPackArgs = {
  input: InventoryTransferAddPackInput;
};


export type MutationInventoryTransferCreateArgs = {
  input: InventoryTransferCreateInput;
};


export type MutationInventoryTransferDeleteArgs = {
  input: InventoryTransferDeleteInput;
};


export type MutationInventoryTransferGiveUpArgs = {
  input: InventoryTransferGiveUpInput;
};


export type MutationInventoryTransferReceiveArgs = {
  input: InventoryTransferReceiveInput;
};


export type MutationInventoryTransferRedirectArgs = {
  input: InventoryTransferRedirectInput;
};


export type MutationInventoryTransferRemovePackArgs = {
  input: InventoryTransferRemovePackInput;
};


export type MutationInventoryTransferStartTransitArgs = {
  input: InventoryTransferStartTransitInput;
};


export type MutationInventoryTransferUndoArgs = {
  input: InventoryTransferUndoInput;
};


export type MutationLabelPrintArgs = {
  input: LabelPrintInput;
};


export type MutationLabelTemplateUploadPresetsArgs = {
  input: LabelTemplateUploadPresetsInput;
};


export type MutationSalesOrderBackOrderArgs = {
  input: SalesOrderBackOrderInput;
};


export type MutationSalesOrderCreateArgs = {
  input: SalesOrderCreateInput;
};


export type MutationSalesOrderSupplyArgs = {
  input: SalesOrderSupplyInput;
};


export type MutationSalesOrderUpdateInternalReferenceArgs = {
  input: SalesOrderUpdateInternalReferenceInput;
};


export type MutationSalesOrderUpdatePickedByArgs = {
  input: SalesOrderUpdatePickedByInput;
};


export type MutationSalesOrderUpdateShippingProviderArgs = {
  input: SalesOrderUpdateShippingProviderInput;
};


export type MutationStockConsumeArgs = {
  input: StockConsumeInput;
};


export type MutationStockCreateArgs = {
  input: StockCreateInput;
};


export type MutationStockCreateSingleArgs = {
  input: StockCreateSingleInput;
};


export type MutationStockCreateSingleUndoArgs = {
  input: StockCreateSingleUndoInput;
};


export type MutationStockCreateUndoArgs = {
  input: StockCreateUndoInput;
};


export type MutationUpdateHandlesGetArgs = {
  input: UpdateHandlesGetInput;
};


export type MutationUploadHandlesGetArgs = {
  input: UploadHandlesGetInput;
};


export type MutationWipFileUploadGetHandlesArgs = {
  input: WipFileUploadGetHandlesInput;
};


export type MutationWipSalesOrderAddLineArgs = {
  input: WipSalesOrderAddLineInput;
};


export type MutationWipSalesOrderAttachDocumentsArgs = {
  input: WipSalesOrderAttachDocumentsInput;
};


export type MutationWipSalesOrderDeleteArgs = {
  input: WipSalesOrderDeleteInput;
};


export type MutationWipSalesOrderProvideBackOrderArgs = {
  input: WipSalesOrderProvideBackOrderInput;
};


export type MutationWipSalesOrderProvideCustomerIdArgs = {
  input: WipSalesOrderProvideCustomerIdInput;
};


export type MutationWipSalesOrderProvideCustomerNameArgs = {
  input: WipSalesOrderProvideCustomerNameInput;
};


export type MutationWipSalesOrderProvideCustomerPhoneNumberArgs = {
  input: WipSalesOrderProvideCustomerPhoneNumberInput;
};


export type MutationWipSalesOrderProvideCustomerReferenceArgs = {
  input: WipSalesOrderProvideCustomerReferenceInput;
};


export type MutationWipSalesOrderProvideDatePaymentDueArgs = {
  input: WipSalesOrderProvideDatePaymentDueInput;
};


export type MutationWipSalesOrderProvideDefaultStockLocationArgs = {
  input: WipSalesOrderProvideDefaultStockLocationInput;
};


export type MutationWipSalesOrderProvideDeliveryAddressArgs = {
  input: WipSalesOrderProvideDeliveryAddressInput;
};


export type MutationWipSalesOrderProvideEmailOverrideArgs = {
  input: WipSalesOrderProvideEmailOverrideInput;
};


export type MutationWipSalesOrderProvideInstructionsArgs = {
  input: WipSalesOrderProvideInstructionsInput;
};


export type MutationWipSalesOrderProvideInternalReferenceArgs = {
  input: WipSalesOrderProvideInternalReferenceInput;
};


export type MutationWipSalesOrderProvideLineDescriptionArgs = {
  input: WipSalesOrderProvideLineDescriptionInput;
};


export type MutationWipSalesOrderProvideLineQuantityArgs = {
  input: WipSalesOrderProvideLineQuantityInput;
};


export type MutationWipSalesOrderProvideLineStockCodeArgs = {
  input: WipSalesOrderProvideLineStockCodeInput;
};


export type MutationWipSalesOrderProvideLineStockItemArgs = {
  input: WipSalesOrderProvideLineStockItemInput;
};


export type MutationWipSalesOrderProvideLineStockLocationArgs = {
  input: WipSalesOrderProvideLineStockLocationInput;
};


export type MutationWipSalesOrderProvideLineUnitPriceArgs = {
  input: WipSalesOrderProvideLineUnitPriceInput;
};


export type MutationWipSalesOrderProvideNarrativeArgs = {
  input: WipSalesOrderProvideNarrativeInput;
};


export type MutationWipSalesOrderProvideOnHoldArgs = {
  input: WipSalesOrderProvideOnHoldInput;
};


export type MutationWipSalesOrderProvideSalesBranchArgs = {
  input: WipSalesOrderProvideSalesBranchInput;
};


export type MutationWipSalesOrderProvideShippingProviderArgs = {
  input: WipSalesOrderProvideShippingProviderInput;
};


export type MutationWipSalesOrderProvideTaxTypeArgs = {
  input: WipSalesOrderProvideTaxTypeInput;
};


export type MutationWipSalesOrderRejectArgs = {
  input: WipSalesOrderRejectInput;
};


export type MutationWipSalesOrderRemoveLineArgs = {
  input: WipSalesOrderRemoveLineInput;
};


export type MutationWipSalesOrderRestoreLineArgs = {
  input: WipSalesOrderRestoreLineInput;
};


export type MutationWipSalesOrderSendArgs = {
  input: WipSalesOrderSendInput;
};


export type MutationWipSalesOrderSubmitArgs = {
  input: WipSalesOrderSubmitInput;
};


export type MutationWipSalesOrderTriggerRulesArgs = {
  input: WipSalesOrderTriggerRulesInput;
};

export type PackLog = {
  __typename?: 'PackLog';
  consumed: Scalars['Boolean'];
  deleted: Scalars['Boolean'];
  id: Scalars['ID'];
  lastUpdated: Scalars['DateTime'];
  packNumber: Scalars['String'];
  printer: Scalars['String'];
  /** Quantity of the pack in the stock item's default unit. */
  quantity: Scalars['Float'];
  stockItem: StockItem;
  /**
   * For variable length packs, describes the composition of the pack.
   * If the pack is not of variable length products (or those configured as such),
   * or the pack was created prior to this feature being added, then this will be
   * an empty array.
   */
  tally: Array<Tally>;
  unitNumber?: Maybe<Scalars['String']>;
  unitNumberCategory?: Maybe<Scalars['String']>;
  workstation: PackWorkstation;
};

export type PackWorkstation = {
  __typename?: 'PackWorkstation';
  id: Scalars['ID'];
};

/**
 * Describes a grouping of sales silos (`SalesBranch`es) to be used to filter
 * orders when picking.
 */
export type PickGroup = {
  __typename?: 'PickGroup';
  /** Associated branches. */
  branches: Array<SalesBranch>;
  /** Unique ID, as configured. */
  id: Scalars['ID'];
  /** Readable name for the `PickGroup`. */
  name: Scalars['String'];
};

/** Information about a file that exists (though might not yet be uploaded). */
export type PresentFile = {
  __typename?: 'PresentFile';
  /**
   * The base URL, but with SAS query parameters providing read access to the
   * file for approximately 60 minutes.
   */
  accessUrl: Scalars['String'];
  /** URI for the location of the blob. */
  baseUrl: Scalars['String'];
  /** Permissions configured for the file. */
  permissions: FilePermissions;
};

/** A device (physical or not) that documents can be printed to. */
export type Printer = {
  __typename?: 'Printer';
  /** Unique ID for the printer */
  id: Scalars['ID'];
};

export type Query = {
  __typename?: 'Query';
  /**
   * Here so that this service can be tested in isolation without the gateway
   * complaining about an empty `Query` type. Will not resolve.
   */
  _messaging: Scalars['Boolean'];
  _null?: Maybe<Scalars['String']>;
  /**
   * The latest date for which invoicing is permitted. This value corresponds to
   * the end of the current `Debtor` ledger period.
   *
   * This field may eventually be deprecated and replaced with a more comprehensive
   * ledger API.
   */
  canInvoiceUntil: Scalars['Date'];
  /** Fetch a counter without modifying. */
  counter?: Maybe<Counter>;
  /** Fetch all counters without modifying. */
  counters: Array<Counter>;
  /** List of contacts for an account in the CRM. */
  crmAccountContacts: Array<CrmContact>;
  /** Get all `CrmSalesOrder`s created after the given date. */
  crmSalesOrders: Array<CrmSalesOrder>;
  /** Returns a set of trade stores that are associated with the company. */
  crmTradeStores: Array<CrmAccount>;
  /**
   * Returns the `Debtor` corresponding to the given ID, ID + ParentId, GLN or store code, or null
   * if not found. Must provide either an ID or a GLN code or a store code.
   */
  debtor?: Maybe<Debtor>;
  /** Gets a `DebtorInvoice`, or null if it doesn't exist. */
  debtorInvoice?: Maybe<DebtorInvoice>;
  /** Gets a list of `DebtorInvoice`s. */
  debtorInvoices: Array<DebtorInvoice>;
  /** Returns a set of `Debtor`s. */
  debtors: Array<Debtor>;
  /** Get a `File` by id */
  file?: Maybe<File>;
  /** Get `File`s by id */
  files: Array<File>;
  /**
   * Returns the `Fulfilment` corresponding to the given ID, or null if not found.
   * By default, will not return abandoned `Fulfilment`s. This can be changed via
   * `includeAbandoned`.
   */
  fulfilment?: Maybe<Fulfilment>;
  /**
   * Returns a set of `Fulfilment`s. By default, will not return those that were
   * abandoned.
   */
  fulfilments: Array<Fulfilment>;
  /** Get the `InventoryTransfer` with the given ID, or null if does not exist. */
  inventoryTransfer?: Maybe<InventoryTransfer>;
  /** List `InventoryTransfer`s with filtering and other options. */
  inventoryTransfers: Array<InventoryTransfer>;
  /** Get a `LabelTemplate` by ID if it exists, or null if none found. */
  labelTemplate?: Maybe<LabelTemplate>;
  /** Returns a list of all/many `LabelTemplate`s. */
  labelTemplates: Array<LabelTemplate>;
  /**
   * Attempts to fetch a latitude and a longitude for a location with the given
   * address. Returns a non-null result upon success.
   */
  locate?: Maybe<Coordinate>;
  /** Gets the current user as recognized by the authentication system. */
  me?: Maybe<User>;
  /** Get a pack log by it's pack number, returning null if not present. */
  packLogByPackNumber?: Maybe<PackLog>;
  /** Fetch pack logs, in order of most recent to least. */
  packLogs: Array<PackLog>;
  /** Get a list of pick groups, with the given IDs if provided, or otherwise all. */
  pickGroups: Array<PickGroup>;
  /** Get a printer by ID if it exists. If a printer is returned, it may be printed to. */
  printer?: Maybe<Printer>;
  /** The list of printers that can be printed to */
  printers: Array<Printer>;
  /** Get all `SalesBranch`es. */
  salesBranches: Array<SalesBranch>;
  /** Returns the `SalesOrder` corresponding to the given ID, or null if not found. */
  salesOrder?: Maybe<SalesOrder>;
  /**
   * Returns the `SalesOrderLine` corresponding to the given ID, or null if not
   * found.
   */
  salesOrderLine?: Maybe<SalesOrderLine>;
  /** Returns a set of `SalesOrderLine`s. */
  salesOrderLines: Array<SalesOrderLine>;
  /** Returns a set of `SalesOrder`s. */
  salesOrders: Array<SalesOrder>;
  /** Get all `ShippingProvider`s. */
  shippingProviders: Array<ShippingProvider>;
  /**
   * Returns the `StockItem` corresponding to the given stock code, or null if not
   * found.
   */
  stockItem?: Maybe<StockItem>;
  /**
   * Determines the best price possible (price that should be quoted) for a
   * `StockItem` given other information. Takes a list of inputs and returns
   * outputs in the same order.
   */
  stockItemCurrentBestPrices: Array<StockItemBestPriceResult>;
  /** Returns a set of `StockItem`s. */
  stockItems: Array<StockItem>;
  /** Returns a set of `StockItem`s, 'fuzzy searched' on the description field. */
  stockItemsFuzzySearch: Array<StockItem>;
  /** Get a list of all `StockLocation`s. */
  stockLocations: Array<StockLocation>;
  /**
   * Not authenticated, can be used to test whether you are able to access
   * the API.
   */
  test: TestResult;
  treatmentHistory: TreatmentHistorySearchResult;
  treatmentPlants: Array<TreatmentPlant>;
  /** Get a list of all `UnitsOfMeasure */
  unitsOfMeasure: Array<UnitOfMeasure>;
  /** Get's the `User` with the given ID, or null if not found. */
  user?: Maybe<User>;
  /**
   * Fetches a `User` by their email.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByEmail?: Maybe<User>;
  /**
   * Attempts to log in using the given PIN.
   * Returns null if no such user exists, otherwise the `User`.
   */
  userByPin?: Maybe<User>;
  /** Get's the `User`s with the given IDs or all `User`s. */
  users: Array<User>;
  /** Fetch a single `WipSalesOrder`, or null if not present. */
  wipSalesOrder?: Maybe<WipSalesOrder>;
  /** Fetch a set of `WipSalesOrder`s. */
  wipSalesOrders: Array<WipSalesOrder>;
};


export type QueryCounterArgs = {
  id: Scalars['ID'];
};


export type QueryCrmAccountContactsArgs = {
  crmAccountId: Scalars['ID'];
};


export type QueryCrmSalesOrdersArgs = {
  createdAfter: Scalars['Date'];
};


export type QueryDebtorArgs = {
  gln?: InputMaybe<Scalars['String']>;
  id?: InputMaybe<Scalars['ID']>;
  name?: InputMaybe<Scalars['String']>;
  parentId?: InputMaybe<Scalars['String']>;
  storeCode?: InputMaybe<Scalars['String']>;
};


export type QueryDebtorInvoiceArgs = {
  id: Scalars['ID'];
};


export type QueryDebtorInvoicesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryDebtorsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryFileArgs = {
  id: Scalars['ID'];
};


export type QueryFilesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QueryFulfilmentArgs = {
  id: Scalars['ID'];
  includeAbandoned?: InputMaybe<Scalars['Boolean']>;
};


export type QueryFulfilmentsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  mostRecent?: InputMaybe<Scalars['Boolean']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<FulfilmentStatus>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryInventoryTransferArgs = {
  id: Scalars['ID'];
};


export type QueryInventoryTransfersArgs = {
  count?: InputMaybe<Scalars['Int']>;
  destinationLocationId?: InputMaybe<Scalars['ID']>;
  fromId?: InputMaybe<Scalars['ID']>;
  status?: InputMaybe<InventoryTransferStatus>;
  userId?: InputMaybe<Scalars['ID']>;
};


export type QueryLabelTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryLabelTemplatesArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryLocateArgs = {
  address: Array<Scalars['String']>;
};


export type QueryPackLogByPackNumberArgs = {
  packNumber: Scalars['String'];
};


export type QueryPackLogsArgs = {
  endDate?: InputMaybe<Scalars['DateTime']>;
  packWorkstationId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['DateTime']>;
  stockCode?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryPickGroupsArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryPrinterArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderLineArgs = {
  id: Scalars['ID'];
};


export type QuerySalesOrderLinesArgs = {
  ids: Array<Scalars['ID']>;
};


export type QuerySalesOrdersArgs = {
  backOrderFilter?: InputMaybe<ListFilter>;
  branchId?: InputMaybe<Scalars['ID']>;
  branchIds?: InputMaybe<Array<InputMaybe<Scalars['ID']>>>;
  createdAfter?: InputMaybe<Scalars['Date']>;
  customerId?: InputMaybe<Scalars['ID']>;
  customerInactive?: InputMaybe<ListFilter>;
  customerOnStopCredit?: InputMaybe<ListFilter>;
  customerReferences?: InputMaybe<Array<Scalars['String']>>;
  descending?: InputMaybe<Scalars['Boolean']>;
  fromId?: InputMaybe<Scalars['ID']>;
  ids?: InputMaybe<Array<Scalars['ID']>>;
  onHoldFilter?: InputMaybe<ListFilter>;
  pickedFilter?: InputMaybe<ListFilter>;
  statuses?: InputMaybe<Array<SalesOrderStatus>>;
  stockLocationId?: InputMaybe<Scalars['ID']>;
  take?: InputMaybe<Scalars['Int']>;
  withLinesInCategory?: InputMaybe<SalesOrderLinePickCategory>;
};


export type QueryShippingProvidersArgs = {
  forFulfilment?: InputMaybe<Scalars['Boolean']>;
};


export type QueryStockItemArgs = {
  stockCode: Scalars['ID'];
};


export type QueryStockItemCurrentBestPricesArgs = {
  input: Array<StockItemBestPriceInput>;
};


export type QueryStockItemsArgs = {
  barcodes?: InputMaybe<Array<Scalars['String']>>;
  skip?: InputMaybe<Scalars['Int']>;
  stockCodes?: InputMaybe<Array<Scalars['ID']>>;
  take?: InputMaybe<Scalars['Int']>;
};


export type QueryStockItemsFuzzySearchArgs = {
  input: StockItemsFuzzySearchInput;
};


export type QueryTreatmentHistoryArgs = {
  dateFrom?: InputMaybe<Scalars['DateTime']>;
  dateTo?: InputMaybe<Scalars['DateTime']>;
  packNo?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  take?: InputMaybe<Scalars['Int']>;
  treatmentPlantId?: InputMaybe<Scalars['ID']>;
  treatmentType?: InputMaybe<Scalars['String']>;
  unitNo?: InputMaybe<Scalars['String']>;
};


export type QueryUserArgs = {
  id: Scalars['ID'];
};


export type QueryUserByEmailArgs = {
  email: Scalars['String'];
};


export type QueryUserByPinArgs = {
  pin: Scalars['String'];
};


export type QueryUsersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
};


export type QueryWipSalesOrderArgs = {
  id: Scalars['ID'];
};


export type QueryWipSalesOrdersArgs = {
  ids?: InputMaybe<Array<Scalars['ID']>>;
  newest?: InputMaybe<Scalars['Boolean']>;
  salesBranchId?: InputMaybe<Scalars['ID']>;
  searchQuery?: InputMaybe<Scalars['String']>;
  skip?: InputMaybe<Scalars['Int']>;
  statuses?: InputMaybe<Array<WipStatus>>;
  take?: InputMaybe<Scalars['Int']>;
};

export type Run = {
  __typename?: 'Run';
  id: Scalars['ID'];
};

export type RunInfeedPack = {
  __typename?: 'RunInfeedPack';
  packId: Scalars['ID'];
};

export type RunOutfeedPack = {
  __typename?: 'RunOutfeedPack';
  packId: Scalars['ID'];
};

/** A segment of the business' sales. */
export type SalesBranch = {
  __typename?: 'SalesBranch';
  id: Scalars['ID'];
  /** Readable name for the `Branch`. */
  name: Scalars['String'];
};

/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrder = {
  __typename?: 'SalesOrder';
  /** Active `Fulfilment` on this order, or null if none. */
  activeFulfilment?: Maybe<Fulfilment>;
  /**
   * Contact email address to use for the order.
   * @deprecated Use `contactEmails`.
   */
  contactEmail?: Maybe<Scalars['String']>;
  /** Contact email addresses to use for the order. */
  contactEmails: Array<ContactEmail>;
  /** The `Debtor` that made the order. */
  customer: Debtor;
  /**
   * Name of the customer. This defaults to the name of the debtor, but can be used
   * when the name of the debtor is not sufficient to name the intended recipient.
   * For example, orders using the ONLINE ORDERS debtor may use this field to
   * specify the buyer's first and last name.
   */
  customerName: Scalars['String'];
  /**
   * The phone number of the customer. Defaults to the debtor's phone number, but
   * can be different when that phone number is not desirable. For example, with
   * an online order where the debtor wouldn't have a good phone number.
   */
  customerPhoneNumber?: Maybe<Scalars['String']>;
  /** Name/identifier assigned by the customer to the order. */
  customerReference: Scalars['String'];
  /**
   * When the `SalesOrder` was created in EXO. In the uncommon situtation that this
   * is null, it uses the `dateOrdered` field instead.
   */
  dateCreated: Scalars['Date'];
  /** The last time the order was modified. */
  dateModified: Scalars['Date'];
  /** The day the order was made. */
  dateOrdered: Scalars['Date'];
  /** Address purchased goods should be delivered to. */
  deliverTo: Array<Scalars['String']>;
  /** List of `Fulfilment`s used to fulfil the order. */
  fulfilments: Array<Fulfilment>;
  /** Whether the order contains back-ordered items. */
  hasBackOrders: Scalars['Boolean'];
  /** Whether the order contains unsupplied items. */
  hasUnsupplied: Scalars['Boolean'];
  /** The ID of the `SalesOrder`, directly from the financial system. */
  id: Scalars['ID'];
  /** Extra notes/instructions on the order. */
  instructions?: Maybe<Scalars['String']>;
  /** Internal information assigned to the order for whatever purpose. */
  internalReference?: Maybe<Scalars['String']>;
  /** Set of `Invoice`s for the `SalesOrder`. */
  invoices: Array<DebtorInvoice>;
  /** Whether the order is considered a back order. */
  isBackOrder: Scalars['Boolean'];
  /** Lines associated with the `SalesOrder`. */
  lines: Array<SalesOrderLine>;
  /** Extra notes on the `SalesOrder`. May be an empty string if there are none. */
  notes: Scalars['String'];
  /** Whether the order has been put on hold. */
  onHold: Scalars['Boolean'];
  /** The `SalesBranch` the order belongs to. */
  salesBranch: SalesBranch;
  /** The provider used/that should be used to ship the order. May be null. */
  shippingProvider?: Maybe<ShippingProvider>;
  /** Status - what phase of the order pipeline it is in. */
  status: SalesOrderStatus;
  /** Whether tax is included??? - seems to have no effect on money values. */
  taxInclusive: Scalars['Boolean'];
  /** Total tax on the order. */
  totalTax: Scalars['Float'];
  /** Total order value, including taxes. */
  totalWithTax: Scalars['Float'];
  /** Total order value, excluding taxes. */
  totalWithoutTax: Scalars['Float'];
  /** The `WipSalesOrder` the order originated from, if any. */
  wipOrder?: Maybe<WipSalesOrder>;
};


/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrderCustomerNameArgs = {
  titleCaseDebtor?: InputMaybe<Scalars['Boolean']>;
};


/** An order from a customer/`Debtor` requesting goods. */
export type SalesOrderLinesArgs = {
  categories?: InputMaybe<Array<SalesOrderLinePickCategory>>;
  hideSpecial?: InputMaybe<Scalars['Boolean']>;
  stockLocationId?: InputMaybe<Scalars['ID']>;
};

export type SalesOrderBackOrderInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
};

export type SalesOrderCreateInput = {
  /** Debtor to which the order should be delivered. */
  customerId: Scalars['ID'];
  /** The customer's name. */
  customerName?: InputMaybe<Scalars['String']>;
  /** The customer's phone number. */
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  /** Name the customer has given the order. */
  customerReference: Scalars['String'];
  /** Date on which the payment is due for the order. */
  datePaymentDue?: InputMaybe<Scalars['Date']>;
  /**
   * Stock location stock is withdrawn from by default to fulfil the `SalesOrder`.
   * Can be override on a per-line basis.
   */
  defaultStockLocationId?: InputMaybe<Scalars['ID']>;
  /** Address to which the order should be delivered. */
  deliveryAddress?: InputMaybe<Array<Scalars['String']>>;
  /** Extra notes/instructions on the order. */
  instructions?: InputMaybe<Scalars['String']>;
  /** The internal reference for the sales order. */
  internalReference?: InputMaybe<Scalars['String']>;
  /** Lines for products ordered. */
  lines: Array<SalesOrderLineInput>;
  /** Optional narrative on the order. */
  narrative?: InputMaybe<Scalars['String']>;
  /** Whether the order will be created as 'On Hold' or not. */
  onHold: Scalars['Boolean'];
  /** `SalesBranch` responsible for this order. */
  salesBranchId: Scalars['ID'];
  /** `ShippingProvider` to use when delivering goods for this order, if any. */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Whether the prices specified are tax inclusive or not. */
  taxInclusive?: InputMaybe<Scalars['Boolean']>;
};

/** A request for a particular good as part of a `SalesOrder`. */
export type SalesOrderLine = {
  __typename?: 'SalesOrderLine';
  /**
   * Description of the line. Often the same as the description of the line's
   * `StockItem`, but may be different.
   */
  description: Scalars['String'];
  /** The ID of the `SalesOrderLine`, directly from the financial system. */
  id: Scalars['ID'];
  /**
   * Whether the line is force closed. A force closed line cannot be supplied or
   * invoiced.
   */
  isForceClosed: Scalars['Boolean'];
  /** The quantity of good that is currently on back order. */
  quantityBackOrdered: Scalars['Float'];
  /** The quantity of good that has been invoiced. */
  quantityInvoiced: Scalars['Float'];
  /** The quantity of good that was ordered. */
  quantityOrdered: Scalars['Float'];
  /** The quantity of good that has been fulfilled/supplied so far. */
  quantitySupplied: Scalars['Float'];
  /** The quantity of good that has not yet been supplied. */
  quantityUnsupplied: Scalars['Float'];
  /** The `SalesOrder` the line belongs to. */
  salesOrder: SalesOrder;
  /** The `StockItem` associated with the line. */
  stockItem: StockItem;
  /** The `StockLocation` inventory should be pulled from when fulfilling. */
  stockLocation: StockLocation;
  /** Total price of the goods on the line. */
  totalPrice: Scalars['Float'];
  /** Price per unit of the goods represented on the line. */
  unitPrice: Scalars['Float'];
};

export type SalesOrderLineInput = {
  /** If null, will use the stock item's description */
  description?: InputMaybe<Scalars['String']>;
  /** The quantity ordered in the unit determined by the stock item. */
  quantityOrdered: Scalars['Float'];
  /** The `StockItem` this line is requesting. */
  stockCode: Scalars['ID'];
  /** Stock location stock is withdrawn from to fulfil the `SalesOrder`. */
  stockLocationId?: InputMaybe<Scalars['ID']>;
  /**
   * Price of the product in the `SalesOrder`. Thus total price is
   * `quantityOrdered * unitPrice`. If null, will use the default price from the
   * debtor and stock item.
   */
  unitPrice?: InputMaybe<Scalars['Float']>;
};

/** Non-exhaustive categorization of `SalesOrderLine`s. */
export enum SalesOrderLinePickCategory {
  /** The line is on backorder. */
  BackOrdered = 'BACK_ORDERED',
  /** The line is fully supplied. */
  FullySupplied = 'FULLY_SUPPLIED',
  /** The line can be picked and isn't on backorder. */
  Ready = 'READY'
}

/** Different possible states for a `SalesOrder`. */
export enum SalesOrderStatus {
  /** The order is complete. */
  FullyProcessed = 'FULLY_PROCESSED',
  /** Nothing on the order has been fulfilled. */
  NotProcessed = 'NOT_PROCESSED',
  /** The order is in a state that doesn't match any of the other categories. */
  Other = 'OTHER',
  /** The order has been fulfilled to some degree. */
  PartiallyProcessed = 'PARTIALLY_PROCESSED',
  /** The order is currently a quote. */
  Quote = 'QUOTE'
}

export type SalesOrderSupplyInput = {
  /**
   * Whether to automatically supply any special lines at the same time, such as
   * freight or discount lines.
   */
  autosupplySpecialLines: Scalars['Boolean'];
  /** Description of the lines be supplied. */
  lines: Array<SalesOrderSupplyLineInput>;
  /** The `SalesOrder` containing the lines that are to be supplied. */
  salesOrderId: Scalars['ID'];
};

export type SalesOrderSupplyLineInput = {
  /**
   * Whether or not to backorder the remaining unsupplied quantity. Note that the
   * `quantity` to supply can be zero, which has the effect of simply backordering
   * the line.
   */
  backorderRemaining: Scalars['Boolean'];
  /**
   * If given, the stock location to change the line to use after fulfilling. This
   * is good for when there is enough inventory somewhere else to fulfil the rest
   * of the line.
   */
  newLocationId?: InputMaybe<Scalars['ID']>;
  /** The quantity to supply, in the unit of the relevant `StockItem`. */
  quantity: Scalars['Float'];
  /** The `SalesOrderLine` to supply, in whole or in part. */
  salesOrderLineId: Scalars['ID'];
};

export type SalesOrderUpdateInternalReferenceInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The new value of the `internalReference` field. Can clear the field with null
   * if desired.
   */
  value?: InputMaybe<Scalars['String']>;
};

export type SalesOrderUpdatePickedByInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The `User` who just picked the order. Can clear the field with null if
   * desired.
   */
  userId?: InputMaybe<Scalars['ID']>;
};

export type SalesOrderUpdateShippingProviderInput = {
  /** The `SalesOrder` to update. */
  salesOrderId: Scalars['ID'];
  /**
   * The `User` who just picked the order. Can clear the field with null if
   * desired.
   */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
};

/** A provider we can use to dispatch goods. */
export type ShippingProvider = {
  __typename?: 'ShippingProvider';
  /**
   * Whether we want to consider the `ShippingProvider` for the standard fulfilment
   * process.
   */
  fulfilmentHide: Scalars['Boolean'];
  id: Scalars['ID'];
  /** Name of the `ShippingProvider`. */
  name: Scalars['String'];
};

export type StockConsumeError = StockConsumeNotFoundError;

export type StockConsumeInput = {
  /** The pack numbers of the packs to consume. */
  packNumbers: Array<Scalars['String']>;
  /** Where the packs are located. */
  stockLocationId: Scalars['ID'];
};

export type StockConsumeNotFoundError = UserError & {
  __typename?: 'StockConsumeNotFoundError';
  message: Scalars['String'];
};

export type StockConsumePayload = {
  __typename?: 'StockConsumePayload';
  userErrors: Array<StockConsumeError>;
};

export type StockCreateError = StockCreateInvalidInputError | StockCreateNotFoundError;

export type StockCreateInput = {
  displayDimensions?: InputMaybe<Scalars['String']>;
  lengthSummary?: InputMaybe<Scalars['String']>;
  packWorkstationId: Scalars['ID'];
  printQuantity: Scalars['Int'];
  printer: Scalars['String'];
  quantity: Scalars['Float'];
  selectedUnitOfMeasure: StockCreateUnitOfMeasure;
  stockCode: Scalars['String'];
  tally?: InputMaybe<Array<TallyInput>>;
  unitNumber?: InputMaybe<Scalars['String']>;
  variableLengthPieces?: InputMaybe<Scalars['Float']>;
};

export type StockCreateInvalidInputError = UserError & {
  __typename?: 'StockCreateInvalidInputError';
  message: Scalars['String'];
};

export type StockCreateNotFoundError = UserError & {
  __typename?: 'StockCreateNotFoundError';
  message: Scalars['String'];
};

export type StockCreatePayload = {
  __typename?: 'StockCreatePayload';
  /** The resulting `PackLog`s, null on failure. */
  packLogs?: Maybe<Array<PackLog>>;
  userErrors: Array<StockCreateError>;
};

export type StockCreateSingleError = StockCreateSingleNoBarcodeError | StockCreateSingleNotFoundError;

export type StockCreateSingleInput = {
  packWorkstationId: Scalars['ID'];
  printer: Scalars['String'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleNoBarcodeError = UserError & {
  __typename?: 'StockCreateSingleNoBarcodeError';
  message: Scalars['String'];
};

export type StockCreateSingleNotFoundError = UserError & {
  __typename?: 'StockCreateSingleNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSinglePayload = {
  __typename?: 'StockCreateSinglePayload';
  userErrors: Array<StockCreateSingleError>;
};

export type StockCreateSingleUndoError = StockCreateSingleUndoNotFoundError;

export type StockCreateSingleUndoInput = {
  packWorkstationId: Scalars['ID'];
  stockCode: Scalars['String'];
};

export type StockCreateSingleUndoNotFoundError = UserError & {
  __typename?: 'StockCreateSingleUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateSingleUndoPayload = {
  __typename?: 'StockCreateSingleUndoPayload';
  userErrors: Array<StockCreateSingleUndoError>;
};

export type StockCreateUndoError = StockCreateUndoNotFoundError;

export type StockCreateUndoInput = {
  /** The pack log to delete or restore. */
  packLogId: Scalars['ID'];
  /** Whether to soft-delete the pack log or restore it. */
  restore?: InputMaybe<Scalars['Boolean']>;
};

export type StockCreateUndoNotFoundError = UserError & {
  __typename?: 'StockCreateUndoNotFoundError';
  message: Scalars['String'];
};

export type StockCreateUndoPayload = {
  __typename?: 'StockCreateUndoPayload';
  /** The resulting `PackLog`, null on failure. */
  packLog?: Maybe<PackLog>;
  userErrors: Array<StockCreateUndoError>;
};

export enum StockCreateUnitOfMeasure {
  Lm = 'LM',
  Pce = 'PCE'
}

/** A category for a `StockItem`. */
export type StockGroup = {
  __typename?: 'StockGroup';
  id: Scalars['ID'];
  /** Name of the group. */
  name: Scalars['String'];
};

/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItem = {
  __typename?: 'StockItem';
  /** Important information to consider when using the `StockItem`, if any. */
  alert?: Maybe<Scalars['String']>;
  /** Barcode/GTIN, or null if not available. */
  barcode?: Maybe<Scalars['String']>;
  /**
   * The value of the product's customer name field in the sales system. Indicates
   * the customer we would typically build this product up in stock for.
   */
  customerName: Scalars['String'];
  /** The last time the `StockItem` record was modified. May return null. */
  dateModified?: Maybe<Scalars['Date']>;
  /** Default location for dispatching the product, if any. */
  defaultStockLocation?: Maybe<StockLocation>;
  /**
   * Any other barcodes that should be recognized as this product, but should not
   * be used by us for the barcode was changed.
   *
   * Ideally we should never change barcodes and this should never be needed, but
   * unfortunately some customers order from us with these barcodes and we need to
   * understand that.
   */
  deprecatedBarcodes: Array<Scalars['String']>;
  /** Sales description. */
  description?: Maybe<Scalars['String']>;
  /**
   * Scaling factor when converting from the order quantity in Get Growing to order
   * quantity in the sales system. For example, if we are selling the product as a
   * 10 pack in Get Growing, this field should be set to 10, so ordering 3 in Get
   * Growing would become 30 in our system.
   */
  getGrowingQuantityMultiplier: Scalars['Float'];
  /** Whether the `StockItem` is actively in use/not retired. */
  isActive: Scalars['Boolean'];
  /** Whether or not the product is considered a 'Variable Length' product. */
  isVariableLengthProduct: Scalars['Boolean'];
  /** Whether or not the product is considered a 'WIP' product. */
  isWipProduct: Scalars['Boolean'];
  /**
   * Weight of the product in kilograms, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  kilograms?: Maybe<Scalars['Float']>;
  /** Length of the product in millimeters, or null if not available. */
  lengthMillimeters?: Maybe<Scalars['Float']>;
  /** Information about a `StockItem` at the given `StockLocation`. */
  locationInfo: StockItemLocationInfo;
  /** Information about a `StockItem` at each `StockLocation`. */
  locationInfos: Array<StockItemLocationInfo>;
  /**
   * Volume of the product in meters cubed, or null if not available.
   *
   * A value of zero in EXO is assumed to be not specified and thus is null here.
   */
  metresCubed?: Maybe<Scalars['Float']>;
  /**
   * The minimum quantity we are willing to sell of the product in an individual
   * order.
   */
  minimumOrderQuantity?: Maybe<Scalars['Float']>;
  /**
   * Name for the `StockItem`, typically shorter and less detailed than
   * description. If no name is available, will default back to `description`
   */
  name: Scalars['String'];
  /** Extra notes on/about the product, if there are any. */
  notes?: Maybe<Scalars['String']>;
  /**
   * The number/quantity of items in a typical pack/bundle. Could be floating-point
   * e.g. units of linear meters.
   */
  quantityMultiplier: Scalars['Float'];
  /**
   * The price at which the product is typically sold at for the given `Debtor`,
   * or null if not available.
   */
  sellPrice?: Maybe<Scalars['Float']>;
  /**
   * The price at which the product is typically sold at for debtors corresponding
   * to the given index (price group).
   */
  sellPriceAtIndex?: Maybe<Scalars['Float']>;
  /** The default shipping destination of a product. Typically NZ, ASIA, or AU */
  shippingDestination?: Maybe<Scalars['String']>;
  /**
   * The pack size of a typical pack of this product, if it is a product that
   * can be made into packs.
   */
  standardPackSize?: Maybe<Scalars['Int']>;
  /** ID of the stock item, often referred to as the 'stock code'. */
  stockCode: Scalars['ID'];
  /** Group the `StockItem` belongs to. */
  stockGroup: StockGroup;
  /** Thickness/height of the product in millimeters, or null if not available. */
  thicknessMillimeters?: Maybe<Scalars['Float']>;
  /** The default treatment type to be applied to the product */
  treatmentType?: Maybe<Scalars['String']>;
  /** Unit usually used to measure the quantity of this product. */
  unitOfMeasure: UnitOfMeasure;
  /** Width of the product in millimeters, or null if not available. */
  widthMillimeters?: Maybe<Scalars['Float']>;
  /** Whether or not the product is wrapped. */
  wrapped: Scalars['Boolean'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemLocationInfoArgs = {
  stockLocationId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceArgs = {
  debtorId: Scalars['ID'];
};


/** An item, typically physical, that is sold for a given price to `Debtor`s. */
export type StockItemSellPriceAtIndexArgs = {
  index: Scalars['Int'];
};

export type StockItemBestPriceInput = {
  /** The `Debtor` that would be purchasing. */
  debtorId: Scalars['ID'];
  /** The amount of the `StockItem` that would be ordered. */
  orderQuantity: Scalars['Float'];
  /** The `StockItem` that would be purchased. */
  stockCode: Scalars['ID'];
  /** Applies tax if required to the returned price. Defaults to GST exclusive. */
  taxType?: InputMaybe<TaxType>;
};

export type StockItemBestPriceResult = {
  __typename?: 'StockItemBestPriceResult';
  /** The determined price, if it could. */
  price?: Maybe<Scalars['Float']>;
};

/** Information about a `StockItem` at a particular `StockLocation`. */
export type StockItemLocationInfo = {
  __typename?: 'StockItemLocationInfo';
  /**
   * The bin code for the `StockItem` at the `StockLocation`.
   *
   * A bin code is a short string code used to help determine the physical location
   * (e.g. which shelf) of a `StockItem` within a larger location.
   *
   * Note that if the `StockItem` does not have any special bin code set for the
   * location, it will use the default, which is set on the `StockItem` itself.
   *
   * If no bin code is found whatsoever, null will be returned.
   */
  binCode?: Maybe<Scalars['String']>;
  /**
   * The current quantity of the `StockItem` available at the `StockLocation` in
   * the appropriate unit, at least as far as the financial system knows.
   */
  freeQuantity: Scalars['Float'];
  /**
   * The current quantity of the `StockItem` present at present in the
   * `StockLocation` in the appropriate unit, at least as far as the financial
   * system knows.
   */
  presentQuantity: Scalars['Float'];
  /** The `StockLocation` the information pertains to. */
  stockLocation: StockLocation;
};

export type StockItemsFuzzySearchInput = {
  description?: InputMaybe<Scalars['String']>;
  take?: InputMaybe<Scalars['Int']>;
};

/** A place from which goods can be stored/taken. */
export type StockLocation = {
  __typename?: 'StockLocation';
  /** Short code for the location. */
  code: Scalars['String'];
  id: Scalars['ID'];
  /** Full name of the location. */
  name: Scalars['String'];
};

/**
 * A measurement of the amount of product.
 * It includes the number of pieces, and the length of these pieces.
 */
export type Tally = {
  __typename?: 'Tally';
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

/** Corresponding input for `Tally` objects. */
export type TallyInput = {
  length: Scalars['Float'];
  pieces: Scalars['Int'];
};

export enum TaxType {
  GstExclusive = 'GST_EXCLUSIVE',
  GstInclusive = 'GST_INCLUSIVE'
}

export type TestResult = {
  __typename?: 'TestResult';
  /**
   * Whether the request was initiated because of another request, or directly.
   * Delegated requests are automatically authorized - scopes are irrelevant.
   */
  delegatedRequest: Scalars['Boolean'];
  /**
   * Whether the request was initiated from an on-premises device. This may be used
   * to determine whether PIN-based authentication is permitted.
   *
   * Currently this is determined based on the IP address of the sender.
   */
  onPremises: Scalars['Boolean'];
  /** Scopes the caller has */
  scopes: Array<Scalars['String']>;
  /**
   * Never returns false. Therefore if you query it and get `true`, then you
   * have successfully connected to the API. Has no specific authorization
   * requirements, other than the minimal requirements for accessing the API
   * at all.
   */
  success: Scalars['Boolean'];
  /** The `User` responsible for the request. */
  user?: Maybe<User>;
};

export enum TextCapitalization {
  Title = 'TITLE',
  Upper = 'UPPER'
}

export type TreatmentHistoryRecord = {
  __typename?: 'TreatmentHistoryRecord';
  dateReceived?: Maybe<Scalars['DateTime']>;
  dateSent: Scalars['DateTime'];
  documentId?: Maybe<Scalars['String']>;
  fromTreatmentInventoryTransferId?: Maybe<Scalars['ID']>;
  fromTreatmentPackId?: Maybe<Scalars['ID']>;
  manualDescription?: Maybe<Scalars['String']>;
  metresCubed: Scalars['Float'];
  packNumber: Scalars['String'];
  quantity: Scalars['Float'];
  returnedToLocationId?: Maybe<Scalars['ID']>;
  sentFromLocationId: Scalars['ID'];
  shippingDestination?: Maybe<Scalars['String']>;
  stockCode?: Maybe<Scalars['String']>;
  stockItem?: Maybe<StockItem>;
  toTreatmentInventoryTransferId: Scalars['ID'];
  toTreatmentPackId: Scalars['ID'];
  treatmentPlant: TreatmentPlant;
  treatmentPlantId: Scalars['ID'];
  treatmentType?: Maybe<Scalars['String']>;
  unitNumber?: Maybe<Scalars['String']>;
  unitText?: Maybe<Scalars['String']>;
  weightKilograms: Scalars['Float'];
};

export type TreatmentHistorySearchResult = {
  __typename?: 'TreatmentHistorySearchResult';
  records: Array<TreatmentHistoryRecord>;
  totalCount: Scalars['Int'];
};

/** Plants that packs can be sent to for treatment along with the treatment types available at that plant. */
export type TreatmentPlant = {
  __typename?: 'TreatmentPlant';
  displayName: Scalars['String'];
  id: Scalars['ID'];
  treatments: Array<Scalars['String']>;
};

/** Data for units for measuring physical objects/goods. */
export type UnitOfMeasure = {
  __typename?: 'UnitOfMeasure';
  /** Name enum for different units. */
  name: UnitOfMeasureName;
  /** Lowercase symbol. */
  symbol: Scalars['String'];
  /** Uppercase symbol. */
  symbolCapitalized: Scalars['String'];
  /** English name. */
  word: Scalars['String'];
  /** English name, but pluralized. */
  wordPlural: Scalars['String'];
};

/** Units for measuring physical objects/goods. */
export enum UnitOfMeasureName {
  /** Board Foot (volume of a 1 by 1 foot board of 1 inch thickness. */
  Bdft = 'BDFT',
  /** Boxes/cartons. */
  Box = 'BOX',
  /** Linear Metres. */
  Lm = 'LM',
  /** Metres Cubed. */
  M3 = 'M3',
  /** Thousand Board Feet. (= 1000 x BDFT) */
  Mbf = 'MBF',
  /** Pieces. */
  Pce = 'PCE',
  /** Unit does not match one of those predefined by the benevolent developers. */
  Unknown = 'UNKNOWN'
}

export type UpdateHandlesGetError = UpdateHandlesGetNotFoundError | UpdateHandlesGetNotModifiableError;

export type UpdateHandlesGetHandleInput = {
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /** ID of the file to update */
  fileId: Scalars['ID'];
  /**
   * The new name for the file. Even when null, this will rename the old file. This
   * does not affect the name of the blob, just the name of the file according to
   * the API.
   */
  filename?: InputMaybe<Scalars['String']>;
};

export type UpdateHandlesGetInput = {
  handles: Array<UpdateHandlesGetHandleInput>;
};

export type UpdateHandlesGetNotFoundError = UserError & {
  __typename?: 'UpdateHandlesGetNotFoundError';
  message: Scalars['String'];
};

export type UpdateHandlesGetNotModifiableError = UserError & {
  __typename?: 'UpdateHandlesGetNotModifiableError';
  message: Scalars['String'];
};

export type UpdateHandlesGetPayload = {
  __typename?: 'UpdateHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UpdateHandlesGetError>;
};

/** A handle to a file in Azure Blob Storage. */
export type UploadHandle = {
  __typename?: 'UploadHandle';
  /** The file record created. */
  file: File;
  /** URL which can be used to upload a file */
  uploadUrl: Scalars['String'];
};

export type UploadHandlesGetError = UploadHandlesGetInvalidInputError;

export type UploadHandlesGetHandleInput = {
  /** The file extension for the blob name, if any. */
  blobExtension?: InputMaybe<Scalars['String']>;
  /**
   * If not set, the blob will be stored internally as
   * "<some-uuid>.<blobExtension>", or if no blob file extension is present, just
   * "<some-uuid>".
   *
   * If set, the blob will be stored as "<prefix> <some-uuid>.<blobExtension>",
   * or if no blob file extension is present, just "<prefix> <some-uuid>"".
   */
  blobNamePrefix?: InputMaybe<Scalars['String']>;
  /**
   * The name of the container to place the blob in. This container does not
   * need to exist to be used - it will be created as required.
   */
  containerName: Scalars['String'];
  /** Content type for the blob, e.g. "application/pdf" */
  contentType: Scalars['String'];
  /**
   * If null, won't ever expire. If set, must be positive. Files will not be
   * deleted until after `expireDays` is met, although the deletion is not
   * immediate.
   */
  expireDays?: InputMaybe<Scalars['Int']>;
  /** Filename for the file according to the API, if any. */
  filename?: InputMaybe<Scalars['String']>;
  /**
   * Permissions that apply to the file. Only enforceable if file operations
   * happen through this api
   */
  permissions?: InputMaybe<FilePermissions>;
};

export type UploadHandlesGetInput = {
  handles: Array<UploadHandlesGetHandleInput>;
};

export type UploadHandlesGetInvalidInputError = UserError & {
  __typename?: 'UploadHandlesGetInvalidInputError';
  message: Scalars['String'];
};

export type UploadHandlesGetPayload = {
  __typename?: 'UploadHandlesGetPayload';
  /** The handles to use for uploading files. */
  handles?: Maybe<Array<UploadHandle>>;
  userErrors: Array<UploadHandlesGetError>;
};

/** A user of MLC applications. */
export type User = {
  __typename?: 'User';
  /** Active `Fulfilment` for the `User`, or null if none. */
  activeFulfilment?: Maybe<Fulfilment>;
  /** The list of apps the user has access to. */
  apps: Array<AppAccess>;
  /**
   * Default `StockLocation` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultFulfillingStockLocation?: Maybe<StockLocation>;
  /**
   * Default `PickGroup` for the `User` when fulfilling `SalesOrder`s.
   * Some `User`s may not have a default - e.g. they don't work in dispatch.
   */
  defaultPickGroup?: Maybe<PickGroup>;
  /**
   * The user's email address, if any are on record. This allows the gateway and
   * services under it to identify a user logged in directly via the gateway, in a
   * way that from a service's perspective behaves identically to being logged in
   * via middleware-clients.
   */
  email?: Maybe<Scalars['String']>;
  /** The user's family (last) name. */
  familyName: Scalars['String'];
  /** The ID of the warehouse staff member in the the financial system. */
  financialWarehouseStaffMemberId?: Maybe<Scalars['ID']>;
  /** The set of branches the user may fulfil from. */
  fulfillingStockLocations: Array<StockLocation>;
  /** The user's given (first) name. */
  givenName: Scalars['String'];
  id: Scalars['ID'];
  /** The set of picking groups the user may fulfil from. */
  pickGroups: Array<PickGroup>;
};

export type UserError = {
  message: Scalars['String'];
};

export type WipFileUploadGetHandleInput = {
  /** The file extension (e.g. 'pdf') to use, or null if no extension desired */
  fileExtension?: InputMaybe<Scalars['String']>;
};

export type WipFileUploadGetHandlePayload = {
  __typename?: 'WipFileUploadGetHandlePayload';
  /** Name of the file that will be uploaded. */
  filename: Scalars['String'];
  /** The ID to use to reference the file when submitting the WIP entity. */
  id: Scalars['ID'];
  /** The URL that can be used to upload the file. Valid for approximately one hour. */
  uploadUrl: Scalars['String'];
};

export type WipFileUploadGetHandlesHandle = {
  __typename?: 'WipFileUploadGetHandlesHandle';
  /** The ID to use to reference the file when submitting the WIP entity. */
  id: Scalars['ID'];
  /** The URL that can be used to upload the file. Valid for approximately one hour. */
  uploadUrl: Scalars['String'];
};

export type WipFileUploadGetHandlesHandleInput = {
  /**
   * The content type of the file. This is used to determine how the file is
   * displayed.
   */
  contentType: Scalars['String'];
  /** The name of the file, including the file extension. */
  filename: Scalars['String'];
};

export type WipFileUploadGetHandlesInput = {
  handles: Array<WipFileUploadGetHandlesHandleInput>;
};

export type WipFileUploadGetHandlesPayload = {
  __typename?: 'WipFileUploadGetHandlesPayload';
  handles: Array<WipFileUploadGetHandlesHandle>;
};

export type WipMutationError = WipOptimisticLockingError;

export type WipOptimisticLockingError = {
  __typename?: 'WipOptimisticLockingError';
  /** Human-readable error message. */
  message: Scalars['String'];
};

/**
 * A piece of data that contributed to the submission of the order, or additional
 * order-related information added after the fact.
 */
export type WipOriginalSource = {
  __typename?: 'WipOriginalSource';
  /** The attached file */
  file: File;
  id: Scalars['ID'];
  /** Whether to show by default. */
  show: Scalars['Boolean'];
};

export type WipOriginalSourceInput = {
  /** Whether to show by default. */
  show: Scalars['Boolean'];
  /** ID of the handle as given by `wipFileUploadGetHandles`. */
  sourceId: Scalars['String'];
};

/** A work-in-progress entity that is hoped to eventually become a `SalesOrder`. */
export type WipSalesOrder = {
  __typename?: 'WipSalesOrder';
  /** If the order will be put on backorder when it is processed. */
  backOrder?: Maybe<Scalars['Boolean']>;
  /**
   * The `Debtor` the order is for. Is null if `customerId` is null or doesn't
   * represent a valid `Debtor`.
   */
  customer?: Maybe<Debtor>;
  /** ID of the `Debtor` the order is for. */
  customerId?: Maybe<Scalars['ID']>;
  /** The customer's name. */
  customerName?: Maybe<Scalars['String']>;
  /** The customer's phone number. */
  customerPhoneNumber?: Maybe<Scalars['String']>;
  /** The customer's name for the order. Is uppercase. */
  customerReference?: Maybe<Scalars['String']>;
  /** Date on which the payment is due for the order. */
  datePaymentDue?: Maybe<Scalars['Date']>;
  /** The date the `WipSalesOrder` was sent/rejected, if at all. */
  dateProcessed?: Maybe<Scalars['Date']>;
  /** The date the `WipSalesOrder` was submitted to the WIP system. */
  dateSubmitted: Scalars['Date'];
  /**
   * The default `StockLocation` lines in the order should draw stock from when
   * supplying. Is null if `defaultStockLocationId` is null or doesn't represent a
   * valid `StockLocation`.
   */
  defaultStockLocation?: Maybe<StockLocation>;
  /**
   * ID of the default `StockLocation` lines in the order draw stock from when
   * supplying.
   */
  defaultStockLocationId?: Maybe<Scalars['ID']>;
  /** Address to which the order should be delivered, or null for customer default. */
  deliveryAddress?: Maybe<Array<Scalars['String']>>;
  /**
   * Overrides the contact email address to use for the order, which would
   * otherwise be the debtor's email.
   */
  emailOverride?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Optional instructions/notes on the order. */
  instructions?: Maybe<Scalars['String']>;
  /** The internal reference for the sales order. */
  internalReference?: Maybe<Scalars['String']>;
  /** Lines for products to order. */
  lines: Array<WipSalesOrderLine>;
  /** Any modifications to the order header information (not lines). */
  modifications: Array<FieldModification>;
  /** Optional narrative on the order. */
  narrative?: Maybe<Scalars['String']>;
  /** Whether the order needs to be backordered. */
  needsBackorder?: Maybe<Scalars['Boolean']>;
  /** Whether the order will be created as 'On Hold' or not */
  onHold: Scalars['Boolean'];
  /**
   * Whether or not the main order data (i.e. pricing, quantity, stock items,
   * descriptions) is allowed to be modified. This is typically determined by
   * the customer and the specific rules around them.
   */
  orderDataFixed: Scalars['Boolean'];
  /** Total monetary value of the order when submitted, in NZD. */
  originalOrderTotal: Scalars['Float'];
  /** Original sources that the order submission resulted from. */
  originalSources: Array<WipOriginalSource>;
  /** The user that processed the order, if processed and a user was recorded. */
  processedBy?: Maybe<User>;
  /**
   * The order the `WipSalesOrder` resulted in. Is not null if and only if `status`
   * is`SENT`.
   */
  resultingOrder?: Maybe<SalesOrder>;
  /**
   * The `SalesBranch` the order should be allocated to. Is null if `salesBranchId`
   * is null or doesn't represent a valid `SalesBranch`.
   */
  salesBranch?: Maybe<SalesBranch>;
  /** ID of the `SalesBranch` the order should be allocated to. */
  salesBranchId?: Maybe<Scalars['ID']>;
  /**
   * The `ShippingProvider` goods on the order should be delivered to, if any. Is
   * null if `shippingProviderId` is null or doesn't represent a valid `ShippingProvider`.
   */
  shippingProvider?: Maybe<ShippingProvider>;
  /** ID of the `ShippingProvider` goods on the order should be delivered by. */
  shippingProviderId?: Maybe<Scalars['ID']>;
  /** Describes where the order came from. */
  source?: Maybe<WipSalesOrderSource>;
  /**
   * Opaque state to use when mutating the order or its lines. Is modified after
   * each mutation. If multiple mutations are to be performed, the state value
   * after the first mutation must be used in the second mutation, and so on.
   */
  state: Scalars['String'];
  /** The current status of the WIP entity. */
  status: WipStatus;
  /** Whether prices in the order are GST inclusive or exclusive. */
  taxType: TaxType;
  /** The type of WIP entity. */
  type: WipType;
};

export type WipSalesOrderAddLineInput = {
  /** Data on the line to add. */
  line: WipSalesOrderAddLineLineInput;
  /** The order to add the line to. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderAddLineLineInput = {
  /**
   * Optional custom description of the good. Will otherwise use the default
   * description from the `StockItem`, or null if not yet known.
   *
   * Must be uppercase.
   */
  description?: InputMaybe<Scalars['String']>;
  /** The quantity of the `StockItem` to order in the `StockItem`'s default unit, or null if not yet known. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** The `StockItem` to order a quantity of, or null if not yet known. */
  stockCode?: InputMaybe<Scalars['ID']>;
  /**
   * The `StockLocation` this line should draw stock from when supplying, or null
   * if not yet known or you want to use the `defaultStockLocationId`.
   */
  stockLocationId?: InputMaybe<Scalars['ID']>;
  /** The expected price per unit of the wood, or null if not yet known. */
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type WipSalesOrderAddLinePayload = {
  __typename?: 'WipSalesOrderAddLinePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The new line, upon success. */
  line?: Maybe<WipSalesOrderLine>;
  /** The affected order. */
  order: WipSalesOrder;
};

export type WipSalesOrderAttachDocumentsInput = {
  /** Documents to add. */
  documents: Array<WipOriginalSourceInput>;
  /** The order to add the line to. */
  orderId: Scalars['ID'];
};

export type WipSalesOrderAttachDocumentsPayload = {
  __typename?: 'WipSalesOrderAttachDocumentsPayload';
  /** The modified order. */
  order: WipSalesOrder;
};

export type WipSalesOrderCreatePayload = {
  __typename?: 'WipSalesOrderCreatePayload';
  /** The order created. */
  order: WipSalesOrder;
};

export type WipSalesOrderDeleteInput = {
  /** ID of the order to attempt to Delete. */
  id: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderDeletePayload = {
  __typename?: 'WipSalesOrderDeletePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order that was attempted to be Deleted. */
  order: WipSalesOrder;
};

/** A request for a particular good as part of a `WipSalesOrder`. */
export type WipSalesOrderLine = {
  __typename?: 'WipSalesOrderLine';
  /**
   * Is `false` if the line was present on the original submission and `true` if
   * added later manually.
   */
  added: Scalars['Boolean'];
  /** The customer's way of describing the ordered `StockItem`, if any. */
  customerDescription?: Maybe<Scalars['String']>;
  /** The customer's way of identifying the stock item, if any. */
  customerStockCode?: Maybe<Scalars['String']>;
  /**
   * Optional custom description of the good. Will otherwise use default
   * description from the `StockItem` when sent. Is uppercase.
   */
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  /** Any modifications made to the line. */
  modifications: Array<FieldModification>;
  /**
   * The total quantity of the product ordered in the unit appropriate for the
   * `StockItem`.
   */
  quantityOrdered?: Maybe<Scalars['Float']>;
  /** Stock code of the `StockItem` associated with the line. */
  stockCode?: Maybe<Scalars['ID']>;
  /**
   * The `StockItem` associated with the line. Is null if `stockCode` is null or
   * doesn't represent a valid `StockItem`.
   */
  stockItem?: Maybe<StockItem>;
  /**
   * The `StockLocation` this line should draw stock from when supplying. Is null
   * if `stockLocationId` is null or doesn't represent a valid `StockLocation`.
   */
  stockLocation?: Maybe<StockLocation>;
  /** ID of the `StockLocation` this line should draw stock from when supplying. */
  stockLocationId?: Maybe<Scalars['ID']>;
  /** Whether the line has been suppressed - such lines won't be sent. */
  suppressed: Scalars['Boolean'];
  /** The expected price per unit of the good. */
  unitPrice?: Maybe<Scalars['Float']>;
};

export type WipSalesOrderProvideBackOrderInput = {
  /** The new backOrder value. */
  backOrder: Scalars['Boolean'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideBackOrderPayload = {
  __typename?: 'WipSalesOrderProvideBackOrderPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideCustomerIdInput = {
  /** The new customerId. */
  customerId?: InputMaybe<Scalars['ID']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideCustomerIdPayload = {
  __typename?: 'WipSalesOrderProvideCustomerIdPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideCustomerNameInput = {
  /** The new `customerName` value. */
  customerName?: InputMaybe<Scalars['String']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideCustomerNamePayload = {
  __typename?: 'WipSalesOrderProvideCustomerNamePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideCustomerPhoneNumberInput = {
  /** The new `CustomerPhoneNumber` value. */
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideCustomerPhoneNumberPayload = {
  __typename?: 'WipSalesOrderProvideCustomerPhoneNumberPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideCustomerReferenceInput = {
  /** The new `customerReference` value. Must be uppercase. */
  customerReference?: InputMaybe<Scalars['String']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideCustomerReferencePayload = {
  __typename?: 'WipSalesOrderProvideCustomerReferencePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideDatePaymentDueInput = {
  /** The new datePaymentDue. */
  datePaymentDue?: InputMaybe<Scalars['Date']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideDatePaymentDuePayload = {
  __typename?: 'WipSalesOrderProvideDatePaymentDuePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideDefaultStockLocationInput = {
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new default `StockLocation` lines on the order should draw stock from. */
  stockLocationId?: InputMaybe<Scalars['ID']>;
};

export type WipSalesOrderProvideDefaultStockLocationPayload = {
  __typename?: 'WipSalesOrderProvideDefaultStockLocationPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideDeliveryAddressInput = {
  /** The new `deliveryAddress` value. Must be uppercase. */
  deliveryAddress?: InputMaybe<Array<Scalars['String']>>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideDeliveryAddressPayload = {
  __typename?: 'WipSalesOrderProvideDeliveryAddressPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideEmailOverrideInput = {
  /** The new emailOverride. */
  emailOverride?: InputMaybe<Scalars['Date']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideEmailOverridePayload = {
  __typename?: 'WipSalesOrderProvideEmailOverridePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideInstructionsInput = {
  /** The new `instructions` value. */
  instructions?: InputMaybe<Scalars['String']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideInstructionsPayload = {
  __typename?: 'WipSalesOrderProvideInstructionsPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideInternalReferenceInput = {
  /** The new internalReference. */
  internalReference?: InputMaybe<Scalars['Date']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideInternalReferencePayload = {
  __typename?: 'WipSalesOrderProvideInternalReferencePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineDescriptionInput = {
  /**
   * The new line description, or null if the `StockItem`'s default description is
   * desired.
   *
   * Must be uppercase.
   */
  description?: InputMaybe<Scalars['String']>;
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideLineDescriptionPayload = {
  __typename?: 'WipSalesOrderProvideLineDescriptionPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineQuantityInput = {
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** The new quantity being ordered. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideLineQuantityPayload = {
  __typename?: 'WipSalesOrderProvideLineQuantityPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineStockCodeInput = {
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new `StockItem` being ordered. */
  stockCode?: InputMaybe<Scalars['String']>;
};

export type WipSalesOrderProvideLineStockCodePayload = {
  __typename?: 'WipSalesOrderProvideLineStockCodePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineStockItemInput = {
  /** The debtor associated with the line. */
  debtorId: Scalars['String'];
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new `StockItem` being ordered. */
  stockCode: Scalars['String'];
};

export type WipSalesOrderProvideLineStockItemPayload = {
  __typename?: 'WipSalesOrderProvideLineStockItemPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineStockLocationInput = {
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new `StockLocation` the line should draw stock from. */
  stockLocationId?: InputMaybe<Scalars['String']>;
};

export type WipSalesOrderProvideLineStockLocationPayload = {
  __typename?: 'WipSalesOrderProvideLineStockLocationPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideLineUnitPriceInput = {
  /** ID of the line to modify. Must be on the order given. */
  lineId: Scalars['ID'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new price expected. */
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type WipSalesOrderProvideLineUnitPricePayload = {
  __typename?: 'WipSalesOrderProvideLineUnitPricePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The line on the order modified. */
  line: WipSalesOrderLine;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideNarrativeInput = {
  /** The new `narrative` value. */
  narrative?: InputMaybe<Scalars['String']>;
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideNarrativePayload = {
  __typename?: 'WipSalesOrderProvideNarrativePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideOnHoldInput = {
  /** The new `OnHold` value. */
  onHold: Scalars['Boolean'];
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideOnHoldPayload = {
  __typename?: 'WipSalesOrderProvideOnHoldPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideSalesBranchInput = {
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** The new `SalesBranch` to allocate the order to. */
  salesBranchId?: InputMaybe<Scalars['ID']>;
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideSalesBranchPayload = {
  __typename?: 'WipSalesOrderProvideSalesBranchPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideShippingProviderInput = {
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /**
   * The `ShippingProvider` that will be used to deliver goods for this order, or
   * null to remove any/specify no provider.
   */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderProvideShippingProviderPayload = {
  __typename?: 'WipSalesOrderProvideShippingProviderPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderProvideTaxTypeInput = {
  /** ID of the order to modify. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /** The new `taxType` value. */
  taxType: TaxType;
};

export type WipSalesOrderProvideTaxTypePayload = {
  __typename?: 'WipSalesOrderProvideTaxTypePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order modified. */
  order: WipSalesOrder;
};

export type WipSalesOrderRejectInput = {
  /** ID of the order to attempt to reject. */
  id: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderRejectPayload = {
  __typename?: 'WipSalesOrderRejectPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order that was attempted to be rejected. */
  order: WipSalesOrder;
};

export type WipSalesOrderRemoveLineInput = {
  /** ID of the line. */
  lineId: Scalars['ID'];
  /** ID of the order with the line. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderRemoveLinePayload = {
  __typename?: 'WipSalesOrderRemoveLinePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The affected order. */
  order: WipSalesOrder;
};

export type WipSalesOrderRestoreLineInput = {
  /** ID of the line. */
  lineId: Scalars['ID'];
  /** ID of the order with the line. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderRestoreLinePayload = {
  __typename?: 'WipSalesOrderRestoreLinePayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The restored line on the order. */
  line: WipSalesOrderLine;
  /** The order with the restored line. */
  order: WipSalesOrder;
};

export type WipSalesOrderSendInput = {
  /** ID of the order to attempt to send. */
  id: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
  /**
   * Will attempt to force an invalid order to be sent. Some orders are invalid
   * because there isn't enough information to create _anything_ useful in the
   * financial system, and others are invalid because a price doesn't match or
   * similar. With `tryForce`, more orders will be sent through, but others can
   * still fail (triggering an internal server error).
   *
   * Minimum requirements:
   * - id of real customer given
   * - id of real sales branch given
   * - non-empty customer reference given
   * - at least one line
   * - for each line:
   *   - stock code that exists
   *   - order quantity > 0
   */
  tryForce?: InputMaybe<Scalars['Boolean']>;
};

export type WipSalesOrderSendPayload = {
  __typename?: 'WipSalesOrderSendPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order that was attempted to be sent. */
  order: WipSalesOrder;
};

export type WipSalesOrderShopifySource = {
  __typename?: 'WipSalesOrderShopifySource';
  shopifyOrderId: Scalars['ID'];
};

export type WipSalesOrderShopifySourceInput = {
  /** E.g. `gid://shopify/Order/123456789`. */
  shopifyOrderId: Scalars['ID'];
};

export type WipSalesOrderSource = WipSalesOrderShopifySource;

export type WipSalesOrderSourceInput = {
  shopify?: InputMaybe<WipSalesOrderShopifySourceInput>;
};

export type WipSalesOrderSubmitInput = {
  /** If the order will be back ordered when processed */
  backOrder: Scalars['Boolean'];
  /** The `Debtor` the order is for. */
  customerId?: InputMaybe<Scalars['ID']>;
  /** The customer's name. */
  customerName?: InputMaybe<Scalars['String']>;
  /** The customer's phone number. */
  customerPhoneNumber?: InputMaybe<Scalars['String']>;
  /**
   * The customer's name for the order, or null if not yet known.
   *
   * Must be uppercase.
   */
  customerReference?: InputMaybe<Scalars['String']>;
  /**
   * The default `StockLocation` lines in the order should draw stock from when
   * supplying.
   */
  defaultStockLocationId?: InputMaybe<Scalars['ID']>;
  /** Optional, address to which the order should be delivered. */
  deliveryAddress?: InputMaybe<Array<Scalars['String']>>;
  /** Email address override for sending order status emails to the customer. */
  emailOverride?: InputMaybe<Scalars['String']>;
  /** Optional, any instructions/notes on the order */
  instructions?: InputMaybe<Scalars['String']>;
  /** The internal reference for the sales order. */
  internalReference?: InputMaybe<Scalars['String']>;
  /** Lines for products to order. */
  lines: Array<WipSalesOrderSubmitLineInput>;
  /** Optional narrative on the order. */
  narrative?: InputMaybe<Scalars['String']>;
  /** Whether the order needs to be back ordered or not. */
  needsBackorder: Scalars['Boolean'];
  /** Whether the order will be created as 'On Hold' or not */
  onHold?: InputMaybe<Scalars['Boolean']>;
  /**
   * Whether or not the main order data (i.e. pricing, quantity, stock items,
   * descriptions) is allowed to be modified.
   */
  orderDataFixed: Scalars['Boolean'];
  originalSources: Array<WipOriginalSourceInput>;
  /**
   * The `SalesBranch` the order should be allocated to, or null if not yet
   * known.
   */
  salesBranchId?: InputMaybe<Scalars['ID']>;
  /**
   * The `ShippingProvider` the order should use for delivery or goods, or null if
   * not yet known or none is needed.
   */
  shippingProviderId?: InputMaybe<Scalars['ID']>;
  /** Describes where the order came from, and any relevant metadata. */
  source?: InputMaybe<WipSalesOrderSourceInput>;
  /**
   * Whether prices in the order are GST inclusive or exclusive. Defaults to
   * GST exclusive pricing.
   */
  taxType?: InputMaybe<TaxType>;
};

export type WipSalesOrderSubmitLineInput = {
  /** The customer's way of describing the ordered `StockItem`, if any. */
  customerDescription?: InputMaybe<Scalars['String']>;
  /** The customer's way of identifying the stock item, if any. */
  customerStockCode?: InputMaybe<Scalars['String']>;
  /**
   * Optional custom description of the good. Will otherwise use the default
   * description from the `StockItem`, or null if not yet known.
   *
   * Must be uppercase.
   */
  description?: InputMaybe<Scalars['String']>;
  /** The quantity of the `StockItem` to order in the `StockItem`'s default unit, or null if not yet known. */
  quantity?: InputMaybe<Scalars['Float']>;
  /** The `StockItem` to order a quantity of, or null if not yet known. */
  stockCode?: InputMaybe<Scalars['ID']>;
  /**
   * The `StockLocation` this line should draw stock from when supplying, or null
   * if not yet known or you want to use the `defaultStockLocationId`.
   */
  stockLocationId?: InputMaybe<Scalars['ID']>;
  /** The expected price per unit of the wood, or null if not yet known. */
  unitPrice?: InputMaybe<Scalars['Float']>;
};

export type WipSalesOrderSubmitPayload = {
  __typename?: 'WipSalesOrderSubmitPayload';
  /** The order submitted. */
  order: WipSalesOrder;
};

export type WipSalesOrderTriggerRulesInput = {
  /** ID of the order to run rules on. */
  orderId: Scalars['ID'];
  /** Value of `state` on the order being modified. */
  state: Scalars['String'];
};

export type WipSalesOrderTriggerRulesPayload = {
  __typename?: 'WipSalesOrderTriggerRulesPayload';
  /** Non-null if an error occurs. */
  error?: Maybe<WipMutationError>;
  /** The order that rules were run on. */
  order: WipSalesOrder;
};

/** The status of a work-in-progress business entity. */
export enum WipStatus {
  /** The entity was deleted. */
  Deleted = 'DELETED',
  /** The entity is not yet considered valid and needs further changes. */
  Invalid = 'INVALID',
  /** The entity was rejected and so was never and will never be sent. */
  Rejected = 'REJECTED',
  /** The entity is considered valid and is ready to be sent. */
  Sendable = 'SENDABLE',
  /** The entity is valid and was sent into the appropriate system. */
  Sent = 'SENT'
}

/** The type of a work-in-progress business entity. */
export enum WipType {
  /**
   * An entity that has been created by some form of automation that has
   * pre-populated it's fields.
   */
  Automated = 'AUTOMATED',
  /**
   * The entity that is created manually and has all fields filled out
   * by the user.
   */
  Manual = 'MANUAL'
}

export type Workstation = {
  __typename?: 'Workstation';
  id: Scalars['ID'];
};

export type PrintPackingSlipMutationVariables = Exact<{
  input: FulfilmentPrintPackingSlipInput;
}>;


export type PrintPackingSlipMutation = { __typename?: 'Mutation', fulfilmentPrintPackingSlip: boolean };

export type PrintLabelsMutationVariables = Exact<{
  input: FulfilmentPrintLabelsInput;
}>;


export type PrintLabelsMutation = { __typename?: 'Mutation', fulfilmentPrintLabels: boolean };

export type AbandonFulfilmentMutationVariables = Exact<{
  input: FulfilmentAbandonInput;
}>;


export type AbandonFulfilmentMutation = { __typename?: 'Mutation', fulfilmentAbandon: boolean };

export type ClearFulfilmentLineMutationVariables = Exact<{
  input: FulfilmentClearLineInput;
}>;


export type ClearFulfilmentLineMutation = { __typename?: 'Mutation', fulfilmentClearLine: boolean };

export type LineClearUpdateFragment = { __typename?: 'FulfilmentLine', quantityFulfilled: number, packs: Array<{ __typename?: 'FulfilmentLinePack', id: string }> };

export type CompleteFulfilmentMutationVariables = Exact<{
  input: FulfilmentCompleteInput;
}>;


export type CompleteFulfilmentMutation = { __typename?: 'Mutation', fulfilmentComplete: boolean };

export type CreateFulfilmentMutationVariables = Exact<{
  input: FulfilmentCreateInput;
}>;


export type CreateFulfilmentMutation = { __typename?: 'Mutation', fulfilmentCreate: string };

export type FinalizeFulfilmentMutationVariables = Exact<{
  input: FulfilmentFinalizeInput;
}>;


export type FinalizeFulfilmentMutation = { __typename?: 'Mutation', fulfilmentFinalize: boolean };

export type FulfilmentSendPendingConsignmentsInGroupMutationVariables = Exact<{
  input: FulfilmentSendPendingConsignmentsInGroupInput;
}>;


export type FulfilmentSendPendingConsignmentsInGroupMutation = { __typename?: 'Mutation', fulfilmentSendPendingConsignmentsInGroup: { __typename?: 'FulfilmentSendPendingConsignmentsInGroupPayload', success: boolean, message: string } };

export type PickLineMutationVariables = Exact<{
  input: FulfilmentPickLineInput;
}>;


export type PickLineMutation = { __typename?: 'Mutation', fulfilmentPickLine: string };

export type PickLineRefetchQueryVariables = Exact<{ [key: string]: never; }>;


export type PickLineRefetchQuery = { __typename?: 'Query', me?: { __typename?: 'User', activeFulfilment?: { __typename?: 'Fulfilment', id: string, lines: Array<{ __typename?: 'FulfilmentLine', id: string, quantityFulfilled: number, salesOrderLine: { __typename?: 'SalesOrderLine', id: string } }> } | null | undefined } | null | undefined };

export type PickPackMutationVariables = Exact<{
  input: FulfilmentPickLinePackInput;
}>;


export type PickPackMutation = { __typename?: 'Mutation', fulfilmentPickLinePack: string };

export type RemovePackMutationVariables = Exact<{
  input: FulfilmentLineRemovePackInput;
}>;


export type RemovePackMutation = { __typename?: 'Mutation', fulfilmentLineRemovePack: boolean };

export type RemovePackUpdateFragment = { __typename?: 'FulfilmentLine', quantityFulfilled: number, packs: Array<{ __typename?: 'FulfilmentLinePack', id: string, quantity: number }> };

export type ActiveFulfilmentQueryVariables = Exact<{ [key: string]: never; }>;


export type ActiveFulfilmentQuery = { __typename?: 'Query', me?: { __typename?: 'User', activeFulfilment?: { __typename?: 'Fulfilment', id: string, status: FulfilmentStatus, salesOrder: { __typename?: 'SalesOrder', id: string } } | null | undefined } | null | undefined };

export type FulfilmentQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type FulfilmentQuery = { __typename?: 'Query', fulfilment?: { __typename?: 'Fulfilment', id: string, status: FulfilmentStatus, dateCreated: any, dateFinalized?: any | null | undefined, invoiceNumber?: string | null | undefined, photos: Array<{ __typename?: 'File', info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } }>, packingSlips: Array<{ __typename?: 'File', id: string, name?: string | null | undefined, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } }>, consignmentGroup?: { __typename?: 'ConsignmentGroup', id: string, dateSent: any, documents: Array<{ __typename?: 'File', id: string, name?: string | null | undefined, info: { __typename: 'DeletedFile' } | { __typename: 'PresentFile', accessUrl: string } }> } | null | undefined, shippingProvider?: { __typename?: 'ShippingProvider', name: string } | null | undefined, salesOrder: { __typename?: 'SalesOrder', id: string, customerName: string, customer: { __typename?: 'Debtor', name: string } }, createdBy: { __typename?: 'User', id: string, givenName: string, familyName: string }, stockLocation: { __typename?: 'StockLocation', name: string } } | null | undefined };

export type FulfilmentsQueryVariables = Exact<{
  take?: InputMaybe<Scalars['Int']>;
  searchQuery?: InputMaybe<Scalars['String']>;
}>;


export type FulfilmentsQuery = { __typename?: 'Query', fulfilments: Array<{ __typename?: 'Fulfilment', id: string, status: FulfilmentStatus, dateCreated: any, invoiceNumber?: string | null | undefined, salesOrder: { __typename?: 'SalesOrder', id: string, customerReference: string, customerName: string, customer: { __typename?: 'Debtor', name: string } }, createdBy: { __typename?: 'User', id: string, givenName: string, familyName: string }, stockLocation: { __typename?: 'StockLocation', name: string } }> };

export type MeQueryVariables = Exact<{ [key: string]: never; }>;


export type MeQuery = { __typename?: 'Query', me?: { __typename?: 'User', id: string, givenName: string, familyName: string, email?: string | null | undefined, pickGroups: Array<{ __typename?: 'PickGroup', id: string, name: string, branches: Array<{ __typename?: 'SalesBranch', id: string }> }>, fulfillingStockLocations: Array<{ __typename?: 'StockLocation', id: string, name: string }> } | null | undefined };

export type SalesOrderQueryVariables = Exact<{
  id: Scalars['ID'];
  stockLocationId: Scalars['ID'];
  hideSpecial: Scalars['Boolean'];
  categories?: InputMaybe<Array<SalesOrderLinePickCategory> | SalesOrderLinePickCategory>;
}>;


export type SalesOrderQuery = { __typename?: 'Query', salesOrder?: { __typename?: 'SalesOrder', id: string, deliverTo: Array<string>, customerName: string, internalReference?: string | null | undefined, customerReference: string, dateOrdered: any, status: SalesOrderStatus, hasBackOrders: boolean, notes: string, onHold: boolean, customer: { __typename?: 'Debtor', name: string }, salesBranch: { __typename?: 'SalesBranch', id: string }, shippingProvider?: { __typename?: 'ShippingProvider', id: string, name: string } | null | undefined, activeFulfilment?: { __typename?: 'Fulfilment', id: string, dateCompleted?: any | null | undefined, lines: Array<{ __typename?: 'FulfilmentLine', id: string, quantityFulfilled: number, packs: Array<{ __typename?: 'FulfilmentLinePack', id: string, quantity: number, packNumber: string, metersCubed: number, kilograms: number }>, salesOrderLine: { __typename?: 'SalesOrderLine', id: string } }>, createdBy: { __typename?: 'User', id: string, givenName: string } } | null | undefined, lines: Array<{ __typename?: 'SalesOrderLine', id: string, isForceClosed: boolean, description: string, quantityOrdered: number, quantitySupplied: number, quantityBackOrdered: number, stockItem: { __typename?: 'StockItem', stockCode: string, barcode?: string | null | undefined, quantityMultiplier: number, kilograms?: number | null | undefined, metresCubed?: number | null | undefined, unitOfMeasure: { __typename?: 'UnitOfMeasure', name: UnitOfMeasureName }, locationInfo: { __typename?: 'StockItemLocationInfo', binCode?: string | null | undefined } } }> } | null | undefined };

export type SalesOrdersQueryVariables = Exact<{
  statuses?: InputMaybe<Array<SalesOrderStatus> | SalesOrderStatus>;
  take: Scalars['Int'];
  branchIds: Array<Scalars['ID']> | Scalars['ID'];
  stockLocationId: Scalars['ID'];
  withLinesInCategory?: InputMaybe<SalesOrderLinePickCategory>;
  descending?: InputMaybe<Scalars['Boolean']>;
  onHoldFilter?: InputMaybe<ListFilter>;
  customerOnStopCredit?: InputMaybe<ListFilter>;
  customerInactive?: InputMaybe<ListFilter>;
  pickedFilter?: InputMaybe<ListFilter>;
}>;


export type SalesOrdersQuery = { __typename?: 'Query', salesOrders: Array<{ __typename?: 'SalesOrder', id: string, customerName: string, internalReference?: string | null | undefined, dateOrdered: any, status: SalesOrderStatus, hasBackOrders: boolean, notes: string, onHold: boolean, customer: { __typename?: 'Debtor', name: string }, activeFulfilment?: { __typename?: 'Fulfilment', id: string, createdBy: { __typename?: 'User', id: string, givenName: string } } | null | undefined }> };

export type ShippingProvidersQueryVariables = Exact<{ [key: string]: never; }>;


export type ShippingProvidersQuery = { __typename?: 'Query', shippingProviders: Array<{ __typename?: 'ShippingProvider', id: string, name: string }> };

export const LineClearUpdateFragmentDoc = gql`
    fragment lineClearUpdate on FulfilmentLine {
  quantityFulfilled
  packs {
    id
  }
}
    `;
export const RemovePackUpdateFragmentDoc = gql`
    fragment removePackUpdate on FulfilmentLine {
  quantityFulfilled
  packs {
    id
    quantity
  }
}
    `;
export const PrintPackingSlipDocument = gql`
    mutation PrintPackingSlip($input: FulfilmentPrintPackingSlipInput!) {
  fulfilmentPrintPackingSlip(input: $input)
}
    `;
export type PrintPackingSlipMutationFn = Apollo.MutationFunction<PrintPackingSlipMutation, PrintPackingSlipMutationVariables>;

/**
 * __usePrintPackingSlipMutation__
 *
 * To run a mutation, you first call `usePrintPackingSlipMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintPackingSlipMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printPackingSlipMutation, { data, loading, error }] = usePrintPackingSlipMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintPackingSlipMutation(baseOptions?: Apollo.MutationHookOptions<PrintPackingSlipMutation, PrintPackingSlipMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintPackingSlipMutation, PrintPackingSlipMutationVariables>(PrintPackingSlipDocument, options);
      }
export type PrintPackingSlipMutationHookResult = ReturnType<typeof usePrintPackingSlipMutation>;
export type PrintPackingSlipMutationResult = Apollo.MutationResult<PrintPackingSlipMutation>;
export type PrintPackingSlipMutationOptions = Apollo.BaseMutationOptions<PrintPackingSlipMutation, PrintPackingSlipMutationVariables>;
export const PrintLabelsDocument = gql`
    mutation PrintLabels($input: FulfilmentPrintLabelsInput!) {
  fulfilmentPrintLabels(input: $input)
}
    `;
export type PrintLabelsMutationFn = Apollo.MutationFunction<PrintLabelsMutation, PrintLabelsMutationVariables>;

/**
 * __usePrintLabelsMutation__
 *
 * To run a mutation, you first call `usePrintLabelsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePrintLabelsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [printLabelsMutation, { data, loading, error }] = usePrintLabelsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePrintLabelsMutation(baseOptions?: Apollo.MutationHookOptions<PrintLabelsMutation, PrintLabelsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PrintLabelsMutation, PrintLabelsMutationVariables>(PrintLabelsDocument, options);
      }
export type PrintLabelsMutationHookResult = ReturnType<typeof usePrintLabelsMutation>;
export type PrintLabelsMutationResult = Apollo.MutationResult<PrintLabelsMutation>;
export type PrintLabelsMutationOptions = Apollo.BaseMutationOptions<PrintLabelsMutation, PrintLabelsMutationVariables>;
export const AbandonFulfilmentDocument = gql`
    mutation AbandonFulfilment($input: FulfilmentAbandonInput!) {
  fulfilmentAbandon(input: $input)
}
    `;
export type AbandonFulfilmentMutationFn = Apollo.MutationFunction<AbandonFulfilmentMutation, AbandonFulfilmentMutationVariables>;

/**
 * __useAbandonFulfilmentMutation__
 *
 * To run a mutation, you first call `useAbandonFulfilmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAbandonFulfilmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [abandonFulfilmentMutation, { data, loading, error }] = useAbandonFulfilmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAbandonFulfilmentMutation(baseOptions?: Apollo.MutationHookOptions<AbandonFulfilmentMutation, AbandonFulfilmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AbandonFulfilmentMutation, AbandonFulfilmentMutationVariables>(AbandonFulfilmentDocument, options);
      }
export type AbandonFulfilmentMutationHookResult = ReturnType<typeof useAbandonFulfilmentMutation>;
export type AbandonFulfilmentMutationResult = Apollo.MutationResult<AbandonFulfilmentMutation>;
export type AbandonFulfilmentMutationOptions = Apollo.BaseMutationOptions<AbandonFulfilmentMutation, AbandonFulfilmentMutationVariables>;
export const ClearFulfilmentLineDocument = gql`
    mutation ClearFulfilmentLine($input: FulfilmentClearLineInput!) {
  fulfilmentClearLine(input: $input)
}
    `;
export type ClearFulfilmentLineMutationFn = Apollo.MutationFunction<ClearFulfilmentLineMutation, ClearFulfilmentLineMutationVariables>;

/**
 * __useClearFulfilmentLineMutation__
 *
 * To run a mutation, you first call `useClearFulfilmentLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useClearFulfilmentLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [clearFulfilmentLineMutation, { data, loading, error }] = useClearFulfilmentLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useClearFulfilmentLineMutation(baseOptions?: Apollo.MutationHookOptions<ClearFulfilmentLineMutation, ClearFulfilmentLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ClearFulfilmentLineMutation, ClearFulfilmentLineMutationVariables>(ClearFulfilmentLineDocument, options);
      }
export type ClearFulfilmentLineMutationHookResult = ReturnType<typeof useClearFulfilmentLineMutation>;
export type ClearFulfilmentLineMutationResult = Apollo.MutationResult<ClearFulfilmentLineMutation>;
export type ClearFulfilmentLineMutationOptions = Apollo.BaseMutationOptions<ClearFulfilmentLineMutation, ClearFulfilmentLineMutationVariables>;
export const CompleteFulfilmentDocument = gql`
    mutation CompleteFulfilment($input: FulfilmentCompleteInput!) {
  fulfilmentComplete(input: $input)
}
    `;
export type CompleteFulfilmentMutationFn = Apollo.MutationFunction<CompleteFulfilmentMutation, CompleteFulfilmentMutationVariables>;

/**
 * __useCompleteFulfilmentMutation__
 *
 * To run a mutation, you first call `useCompleteFulfilmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCompleteFulfilmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [completeFulfilmentMutation, { data, loading, error }] = useCompleteFulfilmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCompleteFulfilmentMutation(baseOptions?: Apollo.MutationHookOptions<CompleteFulfilmentMutation, CompleteFulfilmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CompleteFulfilmentMutation, CompleteFulfilmentMutationVariables>(CompleteFulfilmentDocument, options);
      }
export type CompleteFulfilmentMutationHookResult = ReturnType<typeof useCompleteFulfilmentMutation>;
export type CompleteFulfilmentMutationResult = Apollo.MutationResult<CompleteFulfilmentMutation>;
export type CompleteFulfilmentMutationOptions = Apollo.BaseMutationOptions<CompleteFulfilmentMutation, CompleteFulfilmentMutationVariables>;
export const CreateFulfilmentDocument = gql`
    mutation CreateFulfilment($input: FulfilmentCreateInput!) {
  fulfilmentCreate(input: $input)
}
    `;
export type CreateFulfilmentMutationFn = Apollo.MutationFunction<CreateFulfilmentMutation, CreateFulfilmentMutationVariables>;

/**
 * __useCreateFulfilmentMutation__
 *
 * To run a mutation, you first call `useCreateFulfilmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateFulfilmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createFulfilmentMutation, { data, loading, error }] = useCreateFulfilmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateFulfilmentMutation(baseOptions?: Apollo.MutationHookOptions<CreateFulfilmentMutation, CreateFulfilmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateFulfilmentMutation, CreateFulfilmentMutationVariables>(CreateFulfilmentDocument, options);
      }
export type CreateFulfilmentMutationHookResult = ReturnType<typeof useCreateFulfilmentMutation>;
export type CreateFulfilmentMutationResult = Apollo.MutationResult<CreateFulfilmentMutation>;
export type CreateFulfilmentMutationOptions = Apollo.BaseMutationOptions<CreateFulfilmentMutation, CreateFulfilmentMutationVariables>;
export const FinalizeFulfilmentDocument = gql`
    mutation FinalizeFulfilment($input: FulfilmentFinalizeInput!) {
  fulfilmentFinalize(input: $input)
}
    `;
export type FinalizeFulfilmentMutationFn = Apollo.MutationFunction<FinalizeFulfilmentMutation, FinalizeFulfilmentMutationVariables>;

/**
 * __useFinalizeFulfilmentMutation__
 *
 * To run a mutation, you first call `useFinalizeFulfilmentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFinalizeFulfilmentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [finalizeFulfilmentMutation, { data, loading, error }] = useFinalizeFulfilmentMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFinalizeFulfilmentMutation(baseOptions?: Apollo.MutationHookOptions<FinalizeFulfilmentMutation, FinalizeFulfilmentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FinalizeFulfilmentMutation, FinalizeFulfilmentMutationVariables>(FinalizeFulfilmentDocument, options);
      }
export type FinalizeFulfilmentMutationHookResult = ReturnType<typeof useFinalizeFulfilmentMutation>;
export type FinalizeFulfilmentMutationResult = Apollo.MutationResult<FinalizeFulfilmentMutation>;
export type FinalizeFulfilmentMutationOptions = Apollo.BaseMutationOptions<FinalizeFulfilmentMutation, FinalizeFulfilmentMutationVariables>;
export const FulfilmentSendPendingConsignmentsInGroupDocument = gql`
    mutation FulfilmentSendPendingConsignmentsInGroup($input: FulfilmentSendPendingConsignmentsInGroupInput!) {
  fulfilmentSendPendingConsignmentsInGroup(input: $input) {
    success
    message
  }
}
    `;
export type FulfilmentSendPendingConsignmentsInGroupMutationFn = Apollo.MutationFunction<FulfilmentSendPendingConsignmentsInGroupMutation, FulfilmentSendPendingConsignmentsInGroupMutationVariables>;

/**
 * __useFulfilmentSendPendingConsignmentsInGroupMutation__
 *
 * To run a mutation, you first call `useFulfilmentSendPendingConsignmentsInGroupMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useFulfilmentSendPendingConsignmentsInGroupMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [fulfilmentSendPendingConsignmentsInGroupMutation, { data, loading, error }] = useFulfilmentSendPendingConsignmentsInGroupMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useFulfilmentSendPendingConsignmentsInGroupMutation(baseOptions?: Apollo.MutationHookOptions<FulfilmentSendPendingConsignmentsInGroupMutation, FulfilmentSendPendingConsignmentsInGroupMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<FulfilmentSendPendingConsignmentsInGroupMutation, FulfilmentSendPendingConsignmentsInGroupMutationVariables>(FulfilmentSendPendingConsignmentsInGroupDocument, options);
      }
export type FulfilmentSendPendingConsignmentsInGroupMutationHookResult = ReturnType<typeof useFulfilmentSendPendingConsignmentsInGroupMutation>;
export type FulfilmentSendPendingConsignmentsInGroupMutationResult = Apollo.MutationResult<FulfilmentSendPendingConsignmentsInGroupMutation>;
export type FulfilmentSendPendingConsignmentsInGroupMutationOptions = Apollo.BaseMutationOptions<FulfilmentSendPendingConsignmentsInGroupMutation, FulfilmentSendPendingConsignmentsInGroupMutationVariables>;
export const PickLineDocument = gql`
    mutation PickLine($input: FulfilmentPickLineInput!) {
  fulfilmentPickLine(input: $input)
}
    `;
export type PickLineMutationFn = Apollo.MutationFunction<PickLineMutation, PickLineMutationVariables>;

/**
 * __usePickLineMutation__
 *
 * To run a mutation, you first call `usePickLineMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickLineMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickLineMutation, { data, loading, error }] = usePickLineMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePickLineMutation(baseOptions?: Apollo.MutationHookOptions<PickLineMutation, PickLineMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PickLineMutation, PickLineMutationVariables>(PickLineDocument, options);
      }
export type PickLineMutationHookResult = ReturnType<typeof usePickLineMutation>;
export type PickLineMutationResult = Apollo.MutationResult<PickLineMutation>;
export type PickLineMutationOptions = Apollo.BaseMutationOptions<PickLineMutation, PickLineMutationVariables>;
export const PickLineRefetchDocument = gql`
    query PickLineRefetch {
  me {
    activeFulfilment {
      id
      lines {
        id
        quantityFulfilled
        salesOrderLine {
          id
        }
      }
    }
  }
}
    `;

/**
 * __usePickLineRefetchQuery__
 *
 * To run a query within a React component, call `usePickLineRefetchQuery` and pass it any options that fit your needs.
 * When your component renders, `usePickLineRefetchQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = usePickLineRefetchQuery({
 *   variables: {
 *   },
 * });
 */
export function usePickLineRefetchQuery(baseOptions?: Apollo.QueryHookOptions<PickLineRefetchQuery, PickLineRefetchQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<PickLineRefetchQuery, PickLineRefetchQueryVariables>(PickLineRefetchDocument, options);
      }
export function usePickLineRefetchLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<PickLineRefetchQuery, PickLineRefetchQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<PickLineRefetchQuery, PickLineRefetchQueryVariables>(PickLineRefetchDocument, options);
        }
export type PickLineRefetchQueryHookResult = ReturnType<typeof usePickLineRefetchQuery>;
export type PickLineRefetchLazyQueryHookResult = ReturnType<typeof usePickLineRefetchLazyQuery>;
export type PickLineRefetchQueryResult = Apollo.QueryResult<PickLineRefetchQuery, PickLineRefetchQueryVariables>;
export const PickPackDocument = gql`
    mutation PickPack($input: FulfilmentPickLinePackInput!) {
  fulfilmentPickLinePack(input: $input)
}
    `;
export type PickPackMutationFn = Apollo.MutationFunction<PickPackMutation, PickPackMutationVariables>;

/**
 * __usePickPackMutation__
 *
 * To run a mutation, you first call `usePickPackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `usePickPackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [pickPackMutation, { data, loading, error }] = usePickPackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function usePickPackMutation(baseOptions?: Apollo.MutationHookOptions<PickPackMutation, PickPackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<PickPackMutation, PickPackMutationVariables>(PickPackDocument, options);
      }
export type PickPackMutationHookResult = ReturnType<typeof usePickPackMutation>;
export type PickPackMutationResult = Apollo.MutationResult<PickPackMutation>;
export type PickPackMutationOptions = Apollo.BaseMutationOptions<PickPackMutation, PickPackMutationVariables>;
export const RemovePackDocument = gql`
    mutation RemovePack($input: FulfilmentLineRemovePackInput!) {
  fulfilmentLineRemovePack(input: $input)
}
    `;
export type RemovePackMutationFn = Apollo.MutationFunction<RemovePackMutation, RemovePackMutationVariables>;

/**
 * __useRemovePackMutation__
 *
 * To run a mutation, you first call `useRemovePackMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePackMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePackMutation, { data, loading, error }] = useRemovePackMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRemovePackMutation(baseOptions?: Apollo.MutationHookOptions<RemovePackMutation, RemovePackMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePackMutation, RemovePackMutationVariables>(RemovePackDocument, options);
      }
export type RemovePackMutationHookResult = ReturnType<typeof useRemovePackMutation>;
export type RemovePackMutationResult = Apollo.MutationResult<RemovePackMutation>;
export type RemovePackMutationOptions = Apollo.BaseMutationOptions<RemovePackMutation, RemovePackMutationVariables>;
export const ActiveFulfilmentDocument = gql`
    query ActiveFulfilment {
  me {
    activeFulfilment {
      id
      status
      salesOrder {
        id
      }
    }
  }
}
    `;

/**
 * __useActiveFulfilmentQuery__
 *
 * To run a query within a React component, call `useActiveFulfilmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useActiveFulfilmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useActiveFulfilmentQuery({
 *   variables: {
 *   },
 * });
 */
export function useActiveFulfilmentQuery(baseOptions?: Apollo.QueryHookOptions<ActiveFulfilmentQuery, ActiveFulfilmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ActiveFulfilmentQuery, ActiveFulfilmentQueryVariables>(ActiveFulfilmentDocument, options);
      }
export function useActiveFulfilmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ActiveFulfilmentQuery, ActiveFulfilmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ActiveFulfilmentQuery, ActiveFulfilmentQueryVariables>(ActiveFulfilmentDocument, options);
        }
export type ActiveFulfilmentQueryHookResult = ReturnType<typeof useActiveFulfilmentQuery>;
export type ActiveFulfilmentLazyQueryHookResult = ReturnType<typeof useActiveFulfilmentLazyQuery>;
export type ActiveFulfilmentQueryResult = Apollo.QueryResult<ActiveFulfilmentQuery, ActiveFulfilmentQueryVariables>;
export const FulfilmentDocument = gql`
    query Fulfilment($id: ID!) {
  fulfilment(id: $id, includeAbandoned: true) {
    id
    status
    dateCreated
    dateFinalized
    invoiceNumber
    photos {
      info {
        __typename
        ... on PresentFile {
          accessUrl
        }
      }
    }
    packingSlips {
      id
      name
      info {
        __typename
        ... on PresentFile {
          accessUrl
        }
      }
    }
    consignmentGroup {
      id
      dateSent
      documents {
        id
        name
        info {
          __typename
          ... on PresentFile {
            accessUrl
          }
        }
      }
    }
    shippingProvider {
      name
    }
    salesOrder {
      id
      customerName
      customer {
        name
      }
    }
    createdBy {
      id
      givenName
      familyName
    }
    stockLocation {
      name
    }
  }
}
    `;

/**
 * __useFulfilmentQuery__
 *
 * To run a query within a React component, call `useFulfilmentQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfilmentQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfilmentQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useFulfilmentQuery(baseOptions: Apollo.QueryHookOptions<FulfilmentQuery, FulfilmentQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FulfilmentQuery, FulfilmentQueryVariables>(FulfilmentDocument, options);
      }
export function useFulfilmentLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FulfilmentQuery, FulfilmentQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FulfilmentQuery, FulfilmentQueryVariables>(FulfilmentDocument, options);
        }
export type FulfilmentQueryHookResult = ReturnType<typeof useFulfilmentQuery>;
export type FulfilmentLazyQueryHookResult = ReturnType<typeof useFulfilmentLazyQuery>;
export type FulfilmentQueryResult = Apollo.QueryResult<FulfilmentQuery, FulfilmentQueryVariables>;
export const FulfilmentsDocument = gql`
    query Fulfilments($take: Int, $searchQuery: String) {
  fulfilments(take: $take, searchQuery: $searchQuery, mostRecent: true) {
    id
    status
    dateCreated
    invoiceNumber
    salesOrder {
      id
      customerReference
      customerName
      customer {
        name
      }
    }
    createdBy {
      id
      givenName
      familyName
    }
    stockLocation {
      name
    }
  }
}
    `;

/**
 * __useFulfilmentsQuery__
 *
 * To run a query within a React component, call `useFulfilmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFulfilmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFulfilmentsQuery({
 *   variables: {
 *      take: // value for 'take'
 *      searchQuery: // value for 'searchQuery'
 *   },
 * });
 */
export function useFulfilmentsQuery(baseOptions?: Apollo.QueryHookOptions<FulfilmentsQuery, FulfilmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FulfilmentsQuery, FulfilmentsQueryVariables>(FulfilmentsDocument, options);
      }
export function useFulfilmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FulfilmentsQuery, FulfilmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FulfilmentsQuery, FulfilmentsQueryVariables>(FulfilmentsDocument, options);
        }
export type FulfilmentsQueryHookResult = ReturnType<typeof useFulfilmentsQuery>;
export type FulfilmentsLazyQueryHookResult = ReturnType<typeof useFulfilmentsLazyQuery>;
export type FulfilmentsQueryResult = Apollo.QueryResult<FulfilmentsQuery, FulfilmentsQueryVariables>;
export const MeDocument = gql`
    query Me {
  me {
    id
    givenName
    familyName
    email
    pickGroups {
      id
      name
      branches {
        id
      }
    }
    fulfillingStockLocations {
      id
      name
    }
  }
}
    `;

/**
 * __useMeQuery__
 *
 * To run a query within a React component, call `useMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useMeQuery(baseOptions?: Apollo.QueryHookOptions<MeQuery, MeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<MeQuery, MeQueryVariables>(MeDocument, options);
      }
export function useMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<MeQuery, MeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<MeQuery, MeQueryVariables>(MeDocument, options);
        }
export type MeQueryHookResult = ReturnType<typeof useMeQuery>;
export type MeLazyQueryHookResult = ReturnType<typeof useMeLazyQuery>;
export type MeQueryResult = Apollo.QueryResult<MeQuery, MeQueryVariables>;
export const SalesOrderDocument = gql`
    query SalesOrder($id: ID!, $stockLocationId: ID!, $hideSpecial: Boolean!, $categories: [SalesOrderLinePickCategory!]) {
  salesOrder(id: $id) {
    id
    deliverTo
    customerName
    customer {
      name
    }
    salesBranch {
      id
    }
    shippingProvider {
      id
      name
    }
    internalReference
    customerReference
    dateOrdered
    status
    hasBackOrders
    notes
    onHold
    activeFulfilment {
      id
      dateCompleted
      lines {
        id
        quantityFulfilled
        packs {
          id
          quantity
          packNumber
          metersCubed
          kilograms
        }
        salesOrderLine {
          id
        }
      }
      createdBy {
        id
        givenName
      }
    }
    lines(
      stockLocationId: $stockLocationId
      categories: $categories
      hideSpecial: $hideSpecial
    ) {
      id
      isForceClosed
      description
      quantityOrdered
      quantitySupplied
      quantityBackOrdered
      stockItem {
        stockCode
        barcode
        quantityMultiplier
        kilograms
        metresCubed
        unitOfMeasure {
          name
        }
        locationInfo(stockLocationId: $stockLocationId) {
          binCode
        }
      }
    }
  }
}
    `;

/**
 * __useSalesOrderQuery__
 *
 * To run a query within a React component, call `useSalesOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *      stockLocationId: // value for 'stockLocationId'
 *      hideSpecial: // value for 'hideSpecial'
 *      categories: // value for 'categories'
 *   },
 * });
 */
export function useSalesOrderQuery(baseOptions: Apollo.QueryHookOptions<SalesOrderQuery, SalesOrderQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrderQuery, SalesOrderQueryVariables>(SalesOrderDocument, options);
      }
export function useSalesOrderLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrderQuery, SalesOrderQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrderQuery, SalesOrderQueryVariables>(SalesOrderDocument, options);
        }
export type SalesOrderQueryHookResult = ReturnType<typeof useSalesOrderQuery>;
export type SalesOrderLazyQueryHookResult = ReturnType<typeof useSalesOrderLazyQuery>;
export type SalesOrderQueryResult = Apollo.QueryResult<SalesOrderQuery, SalesOrderQueryVariables>;
export const SalesOrdersDocument = gql`
    query SalesOrders($statuses: [SalesOrderStatus!], $take: Int!, $branchIds: [ID!]!, $stockLocationId: ID!, $withLinesInCategory: SalesOrderLinePickCategory, $descending: Boolean, $onHoldFilter: ListFilter, $customerOnStopCredit: ListFilter, $customerInactive: ListFilter, $pickedFilter: ListFilter) {
  salesOrders(
    statuses: $statuses
    take: $take
    branchIds: $branchIds
    stockLocationId: $stockLocationId
    withLinesInCategory: $withLinesInCategory
    descending: $descending
    onHoldFilter: $onHoldFilter
    customerOnStopCredit: $customerOnStopCredit
    customerInactive: $customerInactive
    pickedFilter: $pickedFilter
  ) {
    id
    customerName
    customer {
      name
    }
    internalReference
    dateOrdered
    status
    hasBackOrders
    notes
    onHold
    activeFulfilment {
      id
      createdBy {
        id
        givenName
      }
    }
  }
}
    `;

/**
 * __useSalesOrdersQuery__
 *
 * To run a query within a React component, call `useSalesOrdersQuery` and pass it any options that fit your needs.
 * When your component renders, `useSalesOrdersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useSalesOrdersQuery({
 *   variables: {
 *      statuses: // value for 'statuses'
 *      take: // value for 'take'
 *      branchIds: // value for 'branchIds'
 *      stockLocationId: // value for 'stockLocationId'
 *      withLinesInCategory: // value for 'withLinesInCategory'
 *      descending: // value for 'descending'
 *      onHoldFilter: // value for 'onHoldFilter'
 *      customerOnStopCredit: // value for 'customerOnStopCredit'
 *      customerInactive: // value for 'customerInactive'
 *      pickedFilter: // value for 'pickedFilter'
 *   },
 * });
 */
export function useSalesOrdersQuery(baseOptions: Apollo.QueryHookOptions<SalesOrdersQuery, SalesOrdersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<SalesOrdersQuery, SalesOrdersQueryVariables>(SalesOrdersDocument, options);
      }
export function useSalesOrdersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<SalesOrdersQuery, SalesOrdersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<SalesOrdersQuery, SalesOrdersQueryVariables>(SalesOrdersDocument, options);
        }
export type SalesOrdersQueryHookResult = ReturnType<typeof useSalesOrdersQuery>;
export type SalesOrdersLazyQueryHookResult = ReturnType<typeof useSalesOrdersLazyQuery>;
export type SalesOrdersQueryResult = Apollo.QueryResult<SalesOrdersQuery, SalesOrdersQueryVariables>;
export const ShippingProvidersDocument = gql`
    query ShippingProviders {
  shippingProviders(forFulfilment: true) {
    id
    name
  }
}
    `;

/**
 * __useShippingProvidersQuery__
 *
 * To run a query within a React component, call `useShippingProvidersQuery` and pass it any options that fit your needs.
 * When your component renders, `useShippingProvidersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useShippingProvidersQuery({
 *   variables: {
 *   },
 * });
 */
export function useShippingProvidersQuery(baseOptions?: Apollo.QueryHookOptions<ShippingProvidersQuery, ShippingProvidersQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ShippingProvidersQuery, ShippingProvidersQueryVariables>(ShippingProvidersDocument, options);
      }
export function useShippingProvidersLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ShippingProvidersQuery, ShippingProvidersQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ShippingProvidersQuery, ShippingProvidersQueryVariables>(ShippingProvidersDocument, options);
        }
export type ShippingProvidersQueryHookResult = ReturnType<typeof useShippingProvidersQuery>;
export type ShippingProvidersLazyQueryHookResult = ReturnType<typeof useShippingProvidersLazyQuery>;
export type ShippingProvidersQueryResult = Apollo.QueryResult<ShippingProvidersQuery, ShippingProvidersQueryVariables>;