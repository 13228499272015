import React, { useState, useEffect } from 'react';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import { useDispatch } from 'react-redux';
import styled from 'styled-components';
import {
  UserActions,
  getCachedPin,
  setCachedPin,
  clearCachedPin,
} from '../state/user';
import { MeQuery, MeDocument, MeQueryVariables } from '../generated/graphql';
import { User } from '../models/User';
import { useApolloClient } from '@apollo/client';
import { releaseProfile } from '../runtime-environment';

enum LoginStatus {
  NotStarted,
  Pending,
  Success,
  Fail,
}

export const LoginPage = () => {
  const [pin, setPin_] = useState('');
  const [loginStatus, setLoginStatus] = useState(LoginStatus.Pending);
  const [errorText, setErrorText] = useState('');

  const setLoginFailure = (error: string) => {
    setPin_('');
    setErrorText(error);
    setLoginStatus(LoginStatus.Fail);
  };

  const dispatch = useDispatch();

  // Try and use the cached pin if there is one to automatically log in
  useEffect(() => {
    const cachedPin = getCachedPin();
    if (cachedPin !== null) startLogin(cachedPin);
    else setLoginStatus(LoginStatus.NotStarted);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const client = useApolloClient();
  const startLogin = (pin: string) => {
    setLoginStatus(LoginStatus.Pending);
    setCachedPin(pin);

    client
      .query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const userData = result.data!.me!;
        const userDataWithPin = { ...userData, pin: pin } as User;
        completeLogin(userDataWithPin);
      })
      .catch(() => {
        // No sentry reporting here because it is most likely due to user
        // error (wrong PIN)
        setLoginFailure('Invalid PIN');
        clearCachedPin();
        setTimeout(() => setLoginStatus(LoginStatus.NotStarted), 1000);
      });
  };

  const completeLogin = (user: User) => {
    setLoginStatus(LoginStatus.Success);
    setTimeout(() => dispatch(UserActions.login(user)), 800);
  };

  const setPin = (value: string) => {
    if (!/^[0-9]*$/.test(value)) return;
    setPin_(value);
    if (value.length === 4) startLogin(value);
  };

  const profile = releaseProfile();
  let state = '';
  if (profile === 'local') state = 'local';
  if (profile === 'staging') state = 'staging';
  if (profile === 'feat-eval') state = 'feature evaluation';

  const content = (() => {
    if (loginStatus === LoginStatus.NotStarted) {
      return (
        <>
          <div style={{ paddingBottom: '4rem' }}>
            <Typography align="center" variant="h2">
              Pickle
            </Typography>
            {state && (
              <Typography
                align="center"
                variant="h6"
                style={{ textTransform: 'uppercase' }}
              >
                {state}
              </Typography>
            )}
          </div>
          <TextField
            id="pin"
            value={pin}
            type="password"
            onBlurCapture={(e) => e.target.focus()}
            onChange={(e) => setPin(e.target.value)}
            placeholder="Enter Your PIN"
            inputProps={{
              pattern: '\\d*',
              style: {
                textAlign: 'center',
              },
            }}
            autoFocus
            variant="standard"
          />
        </>
      );
    }

    if (loginStatus === LoginStatus.Fail) {
      return (
        <Typography align="center" variant="h2" color="error">
          {errorText}
        </Typography>
      );
    }

    if (loginStatus === LoginStatus.Success) {
      return (
        <LoginStyle>
          <span role="img" className="waver" aria-label="wave">
            🖐️
          </span>
        </LoginStyle>
      );
    }
    return null;
  })();

  return (
    <LoginContentStyle style={{ flexDirection: 'column', padding: '2em' }}>
      {content}
    </LoginContentStyle>
  );
};

export default LoginPage;

export const LoginStyle = styled.div`
  .waver {
    font-size: 30pt;
    text-align: center;

    transform-origin: 50% 90%;
    animation-timing-function: ease-in-out;
  }
  animation: waver 0.4s alternate infinite;
  @keyframes waver {
    0% {
      transform: rotate(-25deg);
    }
    100% {
      transform: rotate(30deg);
    }
  }
`;

export const LoginContentStyle = styled.div`
  flex-direction: column;
  padding: 2em;
  display: flex;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;
