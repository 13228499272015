const DateTransformer = {
  parseValue: (time: string) => new Date(time),
  serialize: (time: Date) => time.toISOString(),
};

const transformers = {
  SalesOrder: {
    dateOrdered: DateTransformer,
  },
  Fulfilment: {
    dateCreated: DateTransformer,
    dateCompleted: DateTransformer,
  },
};

export default transformers;
