import { combineReducers } from 'redux';
import user from './user';
import orderView from './orderView';
import shipment from './shipment';

export const rootReducer = combineReducers({
  user,
  orderView,
  shipment,
});

export type AppState = ReturnType<typeof rootReducer>;
