import { gql } from '@apollo/client';
import { useAbandonFulfilmentMutation as useMut } from '../../generated/graphql';

gql`
  mutation AbandonFulfilment($input: FulfilmentAbandonInput!) {
    fulfilmentAbandon(input: $input)
  }
`;

export const useAbandonFulfilmentMutation = (id: string) => {
  return useMut({
    variables: { input: { fulfilmentId: id } },
    refetchQueries: ['SalesOrder'],
    awaitRefetchQueries: true,
  });
};
