import { gql } from '@apollo/client';
import { useClearFulfilmentLineMutation as useMut } from '../../generated/graphql';

gql`
  mutation ClearFulfilmentLine($input: FulfilmentClearLineInput!) {
    fulfilmentClearLine(input: $input)
  }
`;

export const useClearFulfilmentLineMutation = (lineId: string) => {
  return useMut({
    variables: {
      input: { lineId },
    },
    optimisticResponse: {
      __typename: 'Mutation',
      fulfilmentClearLine: true,
    },
    update: (store) => {
      store.writeFragment({
        id: `FulfilmentLine:${lineId}`,
        data: { quantityFulfilled: 0, packs: [] },
        fragment: gql`
          fragment lineClearUpdate on FulfilmentLine {
            quantityFulfilled
            packs {
              id
            }
          }
        `,
      });
    },
  });
};
