import { gql } from '@apollo/client';
import { usePickPackMutation as useMut } from '../../generated/graphql';

gql`
  mutation PickPack($input: FulfilmentPickLinePackInput!) {
    fulfilmentPickLinePack(input: $input)
  }
`;

export const usePickPackMutation = () => {
  return useMut({
    refetchQueries: ['SalesOrder'],
    awaitRefetchQueries: true,
  });
};
