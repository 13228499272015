export enum OrderListType {
  Ready = 0,
  FullyProcessed = 1,
  BackOrdered = 2,
  OnHold,
  Unavailable,
}

export function orderListTypeToDisplayString(value: OrderListType): string {
  if (value === OrderListType.Ready) return 'Ready';
  if (value === OrderListType.FullyProcessed) return 'Fully Processed';
  if (value === OrderListType.BackOrdered) return 'Back Ordered';
  if (value === OrderListType.OnHold) return 'On Hold';
  if (value === OrderListType.Unavailable) return 'Unavailable';
  throw new Error(`Unknown OrderListType value: ${value}`);
}
