import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { CenterPage } from './CenterPage';
import { SalesOrderLine } from '../generated/graphql';

export interface ScanErrorProps {
  orderLine: SalesOrderLine;
  scanned: string;
}

export const ScanError: React.FC<ScanErrorProps> = ({ orderLine, scanned }) => {
  return (
    <CenterPage>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          style={{
            textAlign: 'center',
          }}
        >
          {orderLine.stockItem.locationInfo.binCode}
        </Typography>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          {orderLine.stockItem.stockCode}
          <br />
          {orderLine.description}
        </Typography>
        <Typography
          variant="h2"
          color="error"
          style={{
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          Invalid Data Scanned
        </Typography>
        <div
          style={{
            marginBottom: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography>You Scanned: {scanned}</Typography>
        </div>
      </Container>
    </CenterPage>
  );
};
