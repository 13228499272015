import { gql } from '@apollo/client';
import { useStockLocationId } from '../../state/orderView';
import { useCreateFulfilmentMutation as useMut } from '../../generated/graphql';

gql`
  mutation CreateFulfilment($input: FulfilmentCreateInput!) {
    fulfilmentCreate(input: $input)
  }
`;

export const useCreateFulfilmentMutation = (salesOrderId: string) => {
  const stockLocationId = useStockLocationId();
  return useMut({
    variables: {
      input: { salesOrderId, stockLocationId: stockLocationId! },
    },
    refetchQueries: ['SalesOrder'],
    awaitRefetchQueries: true,
  });
};
