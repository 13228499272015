import React from 'react';
import { SalesOrderLine } from '../generated/graphql';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import styled from 'styled-components';
import { round } from '../utils';
import { Loading } from './Loading';

const DataGridStyle = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 1em;
  margin-bottom: 6em;

  & > * {
    line-height: 40px !important;
  }

  & > h6 {
    text-align: right;
  }

  & > p {
    text-align: left;
  }
`;

export interface PickConfirmationProps {
  orderLine: SalesOrderLine;
  toPick: number;
  cancel: () => void;
  confirm: () => void;
  confirmAndNext: () => void;
  remainingToPick: number;
  loading: boolean;
}

export const PickConfirmation: React.FC<PickConfirmationProps> = ({
  orderLine,
  toPick,
  cancel,
  confirm,
  confirmAndNext,
  remainingToPick,
  loading,
}) =>
  loading ? (
    <Loading fullscreen />
  ) : (
    <Container maxWidth="sm" style={{ marginTop: '5em' }}>
      <Typography
        variant="h2"
        style={{ textAlign: 'center', marginBottom: '8rem' }}
      >
        Pick Confirmation
      </Typography>

      <Typography
        variant="h3"
        style={{
          textAlign: 'center',
        }}
      >
        {orderLine.stockItem.locationInfo.binCode}
      </Typography>
      <Typography
        variant="h4"
        style={{
          textAlign: 'center',
          marginBottom: '3rem',
        }}
      >
        {orderLine.description}
      </Typography>

      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'flex-end',
          marginBottom: '4rem',
        }}
      >
        <Typography variant="h6" style={{ marginRight: 10 }}>
          Are you sure you want to pick
        </Typography>
        <Typography variant="h2">
          {round(toPick / (orderLine.stockItem.quantityMultiplier || 1), 2)}
        </Typography>
        <Typography variant="h6" style={{ marginLeft: 10 }}>
          {orderLine.stockItem.quantityMultiplier !== 1
            ? 'bundles'
            : orderLine.stockItem.unitOfMeasure.name}
          ?
        </Typography>
      </div>

      <DataGridStyle>
        <Typography variant="h6">Ordered</Typography>
        <Typography>{round(orderLine.quantityOrdered, 2)}</Typography>
        <Typography variant="h6">Remaining</Typography>
        <Typography>{round(remainingToPick - toPick, 2)}</Typography>
      </DataGridStyle>

      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <Button size="large" color="error" onClick={cancel}>
          Cancel
        </Button>
        <Button size="large" color="primary" onClick={confirm}>
          Confirm
        </Button>
        <Button
          style={{ marginLeft: '6em' }}
          size="large"
          color="primary"
          onClick={confirmAndNext}
        >
          Confirm and Next
        </Button>
      </div>
    </Container>
  );
