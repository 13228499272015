import React from 'react';

export const CenterPage: React.FC<{ style?: React.CSSProperties }> = ({
  style,
  children,
}) => (
  <div
    style={{
      display: 'flex',
      height: '100vh',
      width: '100vw',
      justifyContent: 'center',
      alignItems: 'center',
      ...style,
    }}
  >
    {children}
  </div>
);
