import React, { useRef, useState } from 'react';
import * as Sentry from '@sentry/react';
import Webcam from 'react-webcam';
import Button from '@mui/material/Button';
import { Typography } from '@mui/material';
import { useHistory } from 'react-router-dom';
import {
  useActiveFulfilmentQuery,
  useFinalizeFulfilmentMutation,
} from '../generated/graphql';
import { Loading } from './Loading';
import Grid from '@mui/material/Grid';
import DeleteIcon from '@mui/icons-material/Delete';
import ButtonBase from '@mui/material/ButtonBase';

export const OrderScreenshotPage = () => {
  const active = useActiveFulfilmentQuery({ fetchPolicy: 'cache-only' });
  const history = useHistory();
  const [finalize, { loading }] = useFinalizeFulfilmentMutation();

  const [viewingPhoto, setViewingPhoto] = useState(false);
  const [photos, setPhotos] = useState<string[]>([]);

  const takePhoto = (photo: string | null) => {
    if (photo == null) {
      window.alert("Couldn't take photo");
      return;
    }

    setPhotos((photos) => [...photos, photo]);
    setViewingPhoto(true);
  };

  const removePhoto = () => {
    setPhotos((photos) => photos.slice(0, photos.length - 1));
    setViewingPhoto(false);
  };

  const fulfilmentId = active.data?.me?.activeFulfilment?.id;
  const submit = () => {
    if (!photos || !fulfilmentId) return;
    finalize({ variables: { input: { fulfilmentId, photos } } })
      .then(() => history.push('/'))
      .catch((error) => {
        Sentry.captureException(error);
        window.alert(
          `Failed to upload photo${
            photos.length === 1 ? '' : 's'
          }. Please try again.`,
        );
      });
  };

  if (active.loading || loading) return <Loading fullscreen />;

  return (
    <Grid
      container
      style={{
        width: '100vw',
        height: '100vh',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {photos.length > 0 && (
        <Grid
          item
          xs={3}
          style={{
            height: '100%',
            overflowY: 'auto',
            direction: 'rtl',
            padding: '1em',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ direction: 'ltr' }}>
            {photos.map((photo, index) => (
              <div key={index} style={{ position: 'relative' }}>
                <img
                  src={photo}
                  alt="The product in all it's wooden glory"
                  style={{ height: '6em', margin: '1em' }}
                />
                <ButtonBase
                  style={{
                    backgroundColor: 'white',
                    borderRadius: '50%',
                    width: 20,
                    height: 20,
                    position: 'absolute',
                    padding: '1.5em',
                    boxShadow: '0 3px 3px #aaa',
                    left: 0,
                    top: 0,
                  }}
                  onClick={() => {
                    if (index === photos.length - 1) {
                      setViewingPhoto(false);
                    }

                    setPhotos((photos) =>
                      photos.filter((_, idx) => idx !== index),
                    );
                  }}
                >
                  <DeleteIcon />
                </ButtonBase>
              </div>
            ))}
          </div>
        </Grid>
      )}
      <Grid
        item
        xs={9}
        style={photos.length > 0 ? { paddingRight: '2em' } : undefined}
      >
        <PhotoTaker
          viewingPhoto={viewingPhoto}
          stopViewingPhoto={() => setViewingPhoto(false)}
          removePhoto={removePhoto}
          takePhoto={takePhoto}
          submit={submit}
          photos={photos}
        />
      </Grid>
    </Grid>
  );
};

interface PhotoTakerProps {
  viewingPhoto: boolean;
  stopViewingPhoto: () => void;
  removePhoto: () => void;
  takePhoto: (photo: string | null) => void;
  submit: () => void;
  photos: string[];
}

const PhotoTaker: React.FC<PhotoTakerProps> = ({
  viewingPhoto,
  stopViewingPhoto,
  removePhoto,
  takePhoto,
  submit,
  photos,
}) => {
  const webcam = useRef<Webcam>(null);
  return (
    <div
      style={{
        height: '100%',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {viewingPhoto ? (
        <>
          <img
            src={photos[photos.length - 1]}
            alt="The product in all it's wooden glory"
            style={{ height: '45vh' }}
          />
          <Typography
            style={{ maxWidth: 300, marginTop: '2em', textAlign: 'center' }}
          >
            Does this photo contain the entire order, and can you read the order
            number on a label?
          </Typography>
          <div>
            <Button color="error" onClick={removePhoto}>
              No, I'll take another
            </Button>
            <Button onClick={stopViewingPhoto}>Yes, continue</Button>
          </div>
        </>
      ) : (
        <>
          <Webcam
            ref={webcam}
            audio={false}
            style={{ height: '45vh' }}
            screenshotFormat="image/jpeg"
            videoConstraints={{
              aspectRatio: 15 / 12,
              facingMode: 'environment',
            }}
          />
          <Typography
            style={{ maxWidth: 400, marginTop: '2em', textAlign: 'center' }}
          >
            Please take a photo of what you are shipping, ensuring that a label
            with the order number is clearly visible.
          </Typography>
          <div>
            <Button
              onClick={submit}
              color={photos.length === 0 ? 'secondary' : 'primary'}
            >
              {photos.length === 0
                ? "I don't want to take photos"
                : "I'm done taking photos"}
            </Button>
            <Button onClick={() => takePhoto(webcam.current!.getScreenshot())}>
              Take Photo
            </Button>
          </div>
        </>
      )}
    </div>
  );
};
