import { gql } from '@apollo/client';

export const FULFILMENT_MOST_RECENT_COUNT = 200;
export const FULFILMENT_SEARCH_COUNT = 20;

gql`
  query Fulfilments($take: Int, $searchQuery: String) {
    fulfilments(take: $take, searchQuery: $searchQuery, mostRecent: true) {
      id
      status
      dateCreated
      invoiceNumber
      salesOrder {
        id
        customerReference
        customerName
        customer {
          name
        }
      }
      createdBy {
        id
        givenName
        familyName
      }
      stockLocation {
        name
      }
    }
  }
`;
