import { env, releaseProfile } from './../../runtime-environment';

export type AuthMode = 'pin' | 'jwt';

export interface ServerConfig {
  authMode: AuthMode;
}

export async function determineServer(): Promise<ServerConfig> {
  const onVpn = releaseProfile() === 'local' || (await accessClientsServer());
  return onVpn ? { authMode: 'pin' } : { authMode: 'jwt' };
}

async function accessClientsServer(): Promise<boolean> {
  const response = await fetch(env.serverUri, {
    method: 'POST',
    headers: {
      accept: '*/*',
      'content-type': 'application/json',
      'apollographql-client-name': 'pickle',
      'apollographql-client-version': env.version || 'local',
    },
    body: JSON.stringify({
      query: 'query OnPremisesCheck{test{onPremises}}',
      operationName: 'OnPremisesCheck',
      variables: {},
    }),
  });
  const { data } = await response.json();
  return data.test.onPremises;
}
