import { Typography } from '@mui/material';

export const OrderCustomer: React.FC<{
  customerName: string;
  debtorName: string;
}> = ({ customerName, debtorName }) => (
  <>
    <Typography variant="h6">{customerName}</Typography>
    {debtorName !== customerName && (
      <Typography variant="subtitle1">{debtorName}</Typography>
    )}
  </>
);
