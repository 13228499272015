import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import {
  OrderListType,
  orderListTypeToDisplayString,
} from '../models/OrderListType';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../state';
import { OrderViewActions } from '../state/orderView';

export const OrderListTypeSelector: React.FC = () => {
  const orderListType = useSelector<AppState, OrderListType>(
    (s) => s.orderView.listType,
  );

  const dispatch = useDispatch();
  const setOrderListType = (v: OrderListType) =>
    dispatch(OrderViewActions.setListType(v));

  const item = (value: OrderListType) => (
    <MenuItem value={value}>{orderListTypeToDisplayString(value)}</MenuItem>
  );

  return (
    <Select
      value={orderListType}
      onChange={(e) => setOrderListType(e.target.value as OrderListType)}
      input={<InputBase id="order-type-input" />}
      variant="standard"
    >
      {item(OrderListType.Ready)}
      {item(OrderListType.FullyProcessed)}
      {item(OrderListType.BackOrdered)}
      {item(OrderListType.OnHold)}
      {item(OrderListType.Unavailable)}
    </Select>
  );
};
