import React from 'react';

import Button from '@mui/material/Button';
import Container from '@mui/material/Container';
import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';

import { OrderListTypeSelector } from './OrderListTypeSelector';
import { BranchSelector } from './BranchSelector';
import { StockLocationSelector } from './StockLocationSelector';
import { UserDisplay } from './UserDisplay';
import { OrderPreviewList } from './OrderPreviewList';
import { AdapterLink } from './AdapterLink';

export const OrdersPage: React.FC = () => {
  return (
    <Container maxWidth="md" style={{ paddingTop: '2rem' }}>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        spacing={2}
        style={{ padding: '0 4em 3em 4em' }}
      >
        <Grid item xs={3} style={{ display: 'flex', flexDirection: 'column' }}>
          <BranchSelector />
          <StockLocationSelector />
        </Grid>
        <Grid item xs={6} style={{ display: 'flex', flexDirection: 'column' }}>
          <Typography variant="h2" style={{ textAlign: 'center' }}>
            Orders
          </Typography>
          <div style={{ display: 'flex', justifyContent: 'center' }}>
            <OrderListTypeSelector />
          </div>
        </Grid>
        <Grid item xs={3}>
          <UserDisplay />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'flex-end',
            }}
          >
            <Button component={AdapterLink} to="/fulfilments">
              View Fulfilments
            </Button>
          </div>
        </Grid>
      </Grid>
      <OrderPreviewList />
    </Container>
  );
};
