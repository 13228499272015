import React, { useState } from 'react';

import { useDispatch } from 'react-redux';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import TextField from '@mui/material/TextField';
import IncrementIcon from '@mui/icons-material/Add';
import DecrementIcon from '@mui/icons-material/Remove';

import { usePickLineMutation } from '../graphql/mutations/usePickOrderLineMutation';
import { CenterPage } from './CenterPage';
import { PickConfirmation } from './PickConfirmation';
import { round } from '../utils';
import { ConfirmationDialog } from './ConfirmationDialog';
import { Switch } from '@mui/material';
import styled from 'styled-components';

import { ShipmentActions } from '../state/shipment';
import { SalesOrderLine, FulfilmentLine } from '../generated/graphql';

export interface PickProps {
  orderLine: SalesOrderLine;
  back: () => void;
  next: () => void;
  fulfilmentId: string;
  fulfilmentLine: FulfilmentLine | null;
  salesOrderId: string;
}

export const Pick: React.FC<PickProps> = ({
  orderLine,
  back,
  next,
  fulfilmentId,
  fulfilmentLine,
}) => {
  const [dialogState, setDialogState] = useState(false);
  const dispatch = useDispatch();
  const [checked, setChecked] = useState(false);
  const remainingToPick = getRemainingToPick(orderLine, fulfilmentLine);

  const [bundleCountText, setBundleCountText] = useState('');
  const bundleCount = parseFloat(bundleCountText) || 0;

  const setBundleCount = (value: string) => {
    if (value.match(/^[0-9\\.]*$/)) setBundleCountText(value);
  };

  const setBundleCountFromUOM = (value: string) => {
    if (value === '') setBundleCountText(value);

    setBundleCount(
      (parseFloat(value) / orderLine.stockItem.quantityMultiplier).toString(),
    );
  };

  const handleChange = () => (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const pickQuantity =
    bundleCountText === ''
      ? ''
      : bundleCount * orderLine.stockItem.quantityMultiplier;
  const maximumBundleCount =
    remainingToPick / orderLine.stockItem.quantityMultiplier;

  const canSupply = bundleCount > 0 && bundleCount <= maximumBundleCount;

  const changeBundleCount = (amount: number) => {
    let newValue = bundleCount + amount;
    if (newValue > maximumBundleCount) newValue = maximumBundleCount;
    if (newValue < 0) newValue = 0;
    setBundleCountText(newValue.toString());
  };

  const [pickOrderLine, { loading }] = usePickLineMutation(
    fulfilmentId,
    orderLine.id,
    pickQuantity === '' ? 0 : pickQuantity,
  );

  const [success, setSuccess] = useState(false);

  const [confirmingPick, setConfirmingPick] = useState(false);
  const confirmPick = (confirmAndNext: boolean) => {
    pickOrderLine().then((result) => {
      if (!result.data || !result.data.fulfilmentPickLine) {
        alert('Failed to pick line. Try again later.');
        return;
      }

      if (checked) {
        dispatch(
          ShipmentActions.changeLineShipItem(result.data.fulfilmentPickLine, 1),
        );
        setChecked(false);
      }

      setSuccess(!!result.data?.fulfilmentPickLine);
      setTimeout(() => {
        setSuccess(false);
        confirmAndNext ? next() : back();
      }, 1000);
    });
  };

  if (confirmingPick) {
    return success ? (
      <CenterPage>
        <Typography align="center">Picked line successfully</Typography>
      </CenterPage>
    ) : (
      <PickConfirmation
        loading={loading}
        orderLine={orderLine}
        toPick={pickQuantity === '' ? 0 : pickQuantity}
        confirm={() => {
          confirmPick(false);
        }}
        confirmAndNext={() => {
          confirmPick(true);
        }}
        cancel={() => setConfirmingPick(false)}
        remainingToPick={remainingToPick}
      />
    );
  }

  return (
    <CenterPage>
      <Container maxWidth="sm">
        {orderLine.stockItem.locationInfo.binCode !== '' && (
          <Typography
            variant="h1"
            style={{
              textAlign: 'center',
            }}
          >
            {orderLine.stockItem.locationInfo.binCode}
          </Typography>
        )}
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            marginBottom: '2rem',
          }}
        >
          {orderLine.stockItem.stockCode}
          <br />
          {orderLine.description}
        </Typography>
        <RemainingToPick
          remaining={remainingToPick}
          quantityMultiplier={orderLine.stockItem.quantityMultiplier}
          unit={orderLine.stockItem.unitOfMeasure.name}
        />
        <Typography variant="h6" align="center">
          Pick Now (
          {orderLine.stockItem.quantityMultiplier === 1
            ? orderLine.stockItem.unitOfMeasure.name
            : 'Bundles'}
          )
        </Typography>
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div style={{ position: 'relative' }}>
            <TextField
              id="bundle-count"
              style={{ margin: 0 }}
              variant="outlined"
              value={bundleCountText}
              onChange={(event) => setBundleCount(event.target.value)}
            />
            <IconButton
              color="primary"
              aria-label="add bundle"
              onClick={() => changeBundleCount(1)}
              disabled={bundleCount >= maximumBundleCount}
              size="large"
            >
              <IncrementIcon style={{ width: '2em', height: '2em' }} />
            </IconButton>
            <IconButton
              color="primary"
              aria-label="remove bundle"
              onClick={() => changeBundleCount(-1)}
              disabled={bundleCount === 0}
              size="large"
            >
              <DecrementIcon style={{ width: '2em', height: '2em' }} />
            </IconButton>
            <div
              style={{
                bottom: '1em',
                right: '0.5em',
                display: 'flex',
              }}
            >
              <TextField
                id="uom-count"
                style={{ margin: 0 }}
                variant="outlined"
                value={pickQuantity === '' ? '' : round(pickQuantity, 2)}
                onChange={(event) => setBundleCountFromUOM(event.target.value)}
              />
              <Typography
                color="textSecondary"
                variant="h6"
                style={{
                  display: 'inline-block',
                  marginLeft: '1em',
                  marginTop: '.5em',
                }}
              >
                {orderLine.stockItem.unitOfMeasure.name}
              </Typography>
            </div>
          </div>
        </div>

        <span style={{ textAlign: 'center' }}>
          <p style={{ margin: '0', marginTop: '1em' }}>
            Toggle to Add a Shipment Item.
          </p>
        </span>
        <SwitchStyle>
          <Switch checked={checked} onChange={handleChange()} color="primary" />
        </SwitchStyle>

        <Grid
          container
          justifyContent="space-around"
          style={{ marginTop: '4rem' }}
        >
          <Grid item>
            <Button size="large" color="error" onClick={back}>
              Back
            </Button>
            <Button
              size="large"
              color="primary"
              disabled={bundleCount === 0}
              onClick={() => {
                canSupply ? setConfirmingPick(true) : setDialogState(true);
              }}
            >
              Pick
            </Button>
            <Button
              size="large"
              style={{ marginLeft: '6em' }}
              color="primary"
              aria-label="pick all"
              onClick={() => {
                setBundleCountText(maximumBundleCount.toString());
                setConfirmingPick(true);
              }}
            >
              Pick All
            </Button>

            <ConfirmationDialog
              open={dialogState === true}
              close={() => setDialogState(false)}
              handleOk={() => {
                setConfirmingPick(true);
              }}
              dialogText={`Are you sure that you would like to oversupply this order?`}
            />
          </Grid>
        </Grid>
      </Container>
    </CenterPage>
  );
};

const SwitchStyle = styled.div`
  .MuiSwitch-root {
    display: flex;
    margin: auto auto;
    transform: scale(2.5);
    -webkit-transform-origin-y: -5px;
  }
`;

export interface RemainingToPickProps {
  remaining: number;
  quantityMultiplier: number;
  unit: string;
}

export const RemainingToPick: React.FC<RemainingToPickProps> = ({
  remaining,
  quantityMultiplier,
  unit,
}) => {
  const maximumBundleCount = remaining / quantityMultiplier;
  const bundlesRemaining = round(maximumBundleCount, 2);
  return (
    <>
      <Typography variant="h6" align="center">
        Remaining
      </Typography>
      <Grid
        container
        alignItems="center"
        direction="column"
        style={{ marginBottom: '3em' }}
      >
        <Grid item>
          <Typography
            variant="h2"
            style={{ display: 'inline-block', marginRight: '0.2em' }}
          >
            {quantityMultiplier === 1 ? remaining : bundlesRemaining}
          </Typography>
          <Typography
            color="textSecondary"
            variant="h6"
            style={{ display: 'inline-block' }}
          >
            {quantityMultiplier === 1
              ? unit
              : bundlesRemaining === 1
              ? 'Bundle'
              : 'Bundles'}
          </Typography>
        </Grid>
        {quantityMultiplier !== 1 && (
          <Grid item>
            <Typography
              variant="h6"
              style={{ display: 'inline-block', marginRight: '0.2em' }}
            >
              {round(remaining, 2)}
            </Typography>
            <Typography
              color="textSecondary"
              variant="h6"
              style={{ display: 'inline-block' }}
            >
              {unit}
            </Typography>
          </Grid>
        )}
      </Grid>
    </>
  );
};

export function getRemainingToPick(
  orderLine: SalesOrderLine,
  fulfilmentLine: FulfilmentLine | null,
) {
  return (
    orderLine.quantityOrdered -
    orderLine.quantitySupplied -
    (fulfilmentLine ? fulfilmentLine.quantityFulfilled : 0)
  );
}
