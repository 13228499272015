import React from 'react';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import { useDispatch } from 'react-redux';
import { UserActions, useCurrentUser } from '../state/user';

export const UserDisplay: React.FC = () => {
  const user = useCurrentUser();

  const dispatch = useDispatch();
  const logout = () => dispatch(UserActions.logout());

  if (user === null) return <em>Not logged in</em>;

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-end',
      }}
    >
      <Typography color="textSecondary">
        {user.givenName} {user.familyName}
      </Typography>
      <Button onClick={logout} color="error">
        Logout
      </Button>
    </div>
  );
};
