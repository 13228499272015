import React from 'react';
import styled from 'styled-components';

import Typography from '@mui/material/Typography';
import ButtonBase from '@mui/material/ButtonBase';
import DeleteIcon from '@mui/icons-material/Delete';

import { PackLabelData } from './Scan';
import { Loading } from './Loading';
import { Error } from './Error';

export interface PackScanNotificationsProps {
  notifications: PackScanNotification[];
  deletePack: (id: string) => void;
}

export interface PackScanNotification {
  id: string;
  packId: string | null;
  state: 'pending' | 'complete' | { error: string };
  data: PackLabelData;
}

export const PackScanNotifications: React.FC<PackScanNotificationsProps> = ({
  notifications,
  deletePack,
}) => {
  return (
    <Styles>
      {[...notifications].reverse().map(({ id, packId, state, data }) => {
        let color;
        if (state === 'pending') color = '#ccc';
        else if (state === 'complete') color = '#383';
        else color = '#f44336';

        return (
          <div
            key={id}
            style={{ width: '100%', marginBottom: '3em', position: 'relative' }}
          >
            <Typography
              variant="h5"
              style={{
                color,
                textAlign: 'center',
              }}
            >
              {data.packNumber}
            </Typography>
            <Typography
              variant="h6"
              style={{
                color,
                textAlign: 'center',
                fontSize: '0.9rem',
              }}
            >
              {data.kilograms} KG, {data.metersCubed} M3
            </Typography>
            <Typography
              variant="h6"
              style={{
                color,
                textAlign: 'center',
                paddingBottom: '0.5rem',
                fontSize: '0.9rem',
              }}
            >
              QTY: {data.quantity}
            </Typography>
            {state === 'pending' && <Loading />}
            {typeof state === 'object' && <Error errorText={state.error} />}
            {packId && (
              <ButtonBase
                onClick={() => deletePack(packId)}
                style={{
                  backgroundColor: 'white',
                  borderRadius: '50%',
                  width: 20,
                  height: 20,
                  position: 'absolute',
                  padding: '1.5em',
                  boxShadow: '0 3px 3px #aaa',
                  right: -15,
                  top: 0,
                }}
              >
                <DeleteIcon />
              </ButtonBase>
            )}
          </div>
        );
      })}
    </Styles>
  );
};

const Styles = styled.div`
  position: fixed;
  height: 100vh;
  overflow-y: auto;
  width: 200px;
  right: 0;
  top: 0;
  padding: 3em 3em 3em 0;
`;
