import { CircularProgress, Typography } from '@mui/material';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import CloseIcon from '@mui/icons-material/Close';
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import React, { useState } from 'react';
import * as Sentry from '@sentry/react';
import styled from 'styled-components';
import {
  FulfilmentStatus,
  useFulfilmentQuery,
  usePrintLabelsMutation,
  usePrintPackingSlipMutation,
} from '../generated/graphql';
import { Error } from './Error';
import { Loading } from './Loading';
import dateFormat from 'dateformat';
import { OrderCustomer } from './OrderCustomer';
import { ConsignmentGroup } from './ConsignmentGroup';
import { PBTTrackingButton } from "./PBTTrackingButton";

export const FulfilmentModal: React.FC<{
  fulfilmentId: string;
  onClose: () => void;
}> = ({ fulfilmentId, onClose }) => {
  const { loading, error, data } = useFulfilmentQuery({
    variables: { id: fulfilmentId },
  });

  const [printPackingSlip] = usePrintPackingSlipMutation({
    variables: { input: { fulfilmentId } },
  });

  const [printLabels] = usePrintLabelsMutation();

  const printInfoLabels = () => {
    return printLabels({
      variables: {
        input: {
          fulfilmentId,
          itemLabels: true,
          consignmentLabels: false,
        },
      },
    });
  };

  const printConsignmentLabels = () => {
    return printLabels({
      variables: {
        input: {
          fulfilmentId,
          itemLabels: false,
          consignmentLabels: true,
        },
      },
    });
  };

  return (
    <Modal open={true} onClose={onClose}>
      <ModalStyle
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
        }}
      >
        <IconButton
          onClick={onClose}
          style={{ position: 'absolute', top: '1em', right: '1em' }}
          size="large"
        >
          <CloseIcon />
        </IconButton>
        {(() => {
          if (error || (!loading && !data)) return <Error />;
          if (loading) return <CircularProgress />;
          if (!data?.fulfilment) {
            return <Error errorText="Fulfilment doesn't exist?!" />;
          }
          if (data.fulfilment.status === FulfilmentStatus.Abandoned) {
            return <Error errorText="This fulfilment was abandoned" />;
          }

          const f = data.fulfilment;

          const photoUrls = f.photos.flatMap((photo) => {
            if (photo.info.__typename === 'PresentFile') {
              return [photo.info.accessUrl];
            }
            return [];
          });

          const documents = f.packingSlips.flatMap((doc) => {
            if (doc.info.__typename === 'PresentFile') {
              return [
                {
                  id: doc.id,
                  name: doc.name,
                  url: doc.info.accessUrl,
                },
              ];
            }
            return [];
          });

          return (
            <>
              <div>
                <Typography variant="h5">
                  Fulfilment #{f.id} for Order #{f.salesOrder.id}
                  {f.invoiceNumber ? `, Invoice #${f.invoiceNumber}` : ''}
                </Typography>
                <OrderCustomer
                  customerName={f.salesOrder.customerName}
                  debtorName={f.salesOrder.customer.name}
                />
                <Typography variant="h6">{f.shippingProvider?.name}</Typography>
                <Typography color="textSecondary" variant="subtitle1">
                  Created on{' '}
                  {dateFormat(f.dateCreated, 'yyyy/mm/dd h:MM:ss tt')}
                </Typography>
                {f.dateFinalized && (
                  <Typography color="textSecondary" variant="subtitle1">
                    Finalized on{' '}
                    {dateFormat(f.dateFinalized, 'yyyy/mm/dd h:MM:ss tt')}
                  </Typography>
                )}
                <Typography color="textSecondary" variant="subtitle2">
                  Picked by {f.createdBy.givenName} {f.createdBy.familyName} in{' '}
                  {f.stockLocation.name
                    .split('.')[1]
                    ?.trim()
                    ?.replace(' Stock', '') ?? f.stockLocation.name}
                </Typography>

                {documents.map((document) => (
                  <Button
                    key={document.id}
                    component="a"
                    href={document.url}
                    endIcon={<OpenInNewIcon />}
                    target="_blank" // Open in new tab
                    style={{ margin: '1em 1em 0 0' }}
                  >
                    {document.name}
                  </Button>
                ))}
              </div>

              {f.consignmentGroup && (
                <ConsignmentGroup
                  providerName={f.shippingProvider?.name ?? 'Unknown'}
                  group={f.consignmentGroup}
                />
              )}

              <Typography variant="h6" sx={{ marginTop: '1em' }}>
                Photos
              </Typography>
              {photoUrls.length > 1 ? (
                <div style={{ overflowY: 'auto', marginBottom: '4em' }}>
                  {photoUrls.map((url, index) => (
                    <div key={index} style={{ marginTop: '2em' }}>
                      <img
                        src={url}
                        alt="fulfilment"
                        style={{ maxWidth: '100%' }}
                      />
                    </div>
                  ))}
                </div>
              ) : (
                <div
                  style={{
                    marginTop: '2em',
                    position: 'relative',
                    flexGrow: 1,
                    height: 1,
                  }}
                >
                  <img
                    src={photoUrls[0] ?? '//picsum.photos/800/800'}
                    alt="fulfilment"
                    style={{ maxWidth: '100%', maxHeight: '100%' }}
                  />
                  {photoUrls.length === 0 && (
                    <Typography
                      variant="h5"
                      style={{
                        color: '#fff',
                        position: 'absolute',
                        left: '2em',
                        bottom: '2em',
                      }}
                    >
                      <em>No photos, so here's something else</em>
                    </Typography>
                  )}
                </div>
              )}

              <div
                style={{ position: 'absolute', right: '1em', bottom: '1em', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}
              >
                {f.shippingProvider?.name === 'P.B.T' && f.stockLocation.name !== 'Christchurch' && f.stockLocation.name !== null && (
                 <PBTTrackingButton
                   fulfilmentId={f.id}
                   fulfilmentStockLocation={f.stockLocation.name}
                 />
                )}

                <PrintButton
                  mutate={printConsignmentLabels}
                  style={{ marginRight: '1em' }}
                >
                  Print Freight Labels
                </PrintButton>
                <PrintButton
                  mutate={printInfoLabels}
                  style={{ marginRight: '1em' }}
                >
                  Print Info Labels
                </PrintButton>
                <PrintButton mutate={printPackingSlip}>
                  Print Packing Slip
                </PrintButton>
              </div>
            </>
          );
        })()}
      </ModalStyle>
    </Modal>
  );
};

const ModalStyle = styled(Paper)`
  left: 10vw;
  top: 10vh;
  width: 80vw;
  height: 80vh;
  position: fixed;
  outline: none;
  padding: 2em;
`;

interface ButtonProps {
  isExternal?: boolean;
  disabled?: boolean;
  variant: 'contained';
}

export const ButtonStyle = styled(Button)<ButtonProps>`
  box-shadow: none;
  background-color: #e9e9e9;
  position: relative;
  height: 4em;

  .loading {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
`;

interface PrintButtonProps {
  mutate: () => Promise<any>;
  style?: React.CSSProperties;
}

const PrintButton: React.FC<PrintButtonProps> = ({
  children,
  mutate,
  style,
}) => {
  const [loading, setLoading] = useState(false);
  const onClick = () => {
    setLoading(true);
    mutate()
      .catch((error) => {
        Sentry.captureException(error);
        alert('Failed to print');
      })
      .finally(() => setLoading(false));
  };

  return (
    <>
      <ButtonStyle
        onClick={onClick}
        disabled={loading}
        variant="contained"
        style={style}
      >
        {children}
        {loading && <Loading className="loading" />}
      </ButtonStyle>
    </>
  );
};
