import { gql, useApolloClient } from '@apollo/client';
import { useRemovePackMutation as useMut } from '../../generated/graphql';
import { useCallback } from 'react';
import * as Sentry from '@sentry/react';

gql`
  mutation RemovePack($input: FulfilmentLineRemovePackInput!) {
    fulfilmentLineRemovePack(input: $input)
  }
`;

export const useRemovePackMutation = () => {
  const client = useApolloClient();
  const [mutate, rest] = useMut();

  const myMutate = useCallback(
    (data: {
      packId: string;
      fulfilmentLineId: string;
      onSuccess: () => void;
    }) => {
      mutate({
        variables: {
          input: {
            packId: data.packId,
          },
        },
      })
        .then(() => {
          data.onSuccess();

          // Cannot do the updating in the `update` method of the mutation hook as
          // it doesn't provide us with the variables we need.
          const fragment = gql`
            fragment removePackUpdate on FulfilmentLine {
              quantityFulfilled
              packs {
                id
                quantity
              }
            }
          `;

          const result = client.readFragment({
            id: `FulfilmentLine:${data.fulfilmentLineId}`,
            fragment,
          });

          if (!result) return;

          const pack = result.packs.find((x: any) => x.id === data.packId);
          client.writeFragment({
            id: `FulfilmentLine:${data.fulfilmentLineId}`,
            fragment,
            data: {
              quantityFulfilled: result.quantityFulfilled - pack.quantity,
              packs: result.packs.filter((x: any) => x.id !== data.packId),
            },
          });
        })
        .catch((e) => {
          console.error('Mutation failed', e);
          Sentry.captureException(e);
        });
    },
    [mutate, client],
  );

  return [myMutate, rest] as [typeof myMutate, ReturnType<typeof useMut>[1]];
};
