import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { OrderViewActions, usePickGroup } from '../state/orderView';
import { useCurrentUser } from '../state/user';
import Typography from '@mui/material/Typography';

export const BranchSelector: React.FC = () => {
  const user = useCurrentUser();
  const pickGroup = usePickGroup();

  const dispatch = useDispatch();
  const setPickGroup = (id: string) => {
    const group = user?.pickGroups.find((x) => x.id === id);
    if (group == null) return;
    return dispatch(
      OrderViewActions.setPickGroup({
        id: group.id,
        branchIds: group.branches.map((x) => x.id),
      }),
    );
  };

  if (!user) {
    return null;
  }

  const groups = user.pickGroups;

  if (groups.length === 0) return null;
  if (groups.length === 1) {
    const group = groups[0].name;
    return (
      <Typography style={{ paddingTop: 6, paddingBottom: 7 }}>
        {group}
      </Typography>
    );
  }
  return (
    <Select
      value={pickGroup?.id ?? -1}
      onChange={(e) => setPickGroup(e.target.value as string)}
      input={<InputBase id="branch-input" />}
      variant="standard"
    >
      {groups.map((b) => (
        <MenuItem key={b.id} value={b.id}>
          {b.name}
        </MenuItem>
      ))}
    </Select>
  );
};
