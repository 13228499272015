import React from 'react';

import Grid from '@mui/material/Grid';
import Typography from '@mui/material/Typography';
import { orderStatusDisplayString } from '../utils';
import { SalesOrder } from '../generated/graphql';
import { OrderCustomer } from './OrderCustomer';

export interface OrderSummaryProps {
  order: SalesOrder;
}

export const OrderSummary: React.FC<OrderSummaryProps> = ({ order }) => (
  <Grid container justifyContent="space-between">
    <Grid item container justifyContent="space-between" xs={8}>
      <Grid item xs={4}>
        <Typography variant="h6">#{order.id}</Typography>
      </Grid>
      <Grid item xs={8}>
        <OrderCustomer
          customerName={order.customerName}
          debtorName={order.customer.name}
        />
      </Grid>
      <Grid item xs={4}>
        <Typography color="textSecondary">
          {orderStatusDisplayString(order)}
        </Typography>
      </Grid>
      <Grid item xs={8}>
        <Typography color="textSecondary">{order.customerReference}</Typography>
      </Grid>
      {order.shippingProvider?.name && (
        <Grid item xs={12} style={{ marginTop: '2em' }}>
          <Typography color="textSecondary">
            Shipping Provider: {order.shippingProvider.name}
          </Typography>
        </Grid>
      )}
      {order.notes !== '' && (
        <Grid item xs={12} style={{ marginTop: '2em' }}>
          <Typography color="textSecondary">{order.notes}</Typography>
        </Grid>
      )}
    </Grid>

    <Grid item container xs={4}>
      <Grid item xs={12}>
        <Typography variant="h6" align="right">
          Deliver To
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography color="textSecondary" align="right">
          {order.deliverTo.map((line, i) => (
            <React.Fragment key={i}>
              {line}
              <br />
            </React.Fragment>
          ))}
        </Typography>
      </Grid>
    </Grid>
  </Grid>
);
