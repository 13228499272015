import { version } from '../package.json';

const localhost = {
  profile: 'localhost',
  version,
  // serverUri: 'https://staging-api.genia.co.nz/graphql', // <-- local or remote server as needed
  serverUri: 'http://localhost:4040/graphql', // <-- local or remote server as needed
  azureAd: {
    clientId: '',
    tenantId: '',
    apiIdentifier: '',
  },
};

const development = {
  profile: 'development',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
  azureAd: {
    clientId: '0c9103c4-eed5-4608-8ebb-24055cf70596',
    tenantId: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
    apiIdentifier: 'api://81345e5e-e3e3-42b9-b74c-85d2bc1f79da/user_impersonation',
  },
};

const staging = {
  profile: 'staging',
  version,
  serverUri: 'https://staging-api.genia.co.nz/graphql',
  azureAd: {
    clientId: '0c9103c4-eed5-4608-8ebb-24055cf70596',
    tenantId: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
    apiIdentifier: 'api://81345e5e-e3e3-42b9-b74c-85d2bc1f79da/user_impersonation',
  },
};

const production = {
  profile: 'production',
  version,
  serverUri: 'https://api.genia.co.nz/graphql',
  azureAd: {
    clientId: '4dea2178-f125-4cce-963b-c557756fb5da',
    tenantId: 'b7ccbed5-8309-42db-9a8d-4d5a2ae5fab4',
    apiIdentifier: 'api://2ed90395-b6ed-43ef-8632-d014b425d6b6/user_impersonation',
  },
};

export const env = (() => {
  const url = window.location.href;
  if (url.includes('pickle.dev01.genia.co.nz')) return development;
  if (url.includes('staging-pickle.genia.co.nz')) return staging;
  if (url.includes('pickle.genia.co.nz')) return production;
  return localhost;
})();

export const releaseProfile = () => env.profile || 'local';
