import * as Sentry from '@sentry/react';
import { User } from '../models/User';
import { OrderViewActions } from './orderView';
import { createReducer, Action, createAction } from 'typesafe-actions';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import getApollo from '../services/apollo';
import { store } from '../App';
import { MeDocument, MeQuery, MeQueryVariables } from '../generated/graphql';
import { getAuthProvider } from '../services/auth';

export interface UserState {
  loggingIn: boolean;
  user: User | null;
}

const initialState: UserState = {
  loggingIn: false,
  user: null,
};

export const useUserLoggedIn = () =>
  useSelector<AppState, User | null>((s) => s.user.user) !== null;

export const useUserIsLoggingIn = () =>
  useSelector<AppState, boolean>((s) => s.user.loggingIn);

export const useCurrentUser = () =>
  useSelector<AppState, User | null>((s) => s.user.user);

export const clearCachedPin = () => sessionStorage.removeItem('user-pin');
export const setCachedPin = (pin: string) =>
  sessionStorage.setItem('user-pin', pin);
export const getCachedPin = () => sessionStorage.getItem('user-pin');

export const UserActions = {
  setUser: createAction('@user/SET_USER', (user: User | null) => user)(),
  setLoggingIn: createAction(
    '@user/SET_LOGGING_IN',
    (value: boolean) => value,
  )(),

  login: (user: User) => (dispatch: any) => {
    dispatch(UserActions.setUser(user));

    const pickGroup = user.pickGroups[0] ?? null;
    dispatch(
      OrderViewActions.setPickGroup(
        pickGroup && {
          id: pickGroup.id,
          branchIds: pickGroup.branches.map((x) => x.id),
        },
        true,
      ),
    );

    dispatch(
      OrderViewActions.setStockLocationId(
        user.fulfillingStockLocations?.[0]?.id ?? null,
        true,
      ),
    );

    if (user.pin) {
      setCachedPin(user.pin);
    }
  },
  logout: () => (dispatch: any, getStore: () => any) => {
    const { pin } = getStore().user.user;

    dispatch(UserActions.setUser(null));
    dispatch(OrderViewActions.setPickGroup(null));
    dispatch(OrderViewActions.setStockLocationId(null));

    clearCachedPin();

    if (pin == null) {
      // Was AAD login
      getAuthProvider().logoutRedirect();
    }
  },
};

const aadLoginAction = createAction('AAD_LOGIN_SUCCESS')();

export const userReducer = createReducer<UserState, Action>(initialState)
  .handleAction(UserActions.setUser, (state, { payload }) => {

    return {
      ...state,
      user: payload,
    };
  })
  .handleAction(UserActions.setLoggingIn, (state, { payload }) => ({
    ...state,
    loggingIn: payload,
  }))
  .handleAction(aadLoginAction, (state) => {
    if (state.loggingIn) return state;

    getApollo(null)
      .query<MeQuery, MeQueryVariables>({
        query: MeDocument,
        fetchPolicy: 'no-cache',
      })
      .then((result) => {
        const userData = result.data!.me;
        if (userData == null) {
          console.error("Account isn't set up for use with Pickle.");
          window.alert(
            "Account isn't set up for use with Pickle. Please lodge an IT support ticket. We will sign you out now, and once we've got your account sorted, please log back in.",
          );
          getAuthProvider().logoutRedirect();
          return;
        }

        const userDataWithPin = { ...userData, pin: undefined } as User;
        store.dispatch(UserActions.login(userDataWithPin) as any);
        store.dispatch(UserActions.setLoggingIn(false));
      })
      .catch((error) => {
        console.error('`Me` query failed', error);
        Sentry.captureException(error);
      });

    return {
      ...state,
      loggingIn: true,
    };
  });

export default userReducer;
