import React, { useState, useEffect, useCallback } from 'react';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import InfiniteScroll from 'react-infinite-scroll-component';
import { useSalesOrdersQuery } from '../graphql/queries/useOrdersQuery';
import { Loading } from './Loading';
import { Error } from './Error';
import { useSelector, useDispatch } from 'react-redux';
import { AppState } from '../state';
import { OrderPreviewItem } from './OrderPreviewItem';
import {
  FETCH_EXTRA_SIZE,
  OrderViewState,
  PickGroup,
  useOrdersRowCount,
} from '../state/orderView';
import { OrderViewActions } from '../state/orderView';
import { OrderListType } from '../models/OrderListType';

export const OrderPreviewList: React.FC = () => {
  const { pickGroup, stockLocationId, listType } = useSelector<
    AppState,
    OrderViewState
  >((s) => s.orderView);

  const { error, orders, fetchedAll, loadMore } = useOrders({
    listType,
    pickGroup,
    stockLocationId,
  });

  if (error) return <Error />;
  if (!orders) return <Loading />;

  return (
    <div style={{ marginBottom: '4rem', overflow: 'none' }}>
      {orders.length > 0 ? (
        <List>
          <InfiniteScroll
            dataLength={orders.length}
            next={loadMore}
            hasMore={!fetchedAll}
            loader={<Loading />}
            style={{ overflow: 'none' }}
            endMessage={
              <>
                <Divider light style={{ marginTop: '2rem' }} />
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    paddingTop: '1em',
                  }}
                >
                  <Typography variant="body1">
                    You've reached the end of the list
                  </Typography>
                </div>
              </>
            }
          >
            {orders.map((order) => (
              <OrderPreviewItem key={order.id} order={order} />
            ))}
          </InfiniteScroll>
        </List>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1em',
          }}
        >
          <Typography variant="body1">Nothing to see here...</Typography>
        </div>
      )}
    </div>
  );
};

function useOrders({
  pickGroup,
  stockLocationId,
  listType,
}: {
  pickGroup: PickGroup | null;
  stockLocationId: string | null;
  listType: OrderListType;
}) {
  const [fetchingMore, setFetchingMore] = useState(false);

  const fetchCount = useOrdersRowCount();

  const dispatch = useDispatch();
  const setFetchCount = useCallback(
    (count: number) => {
      dispatch(OrderViewActions.setCount(count));
      // Dispatch will be stable anyway
      // https://react-redux.js.org/api/hooks#usedispatch
    },
    [dispatch],
  );

  const { error, data, previousData } = useSalesOrdersQuery(
    pickGroup != null && stockLocationId != null
      ? {
          listType,
          take: fetchCount,
          branchIds: pickGroup.branchIds,
          stockLocationId,
        }
      : null,
  );

  useEffect(() => {
    if (data != null) {
      setFetchingMore(false);
    }
  }, [data]);

  useEffect(() => {
    // setFetchCount will be stable since dispatch is stable.
    // So we don't need to compare the three values we actually care about
    // before doing this
    setFetchCount(FETCH_EXTRA_SIZE);
  }, [listType, pickGroup?.branchIds, stockLocationId, setFetchCount]);

  const dataToUse = fetchingMore ? data ?? previousData : data;
  const orders = dataToUse?.salesOrders;

  const fetchedAll = data && data.salesOrders.length < fetchCount;

  const loadMore = useCallback(() => {
    if (fetchedAll) return;
    setFetchCount(fetchCount + FETCH_EXTRA_SIZE);
    setFetchingMore(true);
  }, [fetchedAll, fetchCount, setFetchCount]);

  return {
    orders,
    fetchedAll,
    error,
    loadMore,
  };
}
