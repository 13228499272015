import { SalesOrderStatus, SalesOrder } from './generated/graphql';

export const round = (value: number, dp: number) => {
  const decimals = value - Math.floor(value);
  if (decimals !== 0) return value.toFixed(dp);
  return value;
};

type OrderStatusFields = Pick<
  SalesOrder,
  'onHold' | 'hasBackOrders' | 'status' | 'internalReference'
>;

export function orderStatusDisplayString(order: OrderStatusFields): string {
  if (order.onHold) return 'On Hold';
  if (order.hasBackOrders) return 'Back Ordered';

  const { status } = order;
  if (status === SalesOrderStatus.FullyProcessed) return 'Fully Processed';

  const unavailable = order.internalReference === 'PICKED';
  if (unavailable) return 'Unavailable';

  if (status === SalesOrderStatus.NotProcessed) return 'Unprocessed';
  if (status === SalesOrderStatus.PartiallyProcessed) return 'Partly Processed';
  if (status === SalesOrderStatus.Quote) return 'Quote';
  if (status === SalesOrderStatus.Other) return 'Other';
  throw new Error(`Unknown SalesOrderStatus value: ${status}`);
}
