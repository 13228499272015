import { gql } from '@apollo/client';
import {
  useStockLocationId,
  useListType as useOrderListType,
} from '../../state/orderView';
import {
  useSalesOrderQuery,
  SalesOrderLinePickCategory,
} from '../../generated/graphql';
import { OrderListType } from '../../models/OrderListType';

export const SALES_ORDER_QUERY = gql`
  query SalesOrder(
    $id: ID!
    $stockLocationId: ID!
    $hideSpecial: Boolean!
    $categories: [SalesOrderLinePickCategory!]
  ) {
    salesOrder(id: $id) {
      id
      deliverTo
      customerName
      customer {
        name
      }
      salesBranch {
        id
      }
      shippingProvider {
        id
        name
      }
      internalReference
      customerReference
      dateOrdered
      status
      hasBackOrders
      notes
      onHold
      activeFulfilment {
        id
        dateCompleted
        lines {
          id
          quantityFulfilled
          packs {
            id
            quantity
            packNumber
            metersCubed
            kilograms
          }
          salesOrderLine {
            id
          }
        }
        createdBy {
          id
          givenName
        }
      }
      lines(
        stockLocationId: $stockLocationId
        categories: $categories
        hideSpecial: $hideSpecial
      ) {
        id
        isForceClosed
        description
        quantityOrdered
        quantitySupplied
        quantityBackOrdered
        stockItem {
          stockCode
          barcode
          quantityMultiplier
          kilograms
          metresCubed
          unitOfMeasure {
            name
          }
          locationInfo(stockLocationId: $stockLocationId) {
            binCode
          }
        }
      }
    }
  }
`;

export const useOrderQuery = (id: string | null, cached?: boolean) => {
  const stockLocationId = useStockLocationId();
  const listType = useOrderListType();

  const map: Record<OrderListType, SalesOrderLinePickCategory | null> = {
    [OrderListType.Ready]: SalesOrderLinePickCategory.Ready,
    [OrderListType.OnHold]: null,
    [OrderListType.BackOrdered]: null,
    [OrderListType.Unavailable]: null,
    [OrderListType.FullyProcessed]: null,
  };

  const category = map[listType];

  const args =
    id && stockLocationId
      ? {
          id,
          stockLocationId,
          hideSpecial: true,
          categories: category
            ? [category, SalesOrderLinePickCategory.FullySupplied]
            : null,
        }
      : null!;
  const result = useSalesOrderQuery({
    variables: args,
    skip: args == null,
    fetchPolicy: cached ? 'cache-first' : 'cache-and-network',
  });

  return result;
};
