import { gql } from '@apollo/client';
import { usePickLineMutation as useMut } from '../../generated/graphql';

gql`
  mutation PickLine($input: FulfilmentPickLineInput!) {
    fulfilmentPickLine(input: $input)
  }
`;

export const usePickLineMutation = (
  fulfilmentId: string,
  salesOrderLineId: string,
  quantity: number,
) => {
  return useMut({
    variables: {
      input: {
        fulfilmentId,
        salesOrderLineId,
        quantity,
      },
    },
    refetchQueries: [
      {
        query: gql`
          query PickLineRefetch {
            me {
              activeFulfilment {
                id
                lines {
                  id
                  quantityFulfilled
                  salesOrderLine {
                    id
                  }
                }
              }
            }
          }
        `,
      },
    ],
    awaitRefetchQueries: true,
  });
};
