import React from 'react';
import MenuItem from '@mui/material/MenuItem';
import InputBase from '@mui/material/InputBase';
import Select from '@mui/material/Select';
import { useDispatch } from 'react-redux';
import { OrderViewActions, useStockLocationId } from '../state/orderView';
import { useCurrentUser } from '../state/user';
import Typography from '@mui/material/Typography';

export const StockLocationSelector: React.FC = () => {
  const user = useCurrentUser();
  const id = useStockLocationId();

  const dispatch = useDispatch();
  const setStockLocationId = (v: string) =>
    dispatch(OrderViewActions.setStockLocationId(v));

  if (!user) return null;

  const locations = user.fulfillingStockLocations;
  if (locations.length === 0) return null;
  if (locations.length === 1) {
    const location = locations[0].name;
    return (
      <Typography style={{ paddingTop: 6, paddingBottom: 7 }}>
        {location.split('.')[1] || location}
      </Typography>
    );
  }

  return (
    <Select
      value={id || -1}
      onChange={(e) => setStockLocationId(e.target.value as string)}
      input={<InputBase id="stock-location-input" />}
      variant="standard"
    >
      {locations.map((l) => (
        <MenuItem key={l.id} value={l.id}>
          {l.name.split('.')[1] || l.name}
        </MenuItem>
      ))}
    </Select>
  );
};
