import IconButton from '@mui/material/IconButton';
import Grid from '@mui/material/Grid';
import ListItem from '@mui/material/ListItem';
import Typography from '@mui/material/Typography';
import VisibilityIcon from '@mui/icons-material/Visibility';
import dateFormat from 'dateformat';
import React from 'react';
import { FulfilmentsQuery, FulfilmentStatus } from '../generated/graphql';
import { OrderCustomer } from './OrderCustomer';

const statusMap: Record<FulfilmentStatus, string> = {
  ABANDONED: 'ABANDONED',
  UNFINALIZED: 'PENDING PHOTO',
  COMPLETED: 'PICKED',
  NEW: 'BEING PICKED',
};

export interface FulfilmentPreviewItemProps {
  fulfilment: FulfilmentsQuery['fulfilments'][0];
  onOpen: () => void;
  userId: string;
}

export const FulfilmentPreviewItem: React.FC<FulfilmentPreviewItemProps> = ({
  fulfilment,
  onOpen,
  userId,
}) => {
  return (
    <ListItem>
      <Grid container justifyContent="space-between">
        <Grid item container alignItems="center" xs={3}>
          <div>
            <Typography variant="h6">
              {fulfilment.createdBy.id === userId
                ? 'ME'
                : `${fulfilment.createdBy.givenName} ${fulfilment.createdBy.familyName}`.toUpperCase()}
            </Typography>
            <Typography color="textSecondary">
              {dateFormat(fulfilment.dateCreated, 'yyyy/mm/dd')}
            </Typography>
          </div>
        </Grid>
        <Grid item container alignItems="center" xs={8}>
          <div>
            <OrderCustomer
              customerName={fulfilment.salesOrder.customerName}
              debtorName={fulfilment.salesOrder.customer.name}
            />
            <Typography color="textSecondary">
              {fulfilment.salesOrder.id} -{' '}
              {fulfilment.salesOrder.customerReference} -{' '}
              {statusMap[fulfilment.status]} -{' '}
              {fulfilment.stockLocation.name.split('.')[1] ||
                fulfilment.stockLocation.name}
              {fulfilment.invoiceNumber
                ? ` - INV#${fulfilment.invoiceNumber}`
                : ''}
            </Typography>
          </div>
        </Grid>
        <Grid
          item
          xs={1}
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <IconButton onClick={onOpen} size="large">
            <VisibilityIcon />
          </IconButton>
        </Grid>
      </Grid>
    </ListItem>
  );
};
