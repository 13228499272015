import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Integrations as TracingIntegrations } from '@sentry/tracing';
import * as Integrations from '@sentry/integrations';
import { excludeGraphQLFetch } from 'apollo-link-sentry';
import { App } from './App';
import { Error } from './components/Error';
import 'sweetalert2/dist/sweetalert2.css';
import './global.css';
import {
  determineServer,
  ServerConfig,
} from './services/apollo/determine-server';
import { history } from './history';
import { env, releaseProfile } from './runtime-environment';

if (process.env.NODE_ENV !== 'development') {
  Sentry.init({
    dsn: 'https://fbd34ad6b50240ce9b055671e0740ea7@o925760.ingest.sentry.io/5918753',
    release: `pickle@${env.version}`,
    environment: releaseProfile(),
    normalizeDepth: 10,
    // We add them with more detail through apollo-link-sentry
    beforeBreadcrumb: excludeGraphQLFetch,
    integrations: [
      new TracingIntegrations.BrowserTracing({
        tracingOrigins: ['localhost', '.genia.co.nz'],
        routingInstrumentation: Sentry.reactRouterV5Instrumentation(history),
      }),
      new Integrations.CaptureConsole({
        levels: ['warn', 'error'],
      }),
      new Integrations.Offline(),
    ],
    tracesSampleRate: 0.05,
  });
}

determineServer().then((config: ServerConfig) => {
  ReactDOM.render(
    <Sentry.ErrorBoundary
      fallback={<Error fullscreen errorText="Uh-oh. The app crashed." />}
      showDialog
      dialogOptions={() => {}}
    >
      <App {...config} />
    </Sentry.ErrorBoundary>,
    document.getElementById('root'),
  );
});
