import Divider from '@mui/material/Divider';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import React from 'react';
import { useFulfilmentsQuery } from '../generated/graphql';
import {
  FULFILMENT_MOST_RECENT_COUNT,
  FULFILMENT_SEARCH_COUNT,
} from '../graphql/queries/useFulfilmentsQuery';
import { useCurrentUser } from '../state/user';
import { Error } from './Error';
import { FulfilmentPreviewItem } from './FulfilmentPreviewItem';
import { Loading } from './Loading';

export const FulfilmentPreviewList: React.FC<{
  searchQuery: string | null;
  onOpen: (fulfilmentId: string) => void;
}> = ({ searchQuery, onOpen }) => {
  const { loading, error, data } = useFulfilmentsQuery({
    pollInterval: 15000,
    variables: {
      take: searchQuery
        ? FULFILMENT_SEARCH_COUNT
        : FULFILMENT_MOST_RECENT_COUNT,
      searchQuery: searchQuery || null,
    },
  });

  const userId = useCurrentUser()!.id;

  if (loading) return <Loading />;
  if (!data || error) return <Error />;

  const { fulfilments } = data;
  return (
    <div style={{ marginBottom: '4rem', overflow: 'none' }}>
      {fulfilments.length > 0 ? (
        <List>
          {fulfilments.map((fulfilment) => (
            <FulfilmentPreviewItem
              key={fulfilment.id}
              fulfilment={fulfilment}
              onOpen={() => onOpen(fulfilment.id)}
              userId={userId}
            />
          ))}

          <Divider light style={{ marginTop: '2rem' }} />
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              paddingTop: '1em',
            }}
          >
            <Typography variant="body1">
              That's the {fulfilments.length} fulfilments we've decided to load
              for you
            </Typography>
          </div>
        </List>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            paddingTop: '1em',
          }}
        >
          <Typography variant="body1">Nothing to see here...</Typography>
        </div>
      )}
    </div>
  );
};
