import { createAction, createReducer, Action } from 'typesafe-actions';
import { useSelector } from 'react-redux';
import { AppState } from '.';
import { FulfilmentLine } from '../generated/graphql';

export interface ShipmentState {
  /** fulfilment line id -> count */
  lineShipItems: Record<string, number>;

  notPickingIds: string[];
  notBackorderingIds: string[];
}

const initialState: ShipmentState = {
  lineShipItems: {},
  notPickingIds: [],
  notBackorderingIds: [],
};

export const ShipmentActions = {
  reset: createAction('@shipment/RESET')(),
  setLineShipItem: createAction(
    '@shipment/SET_LINE_SHIP_ITEM',
    (lineId: string, count: number | null) => ({ lineId, count }),
  )(),
  changeLineShipItem: createAction(
    '@shipment/CHANGE_LINE_SHIP_ITEM',
    (lineId: string, amount: number) => ({ lineId, amount }),
  )(),
  toggleNotPicking: createAction(
    '@shipment/TOGGLE_NOT_PICKING',
    (lineId: string) => lineId,
  )(),
  toggleNotBackordering: createAction(
    '@shipment/TOGGLE_NOT_BACKORDERING',
    (lineId: string) => lineId,
  )(),
};

export const useLineShipItems = () =>
  useSelector<AppState, Record<string, number>>(
    (s) => s.shipment.lineShipItems,
  );

export const useNotPickingIds = () =>
  useSelector<AppState, string[]>((s) => s.shipment.notPickingIds);
export const useNotBackorderingIds = () =>
  useSelector<AppState, string[]>((s) => s.shipment.notBackorderingIds);

export const useTotalItemCount = (lines: FulfilmentLine[]) => {
  const lineShipItems = useLineShipItems();
  const lineItemsTotal = Object.values(lineShipItems).reduce(
    (a, b) => a + b,
    0,
  );
  const packCount = lines.map((x) => x.packs.length).reduce((a, b) => a + b, 0);
  return lineItemsTotal + packCount;
};

const shipmentReducer = createReducer<ShipmentState, Action>(initialState)
  .handleAction(ShipmentActions.reset, () => initialState)
  .handleAction(ShipmentActions.setLineShipItem, (state, { payload }) => {
    const lineShipItems = { ...state.lineShipItems };
    if (payload.count === null) delete lineShipItems[payload.lineId];
    else lineShipItems[payload.lineId] = payload.count;
    return {
      ...state,
      lineShipItems,
    };
  })
  .handleAction(ShipmentActions.changeLineShipItem, (state, { payload }) => ({
    ...state,
    lineShipItems: {
      ...state.lineShipItems,
      [payload.lineId]:
        (state.lineShipItems[payload.lineId] || 0) + payload.amount,
    },
  }))
  .handleAction(ShipmentActions.toggleNotPicking, (state, { payload }) => ({
    ...state,
    notPickingIds: state.notPickingIds.includes(payload)
      ? state.notPickingIds.filter((x) => x !== payload)
      : [...state.notPickingIds, payload],
    // Ensure default
    notBackorderingIds: state.notBackorderingIds.filter((x) => x !== payload),
  }))
  .handleAction(
    ShipmentActions.toggleNotBackordering,
    (state, { payload }) => ({
      ...state,
      notBackorderingIds: state.notBackorderingIds.includes(payload)
        ? state.notBackorderingIds.filter((x) => x !== payload)
        : [...state.notBackorderingIds, payload],
    }),
  );

export default shipmentReducer;
