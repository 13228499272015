import { gql } from '@apollo/client';
import { useNotBackorderingIds } from '../../state/shipment';
import { useCompleteFulfilmentMutation as useMut } from '../../generated/graphql';

gql`
  mutation CompleteFulfilment($input: FulfilmentCompleteInput!) {
    fulfilmentComplete(input: $input)
  }
`;

export const useCompleteFulfilmentMutation = (data: {
  fulfilmentId: string;
  salesOrderId: string;
  shippingProviderId: string;
  shipmentItemCount: number;
}) => {
  const noBackorderLines = useNotBackorderingIds();
  return useMut({
    variables: {
      input: {
        fulfilmentId: data.fulfilmentId,
        shippingProviderId: data.shippingProviderId,
        shipmentItemCount: data.shipmentItemCount,
        doNotBackOrderSalesOrderLines: noBackorderLines,
      },
    },
    refetchQueries: ['SalesOrder', 'SalesOrders'],
    awaitRefetchQueries: true,
  });
};
