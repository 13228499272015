import React from 'react';

import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';

import { CenterPage } from './CenterPage';
import { SalesOrderLine } from '../generated/graphql';

export interface ScanSuccessProps {
  orderLine: SalesOrderLine;
  scanned: string;
}

export const ScanSuccess: React.FC<ScanSuccessProps> = ({
  orderLine,
  scanned,
}) => {
  return (
    <CenterPage>
      <Container maxWidth="sm">
        <Typography
          variant="h1"
          style={{
            textAlign: 'center',
          }}
        >
          {orderLine.stockItem.locationInfo.binCode}
        </Typography>
        <Typography
          variant="h6"
          style={{
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          {orderLine.stockItem.stockCode}
          <br />
          {orderLine.description}
        </Typography>
        <Typography
          variant="h2"
          style={{
            color: '#383',
            textAlign: 'center',
            paddingBottom: '4rem',
          }}
        >
          {scanned}
        </Typography>
        <div
          style={{
            marginBottom: '2rem',
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <Typography>Verified</Typography>
        </div>
      </Container>
    </CenterPage>
  );
};
