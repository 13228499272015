import { useSelector } from 'react-redux';
import { AppState } from '.';
import { createAction, createReducer, Action } from 'typesafe-actions';
import { OrderListType } from '../models/OrderListType';

export interface OrderViewState {
  count: number;
  pickGroup: PickGroup | null;
  stockLocationId: string | null;
  listType: OrderListType;
}

export interface PickGroup {
  id: string;
  branchIds: string[];
}

// The number of orders that are fetched at a time.
// Initially fetches this many, then after scrolling, fetches this many again,
// and so on.
export const FETCH_EXTRA_SIZE = 50;

const initialState: OrderViewState = {
  count: FETCH_EXTRA_SIZE,
  pickGroup: null,
  stockLocationId: null,
  listType: OrderListType.Ready,
};

export const OrderViewActions = {
  setCount: createAction('@orderView/SET_COUNT', (count: number) => count)(),
  setPickGroup: createAction(
    '@orderView/SET_PICK_GROUP',
    (pickGroup: PickGroup | null, noReplace?: boolean) => ({
      pickGroup,
      noReplace,
    }),
  )(),
  setStockLocationId: createAction(
    '@orderView/SET_STOCK_LOCATION_ID',
    (id: string | null, noReplace?: boolean) => ({ id, noReplace }),
  )(),
  setListType: createAction(
    '@orderView/SET_LIST_TYPE',
    (type: OrderListType) => type,
  )(),
};

export const useStockLocationId = () =>
  useSelector<AppState, string | null>((s) => s.orderView.stockLocationId);

export const useOrdersRowCount = () =>
  useSelector<AppState, number>((s) => s.orderView.count);

export const useListType = () =>
  useSelector<AppState, OrderListType>((s) => s.orderView.listType);

export const usePickGroup = () =>
  useSelector<AppState, PickGroup | null>((s) => s.orderView.pickGroup);

const orderViewReducer = createReducer<OrderViewState, Action>(initialState)
  .handleAction(OrderViewActions.setCount, (state, { payload }) => ({
    ...state,
    count: payload,
  }))
  .handleAction(OrderViewActions.setPickGroup, (state, { payload }) => {
    if (payload.noReplace && state.pickGroup !== null) return state;
    return {
      ...state,
      pickGroup: payload.pickGroup,
    };
  })
  .handleAction(OrderViewActions.setStockLocationId, (state, { payload }) => {
    if (payload.noReplace && state.stockLocationId !== null) return state;
    return {
      ...state,
      stockLocationId: payload.id,
    };
  })
  .handleAction(OrderViewActions.setListType, (state, { payload }) => ({
    ...state,
    listType: payload,
  }));

export default orderViewReducer;
