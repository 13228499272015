import React, { useEffect } from 'react';
import { Paper, Typography } from '@mui/material';
import styled from 'styled-components';
import { useOrderQuery } from '../graphql/queries/useOrderQuery';
import Swal from 'sweetalert2';
import { SalesOrderQuery } from '../generated/graphql';

export const FulfilmentStats: React.FC<{ salesOrderId: string }> = ({
  salesOrderId,
}) => {
  const { data } = useOrderQuery(salesOrderId, true);
  const stats = getStats(data);

  useEffect(() => {
    if (!stats?.hasProblem) return;
    Swal.fire({
      title:
        'One or more lines has changed on this order. Please abandon this fulfilment to get an accurate order.',
      icon: 'error',
      showConfirmButton: true,
    });
  }, [stats?.hasProblem]);

  if (stats?.hasProblem) {
    return <ContainerStyle>Saw a problem...</ContainerStyle>;
  }

  if (!stats) {
    return null;
  }

  return (
    <ContainerStyle>
      <Typography variant="caption">Picked</Typography>
      <div className="measurements">
        <Typography variant="h6">KG: {Math.ceil(stats.kg)}</Typography>
        <Typography variant="h6">M3: {stats.m3.toFixed(3)}</Typography>
      </div>
    </ContainerStyle>
  );
};

export const ContainerStyle = styled(Paper)`
  position: fixed;
  left: 120px;
  bottom: 20px;

  width: 300px;

  .measurements {
    display: flex;
    justify-content: space-between;
  }

  padding: 0.5rem 1.5rem;
  border-radius: 6px;
`;

function getStats(data: SalesOrderQuery | undefined) {
  const salesOrder = data?.salesOrder;
  const fulfilment = data?.salesOrder?.activeFulfilment;
  if (salesOrder == null || fulfilment == null) {
    return null;
  }

  let hasProblem = false;

  const lineMappings = new Map(
    salesOrder.lines.map((x) => [
      x.id,
      { m3: x.stockItem.metresCubed ?? 0, kg: x.stockItem.kilograms ?? 0 },
    ]),
  );

  let kg = 0;
  let m3 = 0;
  for (const line of fulfilment.lines) {
    if (line.packs.length === 0) {
      const item = lineMappings.get(line.salesOrderLine.id)!;
      if (item == null) {
        hasProblem = true;
        break;
      }
      kg += item.kg * line.quantityFulfilled;
      m3 += item.m3 * line.quantityFulfilled;
    } else {
      // Use total pack weights and M3
      kg += line.packs.reduce((acc, pack) => acc + pack.kilograms, 0);
      m3 += line.packs.reduce((acc, pack) => acc + pack.metersCubed, 0);
    }
  }

  return {
    kg,
    m3,
    hasProblem,
  };
}
