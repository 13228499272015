import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { Button, Typography } from '@mui/material';
import dateFormat from 'dateformat';
import { FulfilmentQuery } from '../generated/graphql';

export interface ConsignmentGroupProps {
  providerName: string;
  group: NonNullable<
    NonNullable<FulfilmentQuery['fulfilment']>['consignmentGroup']
  >;
}

export function ConsignmentGroup({
  providerName,
  group,
}: ConsignmentGroupProps) {
  return (
    <section style={{ marginTop: '1em' }}>
      <Typography variant="h6">Consignment Info</Typography>
      <Typography color="textSecondary" variant="subtitle1">
        Notified {providerName}:{' '}
        {dateFormat(group.dateSent, 'yyyy/mm/dd h:MM:ss tt')}
      </Typography>
      {group.documents.map((document) => {
        if (document.info.__typename !== 'PresentFile') {
          return (
            <div style={{ marginTop: '1em' }}>
              <Typography key={document.id} color="error">
                Deleted: {document.name}
              </Typography>
            </div>
          );
        }

        return (
          <Button
            key={document.id}
            component="a"
            href={document.info.accessUrl}
            endIcon={<OpenInNewIcon />}
            target="_blank" // Open in new tab
            style={{ margin: '1em 1em 0 0' }}
          >
            {document.name}
          </Button>
        );
      })}
    </section>
  );
}
