import React, { useState } from 'react';
import * as Sentry from '@sentry/react';

import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import ClearIcon from '@mui/icons-material/Clear';
import IncrementIcon from '@mui/icons-material/Add';
import DecrementIcon from '@mui/icons-material/Remove';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Switch from '@mui/material/Switch';

import { useHistory } from 'react-router-dom';
import styled from 'styled-components';

import { round } from '../utils';

import { useClearFulfilmentLineMutation } from '../graphql/mutations/useClearFulfilmentLineMutation';
import { Fulfilment, SalesOrderLine } from '../generated/graphql';

import { ConfirmationDialog } from './ConfirmationDialog';
import { determinePickType } from './PickPage';
import { ChangeQuantityButton } from './ChangeQuantityButton';
import {
  useLineShipItems,
  ShipmentActions,
  useNotPickingIds,
  useNotBackorderingIds,
} from '../state/shipment';
import { useDispatch } from 'react-redux';

const TableCellStyle = styled(TableCell)`
  min-height: 70px;
`;

// Frozen is an integer due to a styled-components bug
const TableRowStyle = styled(TableRow)<{
  frozen: number | undefined;
  type: 'normal' | 'pack';
}>`
  position: relative;
  ${(props) =>
    props.frozen === 1 && `& > * { color: rgba(0, 0, 0, 0.18) !important; }`}

  ${(props) =>
    props.type === 'pack' &&
    `
    & > ${TableCellStyle} {
      padding-top: 3px;
      padding-bottom: 3px;
      min-height: 40px;

      :nth-child(2) {
        padding-left: 60px;
      }
    }
  `}
`;

const nearlyZero = (value: number) => value < 1e-5 && value > -1e-5;
const nearlyZeroOrLess = (value: number) => value < 1e-5;
const quantityDisplay = (
  value: number,
  fontSize?: string,
  showZero?: boolean,
) => {
  if (nearlyZero(value)) {
    if (!showZero) return '-';
    value = 0;
  }

  if (fontSize) {
    return <span style={{ fontSize }}>{round(value, 2)}</span>;
  }
  return round(value, 2);
};

const Counter: React.FC<{ lineId: string }> = ({ lineId }) => {
  const lineShipItems = useLineShipItems();
  const dispatch = useDispatch();
  const value = lineShipItems[lineId] || null;
  const setValue = (val: number | null) => {
    dispatch(ShipmentActions.setLineShipItem(lineId, val));
  };

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const val = e.target.value as string;
    if (val === '') setValue(null);
    if (/^[0-9]+$/.test(val)) setValue(parseInt(val, 10));
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',
        margin: '0 -18px 0 -8px',
      }}
    >
      <ChangeQuantityButton value={value} setValue={setValue} changeAmount={1}>
        <IncrementIcon style={{ width: '1em', height: '1em' }} />
      </ChangeQuantityButton>
      <TextField
        type="number"
        placeholder="0"
        style={{ width: 30 }}
        value={value === null ? '' : value.toString()}
        inputProps={{ style: { textAlign: 'center' } }}
        onChange={onChange}
        autoFocus
        variant="standard"
      />
      <ChangeQuantityButton value={value} setValue={setValue} changeAmount={-1}>
        <DecrementIcon style={{ width: '1em', height: '1em' }} />
      </ChangeQuantityButton>
    </div>
  );
};

const OrderLineTableRow: React.FC<{
  line: SalesOrderLine;
  pick: ((lineId: string, wouldOversupply: boolean) => void) | null;
  frozen: boolean;
  activeFulfilment: Fulfilment | null;
}> = ({ line, pick, frozen, activeFulfilment }) => {
  const fulfilmentLine =
    activeFulfilment && activeFulfilment.lines
      ? activeFulfilment.lines.find((l) => l.salesOrderLine.id === line.id) ||
        null
      : null;
  let remainingQuantity = line.quantityOrdered - line.quantitySupplied;
  if (remainingQuantity < 0) remainingQuantity = 0;
  const [remainingToPick, picked] = fulfilmentLine
    ? [
        remainingQuantity - fulfilmentLine.quantityFulfilled,
        fulfilmentLine.quantityFulfilled,
      ]
    : [remainingQuantity, 0];

  const rowFrozen =
    frozen || nearlyZero(remainingQuantity) || line.isForceClosed;
  const lightGrey = rowFrozen ? 'rgba(0, 0, 0, 0.18)' : 'rgba(0, 0, 0, 0.54)';

  const picking = pick !== null;
  const [clearFulfilmentLineMutation] = useClearFulfilmentLineMutation(
    fulfilmentLine ? fulfilmentLine.id : '',
  );

  const dispatch = useDispatch();
  const clearFulfilmentLine = () => {
    try {
      dispatch(ShipmentActions.setLineShipItem(fulfilmentLine!.id, null));
      clearFulfilmentLineMutation();
    } catch (error) {
      console.log('error clearing fulfilment line');
      Sentry.captureException(error);
    }
  };

  const onDoNotPickToggle = () => {
    dispatch(ShipmentActions.toggleNotPicking(line.id));
  };

  const backordering = !useNotBackorderingIds().includes(line.id);
  const onDoNotBackorderToggle = () => {
    dispatch(ShipmentActions.toggleNotBackordering(line.id));
  };

  const bundlesPicked = picked / line.stockItem.quantityMultiplier;
  const totalBundles = remainingQuantity / line.stockItem.quantityMultiplier;
  return (
    <>
      <TableRowStyle frozen={rowFrozen ? 1 : undefined} type="normal">
        {picking && (
          <TableCellStyle
            style={{ paddingLeft: 0, paddingRight: 0 }}
            size="small"
            align="center"
          >
            {picked !== 0 &&
              (fulfilmentLine?.packs.length === 0 ? (
                <Counter lineId={fulfilmentLine!.id} />
              ) : (
                <strong>
                  {fulfilmentLine!.packs.length} PACK
                  {fulfilmentLine!.packs.length !== 1 ? 'S' : ''}
                </strong>
              ))}
          </TableCellStyle>
        )}

        <TableCellStyle
          component="th"
          scope="row"
          style={{ display: 'flex', alignItems: 'center' }}
        >
          <span
            style={{ fontSize: '1.5rem', paddingRight: '1em', flex: 'none', width: '3.5em', textAlign:'center'}}
          >
            {line.stockItem.locationInfo.binCode || '-'}
          </span>
          <div style={{ fontSize: '0.7rem', color: lightGrey }}>
            {line.stockItem.stockCode}
            <br />
            {line.description}
          </div>
        </TableCellStyle>

        {picking ? (
          <>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(picked, '1.5em', true)}/
              {quantityDisplay(remainingQuantity)}
            </TableCellStyle>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(bundlesPicked, '1.5em', true)}/
              {quantityDisplay(totalBundles)}
            </TableCellStyle>
            <TableCellStyle
              size="small"
              align="right"
              style={{ padding: 0, width: 93 }}
            >
              {nearlyZero(picked) ? (
                nearlyZero(remainingQuantity) || line.isForceClosed ? null : (
                  <Button
                    color="error"
                    onClick={onDoNotPickToggle}
                    style={{
                      width: '100%',
                      height: 69,
                    }}
                  >
                    {frozen ? 'Restore' : 'Skip'}
                  </Button>
                )
              ) : (
                <Button
                  color="primary"
                  onClick={() => clearFulfilmentLine()}
                  style={{
                    width: '100%',
                    height: 69,
                  }}
                >
                  <ClearIcon />
                </Button>
              )}
            </TableCellStyle>
            <TableCellStyle size="small" align="right" style={{ padding: 0 }}>
              {nearlyZero(picked) &&
              !nearlyZero(remainingQuantity) &&
              frozen ? (
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    justifyContent: 'space-around',
                  }}
                >
                  <Typography style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
                    Pick Later
                  </Typography>
                  <Switch
                    checked={!backordering}
                    onChange={onDoNotBackorderToggle}
                    color="primary"
                  />
                </div>
              ) : (
                <Button
                  color="primary"
                  onClick={() => {
                    if (frozen || pick === null) return;
                    const wouldOversupply = remainingToPick <= 1e-5;
                    pick(line.id, wouldOversupply);
                  }}
                  style={{
                    width: '100%',
                    height: 69,
                    visibility:
                      frozen || rowFrozen || pick === null || line.isForceClosed
                        ? 'hidden'
                        : 'visible',
                  }}
                >
                  Pick
                </Button>
              )}
            </TableCellStyle>
          </>
        ) : (
          <>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(line.quantityOrdered)}
            </TableCellStyle>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(line.quantitySupplied)}
            </TableCellStyle>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(line.quantityBackOrdered)}
            </TableCellStyle>
            <TableCellStyle size="small" align="right">
              {quantityDisplay(remainingQuantity, '1.5em')}
            </TableCellStyle>
          </>
        )}
      </TableRowStyle>
      {frozen &&
        fulfilmentLine?.packs.map((pack) => (
          <TableRowStyle
            key={pack.id}
            frozen={rowFrozen ? 1 : undefined}
            type="pack"
          >
            {picking && (
              <TableCellStyle
                style={{ paddingLeft: 0, paddingRight: 0 }}
                size="small"
                align="right"
              >
                1
              </TableCellStyle>
            )}

            <TableCellStyle
              component="th"
              scope="row"
              style={{ display: 'flex', alignItems: 'center' }}
            >
              <span
                style={{
                  fontSize: '1.2rem',
                  paddingRight: '1em',
                  flex: 'none',
                }}
              >
                {pack.packNumber}
              </span>
              <div style={{ fontSize: '0.7rem', color: lightGrey }}>
                {pack.kilograms} KG, {pack.metersCubed} M3
              </div>
            </TableCellStyle>
            <TableCellStyle size="small" align="right">
              {pack.quantity}
            </TableCellStyle>
            <TableCellStyle colSpan={3} />
          </TableRowStyle>
        ))}
    </>
  );
};

const TableRows: React.FC<{
  lines: SalesOrderLine[];
  pick: ((lineId: string, wouldOversupply: boolean) => void) | null;
  frozenIds: string[];
  activeFulfilment: Fulfilment | null;
}> = ({ lines, pick, frozenIds, activeFulfilment }) => {
  return (
    <>
      {lines.map((l) => (
        <OrderLineTableRow
          key={l.id}
          line={l}
          pick={pick}
          frozen={frozenIds.includes(l.id)}
          activeFulfilment={activeFulfilment}
        />
      ))}
    </>
  );
};

export interface OrderLineTableProps {
  salesOrderId: string;
  lines: SalesOrderLine[];
  frozen: boolean;
  picking: boolean;
  activeFulfilment: Fulfilment | null;
}

export const OrderLineTable: React.FC<OrderLineTableProps> = ({
  salesOrderId,
  lines,
  frozen,
  picking,
  activeFulfilment,
}) => {
  const history = useHistory();
  // Stores the id of the line that would be picked
  const [oversupplyConfirm, setOversupplyConfirm] = useState<null | string>(
    null,
  );

  const pick = (lineId: string, wouldOversupply: boolean) => {
    if (wouldOversupply) {
      setOversupplyConfirm(lineId);
      return;
    }

    setOversupplyConfirm(null);

    const pickType = determinePickType(activeFulfilment as Fulfilment, lineId);
    history.push(`/pick/${salesOrderId}/${lineId}/${pickType}`);
  };

  const notPickingIds = useNotPickingIds();

  const frozenIds = [
    ...(frozen ? lines.map((l) => l.id) : []),
    ...notPickingIds,
  ];

  return (
    <div
      style={{
        height: 'calc(100vh - 16rem)',
        overflow: 'auto',
      }}
    >
      <Table stickyHeader style={{ marginBottom: '16em' }}>
        <colgroup>
          {picking && <col />}
          <col style={{ width: '43%' }} />
        </colgroup>
        <TableHead>
          <TableRow>
            {picking && (
              <TableCellStyle
                style={{ minWidth: 100 }}
                size="small"
                align="center"
              >
                Items
              </TableCellStyle>
            )}

            <TableCell />
            {picking ? (
              <>
                <TableCell size="small" align="right">
                  Qty/Total
                </TableCell>
                <TableCell size="small" align="right">
                  Bdl/Total
                </TableCell>
                <TableCell size="small" />
                <TableCell size="small" style={{ minWidth: 101 }} />
              </>
            ) : (
              <>
                <TableCell size="small" align="right">
                  Ordered
                </TableCell>
                <TableCell size="small" align="right">
                  Supplied
                </TableCell>
                <TableCell
                  style={{ minWidth: '9.5em' }}
                  size="small"
                  align="right"
                >
                  Back Ordered
                </TableCell>
                <TableCell
                  style={{ minWidth: '6em' }}
                  size="small"
                  align="right"
                >
                  To Pick
                </TableCell>
              </>
            )}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRows
            lines={sortOrderLines(lines)}
            frozenIds={frozenIds}
            pick={picking ? pick : null}
            activeFulfilment={activeFulfilment}
          />
        </TableBody>
      </Table>
      <ConfirmationDialog
        open={!!oversupplyConfirm}
        close={() => setOversupplyConfirm(null)}
        handleOk={() => pick(oversupplyConfirm!, false)}
        dialogText={`Are you sure that you would like to oversupply this line?`}
      />
    </div>
  );
};

export function sortOrderLines(lines: SalesOrderLine[]): SalesOrderLine[] {
  const pickedLines = lines.filter(
    (x) =>
      nearlyZeroOrLess(x.quantityOrdered - x.quantitySupplied) ||
      x.isForceClosed,
  );
  const unpickedLines = lines.filter(
    (x) =>
      !nearlyZeroOrLess(x.quantityOrdered - x.quantitySupplied) &&
      !x.isForceClosed,
  );

  const sort = (a: SalesOrderLine, b: SalesOrderLine) =>
    (a.stockItem.locationInfo.binCode || '') >
    (b.stockItem.locationInfo.binCode || '')
      ? 1
      : -1;

  return [...unpickedLines.sort(sort), ...pickedLines.sort(sort)];
}
