import { createStore, applyMiddleware, compose } from 'redux';
import * as Sentry from '@sentry/react';
import { AppState, rootReducer } from './state';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import thunk from 'redux-thunk';

const persistConfig = {
  key: 'redux-root',
  storage,
  whitelist: ['orderView', 'shipment'],
};

const composeEnhancers =
  (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

const sentryReduxEnhancer = Sentry.createReduxEnhancer({
  configureScopeWithState: (scope, state: AppState) => {
    const { user } = state.user;
    if (user == null) {
      scope.setUser(null);
      scope.setTag('user.auth', 'logged-out');
    } else {
      scope.setUser({
        id: user.id,
        username: `${user.givenName} ${user.familyName}`,
        email: user.email ?? undefined,
      });

      scope.setTag('user.auth', user.pin == null ? 'azure' : 'pin');
    }
  },
  actionTransformer: (action) => {
    // Login actions should have payload removed
    if (action.type?.startsWith('AAD_')) {
      return { type: action.type, payload: '<redacted>' };
    }

    return action;
  },
});

const middleware = applyMiddleware(thunk);
const makeStore = () => {
  const store = createStore(
    persistReducer(persistConfig, rootReducer),
    composeEnhancers(middleware, sentryReduxEnhancer),
  );
  const persistor = persistStore(store);
  return { store, persistor };
};

export default makeStore;
