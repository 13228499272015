import React, { useState, useCallback } from 'react';

import { useRouteMatch, useHistory } from 'react-router-dom';

import { Loading } from './Loading';
import { Error } from './Error';
import { Scan, PackLabelData } from './Scan';
import { Pick } from './Pick';
import { useOrderQuery } from '../graphql/queries/useOrderQuery';
import {
  SalesOrderLine,
  FulfilmentLine,
  Fulfilment,
} from '../generated/graphql';
import { usePickPackMutation } from '../graphql/mutations/usePickPackMutation';
import { useCurrentUser } from '../state/user';
import { sortOrderLines } from './OrderLineTable';
import { useNotPickingIds } from '../state/shipment';

function nextPickableLine(
  lines: SalesOrderLine[],
  fulfilmentLines: FulfilmentLine[],
  notPickingIds: string[],
  currentIndex: number,
): string | null {
  for (let i = currentIndex + 1; i < lines.length; i++) {
    const line = lines[i];
    const fq =
      fulfilmentLines.find((x) => x.salesOrderLine.id === line.id)
        ?.quantityFulfilled || 0;
    if (
      fq === 0 &&
      line.quantityOrdered - line.quantitySupplied > 0 &&
      !line.isForceClosed &&
      !notPickingIds.includes(line.id)
    ) {
      return line.id;
    }
  }

  return null;
}

export type PickType = 'any' | 'pack' | 'quantity';
export function determinePickType(
  fulfilment: Fulfilment,
  soLineId: string,
): PickType {
  const line = fulfilment?.lines.find((l) => l.salesOrderLine.id === soLineId);
  let pickType: PickType = 'quantity';
  if (!line || line.quantityFulfilled === 0) pickType = 'any';
  else if (line.packs.length !== 0) pickType = 'pack';
  return pickType;
}

export const PickPage: React.FC = () => {
  const history = useHistory();
  const match = useRouteMatch<{
    orderId: string;
    lineId: string;
    type: PickType;
  }>();
  const { type: pickType, orderId, lineId } = match.params;

  const [isSupplying, setIsSupplying] = useState(false);
  const userId = useCurrentUser()!.id;
  const notPickingIds = useNotPickingIds();

  const { loading, error, data } = useOrderQuery(orderId);
  const [pickPack] = usePickPackMutation();

  const soId = data?.salesOrder?.id;
  const toOrderPage = useCallback(() => {
    history.push(`/order/${soId}`);
  }, [history, soId]);

  if (lineId === '') return null;
  if (!['any', 'pack', 'quantity'].includes(pickType)) return null;

  if (loading || error || !data || !data.salesOrder) {
    return loading ? <Loading fullscreen /> : <Error fullscreen />;
  }

  const orderLine = data?.salesOrder?.lines.find((x) => x.id === lineId);
  if (!orderLine) {
    return <Error fullscreen errorText="Line doesn't exist. That's weird." />;
  }

  const order = data.salesOrder;
  const orderLines = sortOrderLines(order.lines as SalesOrderLine[]);

  if (order.activeFulfilment?.createdBy.id !== userId) {
    history.goBack();
    return null;
  }

  const fulfilment = order.activeFulfilment;
  const fulfilmentLine = fulfilment.lines.find(
    (l) => l.salesOrderLine.id === lineId,
  );

  const nextOrderLine = () => {
    const currentIndex = orderLines.findIndex((l) => l.id === orderLine.id);
    const nextLineId = nextPickableLine(
      orderLines as SalesOrderLine[],
      fulfilment.lines as FulfilmentLine[],
      notPickingIds,
      currentIndex,
    );
    if (nextLineId !== null) {
      const pickType = determinePickType(fulfilment as Fulfilment, nextLineId);
      history.push(`/pick/${order.id}/${nextLineId}/${pickType}`);
      setIsSupplying(false);
    } else {
      history.push(`/order/${order.id}`);
    }
  };

  const completeScan = (
    packData?: PackLabelData,
  ): Promise<{ error: string | null; packId: string | null }> => {
    if (packData) {
      history.push('./pack');
      return pickPack({
        variables: {
          input: {
            fulfilmentId: fulfilment.id,
            salesOrderLineId: lineId,
            quantity: packData.quantity,
            packNumber: packData.packNumber,
            stockCode: packData.stockCode,
            metersCubed: packData.metersCubed,
            kilograms: packData.kilograms,
          },
        },
      })
        .then(({ data }) => {
          return {
            error: null,
            packId: data?.fulfilmentPickLinePack ?? null,
          };
        })
        .catch((error) => {
          // No sentry reporting here because it is most likely due to user
          // error (for example, scanning the same pack twice, since duplicates
          // are not allowed)
          console.warn('Failed to pick pack.', error);
          return { error: 'Failed to pick pack', packId: null };
        });
    }

    setIsSupplying(true);
    return Promise.resolve({ error: null, packId: null });
  };

  return isSupplying ? (
    <Pick
      orderLine={orderLine as SalesOrderLine}
      back={toOrderPage}
      next={nextOrderLine}
      fulfilmentLine={fulfilmentLine as FulfilmentLine}
      salesOrderId={orderId}
      fulfilmentId={fulfilment.id}
    />
  ) : (
    <Scan
      pickType={pickType}
      orderLine={orderLine as SalesOrderLine}
      fulfilmentLine={fulfilmentLine as FulfilmentLine}
      back={toOrderPage}
      complete={completeScan}
    />
  );
};
