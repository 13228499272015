import React from 'react';
import IconButton from '@mui/material/IconButton';

export const ChangeQuantityButton: React.FC<{
  value: number | null;
  setValue: (v: number) => void;
  changeAmount: number;
}> = ({ value, setValue, changeAmount, children }) => {
  const intRep = value || 0;
  const disabled = intRep === 0 && changeAmount < 0;
  return (
    <IconButton
      color="primary"
      aria-label="change quantity"
      disabled={disabled}
      onClick={() => setValue(intRep + changeAmount)}
      size="large"
    >
      {children}
    </IconButton>
  );
};
